import React from 'react';
import { Link } from 'react-router-dom';

export default function CustomLink(props) {
  if (props.nolink) {
    return <div {...props}>{props.children}</div>;
  }

  return (
    <Link {...props} onClick={() => window.scrollTo(0, 0)}>
      {props.children}
    </Link>
  );
}
