import React, { useState, useContext } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import CustomLink from './CustomLink';
import PlayerInsights from './PlayerInsights';
import SuccessSnackbar from './SuccessSnackbar';
import CourtViewPosition from './CourtViewPosition';
import GlobalContext from '../contexts/GlobalContext';
import {
  getClassForTeamScore,
  getClassForPlayerScore,
  getNewHealth,
  getTimeAgo,
  parseGameSchedule,
} from '../helpers/global';
import { BellIcon, CloseIcon } from '../helpers/icons';

const InfoBox = ({ game, hideRedirectLink }) => (
  <div className="game-head">
    <div className="game-listing-main">
      <h2 className="game-title">
        {game.homeTeamDetails.name} vs. {game.visitingTeamDetails.name}
      </h2>
      <div className="game-dates">
        <span className="value">Game Date: {parseGameSchedule(game.gameSchedule, '-00:00').format('M/D/YY')}</span>
        <span className="sep">&nbsp; | &nbsp;</span>
        <span className="value">Game Time: {parseGameSchedule(game.gameSchedule, '-00:00').format('h:mm A z')}</span>
        <span className="sep">&nbsp; | &nbsp;</span>
        <span className="value">
          Last updated @ {moment(game.updatedAt).format('h:mm A')}{' '}
          {moment(game.gameDate).tz(moment.tz.guess()).format('z')}
        </span>
      </div>
      {hideRedirectLink ? null : <div className="redirect-link">Read Full Game Preview</div>}
    </div>
    <div className="game-listing-logos">
      <figure className="item-logo transparent">
        <img src={game.homeTeamDetails.logoUrl} alt="Team logo" />
      </figure>
      <span>vs.</span>
      <figure className="item-logo transparent">
        <img src={game.visitingTeamDetails.logoUrl} alt="Team logo" />
      </figure>
    </div>
  </div>
);

function CourtBox({ game }) {
  const context = useContext(GlobalContext);
  const [successMessage, setSuccessMessage] = useState('');
  const [popupPlayerInfo, setPopupPlayerInfo] = useState(null);
  const { playerNotifications, togglePlayerNotification, statuses } = context;

  function openModal(position, team) {
    if (!position.featuredPlayer) {
      return;
    }

    const featuredPlayer = position.featuredPlayer;

    setPopupPlayerInfo({
      ...position,
      featuredPlayer,
      backupPlayers: position.backupPlayers.filter((player) => player),
      teamLogo: team.logoUrl,
    });
  }

  return (
    <>
      <div className="court-view-fields">
        <div className="court-view-top">
          <div className="court-view-ttl">Doc's diagnosis</div>
        </div>
        <div className="court-view-cols court-view-headlines">
          <div>{game.gameDate.format('D/M/Y')}</div>
          <div>Spread</div>
          <div>Money</div>
          <div>O/U</div>
          <div>Team</div>
        </div>

        <div className="court-view-cols court-view-values">
          <div className="court-view-id">
            <img src={game.homeTeamDetails.logoUrl} alt="Team logo" />
          </div>
          <div>{game.homeTeamPoints}</div>
          <div>{game.homeTeamMoney}</div>
          <div>{game.homeTeamOU}</div>
          <div className={'health-cell ' + getClassForTeamScore(game.homeTeamTeam)}>{game.homeTeamTeam}</div>
        </div>
        <div className="court-view-cols court-view-values">
          <div className="court-view-id">
            <img src={game.visitingTeamDetails.logoUrl} alt="Team logo" />
          </div>
          <div>{game.awayTeamPoints}</div>
          <div>{game.awayTeamMoney}</div>
          <div>{game.awayTeamOU}</div>
          <div className={'health-cell ' + getClassForTeamScore(game.awayTeamTeam)}>{game.awayTeamTeam}</div>
        </div>
      </div>
      <div className="court-view-info">
        <div className="court-view-side">
          <div>
            <div className="court-view-team">{game.homeTeamDetails.name}</div>
            <div className="court-view-bench">
              <div className="court-view-lbl">Bench</div>
              <CourtViewPosition
                onClick={() => openModal(game.homeTeamPositions.sub1, game.homeTeamDetails)}
                position={game.homeTeamPositions.sub1}
                className="hs1"
              />

              <CourtViewPosition
                onClick={() => openModal(game.homeTeamPositions.sub2, game.homeTeamDetails)}
                position={game.homeTeamPositions.sub2}
                className="hs2"
              />

              <CourtViewPosition
                onClick={() => openModal(game.homeTeamPositions.sub3, game.homeTeamDetails)}
                position={game.homeTeamPositions.sub3}
                className="hs3"
              />

              <CourtViewPosition
                onClick={() => openModal(game.homeTeamPositions.sub4, game.homeTeamDetails)}
                position={game.homeTeamPositions.sub4}
                className="hs4"
              />

              <CourtViewPosition
                onClick={() => openModal(game.homeTeamPositions.sub5, game.homeTeamDetails)}
                position={game.homeTeamPositions.sub5}
                className="hs5"
              />
            </div>
          </div>
          <div className="court-view-logo">
            <img src={game.homeTeamDetails.logoUrl} alt="Team logo" />
          </div>
        </div>
        <div className="court-view-vs">VS</div>
        <div className="court-view-side">
          <div className="court-view-logo">
            <img src={game.visitingTeamDetails.logoUrl} alt="Team logo" />
          </div>
          <div>
            <div className="court-view-team">{game.visitingTeamDetails.name}</div>
            <div className="court-view-bench">
              <CourtViewPosition
                onClick={() => openModal(game.awayTeamPositions.sub1, game.visitingTeamDetails)}
                position={game.awayTeamPositions.sub1}
                className="as1"
              />

              <CourtViewPosition
                onClick={() => openModal(game.awayTeamPositions.sub2, game.visitingTeamDetails)}
                position={game.awayTeamPositions.sub2}
                className="as2"
              />

              <CourtViewPosition
                onClick={() => openModal(game.awayTeamPositions.sub3, game.visitingTeamDetails)}
                position={game.awayTeamPositions.sub3}
                className="as3"
              />

              <CourtViewPosition
                onClick={() => openModal(game.awayTeamPositions.sub4, game.visitingTeamDetails)}
                position={game.awayTeamPositions.sub4}
                className="as4"
              />

              <CourtViewPosition
                onClick={() => openModal(game.awayTeamPositions.sub5, game.visitingTeamDetails)}
                position={game.awayTeamPositions.sub5}
                className="as5"
              />

              <div className="court-view-lbl">Bench</div>
            </div>
          </div>
        </div>
      </div>
      <div className="court-view-inner">
        <CourtViewPosition
          onClick={() => openModal(game.homeTeamPositions.p1, game.homeTeamDetails)}
          position={game.homeTeamPositions.p1}
          className="h1"
        />

        <CourtViewPosition
          onClick={() => openModal(game.homeTeamPositions.p2, game.homeTeamDetails)}
          position={game.homeTeamPositions.p2}
          className="h2"
        />

        <CourtViewPosition
          onClick={() => openModal(game.homeTeamPositions.p3, game.homeTeamDetails)}
          position={game.homeTeamPositions.p3}
          className="h3"
        />

        <CourtViewPosition
          onClick={() => openModal(game.homeTeamPositions.p5, game.homeTeamDetails)}
          position={game.homeTeamPositions.p5}
          className="h5"
        />

        <CourtViewPosition
          onClick={() => openModal(game.homeTeamPositions.p4, game.homeTeamDetails)}
          position={game.homeTeamPositions.p4}
          className="h4"
        />

        <CourtViewPosition
          onClick={() => openModal(game.awayTeamPositions.p1, game.visitingTeamDetails)}
          position={game.awayTeamPositions.p1}
          className="a1"
        />

        <CourtViewPosition
          onClick={() => openModal(game.awayTeamPositions.p2, game.visitingTeamDetails)}
          position={game.awayTeamPositions.p2}
          className="a2"
        />

        <CourtViewPosition
          onClick={() => openModal(game.awayTeamPositions.p3, game.visitingTeamDetails)}
          position={game.awayTeamPositions.p3}
          className="a3"
        />

        <CourtViewPosition
          onClick={() => openModal(game.awayTeamPositions.p5, game.visitingTeamDetails)}
          position={game.awayTeamPositions.p5}
          className="a5"
        />

        <CourtViewPosition
          onClick={() => openModal(game.awayTeamPositions.p4, game.visitingTeamDetails)}
          position={game.awayTeamPositions.p4}
          className="a4"
        />
      </div>

      {popupPlayerInfo ? (
        <div
          className="diamond-info transparent"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <div className="diamond-info-inner">
            <div className="diamond-info-timestamp">Updated {getTimeAgo(game.updatedAt)}</div>
            <span className="diamond-info-close" onClick={() => setPopupPlayerInfo(null)}>
              <CloseIcon />
            </span>

            <div className="diamond-info-top">
              <div className="diamond-info-id">
                <figure>
                  {popupPlayerInfo.featuredPlayer && popupPlayerInfo.featuredPlayer.photoUrl ? (
                    <img src={popupPlayerInfo.featuredPlayer.photoUrl} alt="Player preview" />
                  ) : null}
                </figure>
                {popupPlayerInfo.featuredPlayer ? (
                  <div>
                    <div
                      className="diamond-info-player"
                      dangerouslySetInnerHTML={{
                        __html: popupPlayerInfo.featuredPlayer.name
                          ? popupPlayerInfo.featuredPlayer.name.replace(' ', '<br/>')
                          : '',
                      }}
                    ></div>
                    <CustomLink
                      className="diamond-info-link"
                      to={
                        '/' +
                        (popupPlayerInfo.featuredPlayer.leagueType || '').toLowerCase() +
                        '/players/' +
                        popupPlayerInfo.featuredPlayer.slug
                      }
                    >
                      View Profile
                    </CustomLink>
                  </div>
                ) : null}
              </div>

              {popupPlayerInfo.featuredPlayer ? (
                <span
                  className={classNames({
                    'diamond-info-notify': true,
                    'is-active': playerNotifications.indexOf(popupPlayerInfo.featuredPlayer.id) !== -1,
                  })}
                  onClick={() => {
                    const id = popupPlayerInfo.featuredPlayer.id;

                    togglePlayerNotification(id);
                    setSuccessMessage(
                      playerNotifications.indexOf(id) === -1
                        ? 'Player notifications turned off'
                        : 'Player notifications turned on'
                    );
                    setTimeout(() => setSuccessMessage(''), 1000);
                  }}
                >
                  <BellIcon />
                </span>
              ) : null}
            </div>

            <div className="diamond-info-table">
              <div className="diamond-info-tr">
                <div className="diamond-info-td1">HEALTH</div>
                <div className="diamond-info-td1">TEAM</div>
                {/* <div className="diamond-info-td1">STATUS</div> */}
                <div className="diamond-info-td1">POSITION</div>
              </div>
              <div className="diamond-info-tr diamond-info-tr2">
                {popupPlayerInfo.featuredPlayer ? (
                  <div className="diamond-info-td2 is-flex">
                    <span
                      className={
                        'health-sign rounded health-' +
                        getNewHealth(
                          popupPlayerInfo.featuredPlayer.health,
                          popupPlayerInfo.featuredPlayer.sicScore,
                          popupPlayerInfo.featuredPlayer.status,
                          statuses
                        )
                      }
                    >
                      {popupPlayerInfo.featuredPlayer.sicScore !== null ? popupPlayerInfo.featuredPlayer.sicScore : ''}
                    </span>
                  </div>
                ) : null}
                <div className="diamond-info-td2">
                  <figure>
                    {popupPlayerInfo.teamLogo ? <img src={popupPlayerInfo.teamLogo} alt="Team logo" /> : null}
                  </figure>
                </div>
                {/* <div className="diamond-info-td2">
                  {popupPlayerInfo.featuredPlayer ? popupPlayerInfo.featuredPlayer.status : ''}
                </div> */}
                <div className="diamond-info-td2">{popupPlayerInfo.position}</div>
              </div>
            </div>

            {popupPlayerInfo.featuredPlayer && popupPlayerInfo.featuredPlayer.playerNotes ? (
              <PlayerInsights insights={popupPlayerInfo.featuredPlayer.playerNotes} />
            ) : null}

            {popupPlayerInfo.backupPlayers.length ? (
              <div>
                <div className="diamond-info-tr mbm">
                  <div className="diamond-info-td3">POS. BACK UPS</div>
                  <div className="diamond-info-td3 diamond-info-double">
                    <span>Status</span>
                    <span className="diamond-info-cross"></span>
                  </div>
                </div>
                {popupPlayerInfo.backupPlayers.map((player) => (
                  <div className="diamond-info-tr mbs" key={player._id}>
                    <div className="diamond-info-td4">
                      <CustomLink
                        className="diamond-info-link"
                        to={'/' + (player.leagueType || '').toLowerCase() + '/players/' + player.slug}
                      >
                        {player.name}
                      </CustomLink>
                    </div>
                    <div className="diamond-info-td4 diamond-info-double">
                      <span>{player.status}</span>
                      <div className={'health-cell ' + getClassForPlayerScore(player.health)}></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {successMessage ? <SuccessSnackbar message={successMessage} /> : null}
    </>
  );
}

export default function CourtView(props) {
  const { game, hideRedirectLink, infoBoxPosition } = props;

  return (
    <CustomLink nolink={hideRedirectLink} to={`/nba/games/${game.season}/${game.slug}`} className="court-view">
      {infoBoxPosition === 'top' ? <InfoBox {...props} /> : null}

      <CourtBox {...props} />

      {infoBoxPosition === 'bottom' ? <InfoBox {...props} /> : null}
    </CustomLink>
  );
}

CourtView.defaultProps = {
  infoBoxPosition: 'top',
};
