import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import GlobalContext from '../contexts/GlobalContext'
import EditWidget from './EditWidget'
import LeagueSwitcher from './LeagueSwitcher'
import SicImage from './SicImage'
import classNames from 'classnames'
import { getTimeAgo, getNewHealth } from '../helpers/global'
import { ArrowRightIcon, LockIcon, SicPickIcon, SicPickLogoIcon } from '../helpers/icons'
import { SUPPORTED_LEAGUES } from '../config'

const slideTo = to => {
	const slider = document.querySelector('.latest-news-articles.inline');
	const currentSlide = slider.getAttribute('data-slide');
	const totalSlides = slider.querySelectorAll('.latest-news-article').length;
	const firstArticle = slider.querySelector('.latest-news-article:first-child');
	const newSlide = to === '-1' ? (currentSlide-1) : (to === '+1' ? (currentSlide*1+1) : to);

	if (newSlide > totalSlides - 3 || newSlide < 1) {
		return;
	}

	slider.setAttribute('data-slide', newSlide);

	firstArticle.style.marginLeft = (-25.333 * (newSlide-1)) + '%';
}

class LatestNews extends Component {
	constructor() {
		super();

		this.state = {
			currentLeague: null
		};
	}

	componentDidMount() {
		const { selfLoad, match } = this.props;
		const { latestNews, loadLatestNews } = this.context;

		if (selfLoad && latestNews.length === 0) {
			loadLatestNews(match.params.league || null);
		}
	}

	render() {
		const { currentLeague } = this.state;
		const { 
			allLeagues, 
			latestNews, 
			widgets, 
			widgetsContent, 
			selectedLeague,
			statuses
    } = this.context;
		const { 
			layout, 
			showEditWidgetButton, 
			postsCount, 
			selfLoad, 
			outerLoad, 
			widgetTitle, 
			updates, 
			hasScroller,
			globalLeague,
			hideSignupForm,
			showLeagueSwitcher,
			externalData
		} = this.props
		const widgetKey = 'post-feed-horizontal';

		if (!widgets[widgetKey] || allLeagues.length === 0) {
			return null;
		}

		const chosenLeague = widgets[widgetKey].find(({key}) => key === 'leagueType').value;
		const leagues = chosenLeague === 'all' || globalLeague ? allLeagues : [allLeagues.find(({name}) => name === chosenLeague)];
		let displayLeague = globalLeague ? leagues.find(({_id}) => _id === selectedLeague) : (currentLeague ? currentLeague : leagues[0]);

		if (leagues.length > 1 && SUPPORTED_LEAGUES.indexOf(window.location.pathname) !== -1) {
			displayLeague = leagues.find(({name}) => name === window.location.pathname.replace('/', '').toUpperCase());
		}

		let articles = (outerLoad ? updates : (
			selfLoad 
			? latestNews 
			: widgetsContent[widgetKey]
		).filter(({leagueType}) => !displayLeague || displayLeague._id === 'all' || leagueType === displayLeague.name))

		if (postsCount > 0) {
			articles = articles.slice(0, postsCount);
		}

		if (externalData) {
			articles = externalData
		}

  	return <>
  		{
  			hideSignupForm
  			? null
  			: <iframe title="Signup form" src="/signup.html" style={{ border: 0, height: 660 }}></iframe>
  		}
  		<section className="latest-news">
				<div className="section-header justify-between">
					<div className="section-header-start">
						{
							widgetTitle
							? <h2 className="section-title">{widgetTitle}</h2>
							: null
						}
			    	{
			    		showLeagueSwitcher
			    		? <LeagueSwitcher 
				    		leagues={leagues}
				    		currentLeague={displayLeague}
				    		onChangeLeague={league => {
				    			this.setState({ currentLeague: allLeagues.find(({_id}) => _id === league) });
				    		}} 
			    		/>
			    		: null
			    	}
			  	</div>
			  	{
			  		showEditWidgetButton
			  		? <EditWidget type={widgetKey} />
			  		: null
			  	}
				</div>

				{
			  	hasScroller
			  	? <div className="widget-scroller prev" onClick={() => slideTo('-1')}>
						<ArrowRightIcon />
					</div>
					: null
			  }

			  <div className={classNames(['latest-news-articles', layout, 'count-' + articles.length])} data-slide="1">
			    {
			      articles.map(article => {
			      	return <Link 
				      	to={this.context.getArticleLink(article)} 
				      	className={classNames(['latest-news-article', layout])} 
				      	key={article._id}
				      	onClick={() => {
				      		this.context.loadArticle(article.slug, article.leagueType);
				      		window.scrollTo(0,0);
				      	}}
			      	>
		      			<figure>
				        	{
                    article.video
                    ? <iframe src={article.video} title={article.title + ' video'}></iframe>
                    : <SicImage src={article.postImage.thumb} data-src={article.postImage.small} alt="Cover" />
                  }

                  {
				      			article.accessibility === 'paid'
				      			?	<div className="sic-pick-overlay">
				      				<SicPickLogoIcon />
				      				<div>
					      				<LockIcon />
					      				Exclusive Content
				      				</div>
			      				</div>
				      			: null
				      		}
				      	</figure>
				        <h3 className="update-title">{article.title}</h3>

				        {
				        	article.playerDetails || article.teamDetails
					        ? <div className="post-attachments">
					        	{
					        		article.playerDetails
					        		? <>
					        			{
							        		article.playerDetails.map(player => {
							        			return <span onClick={e => {
							        				e.preventDefault();
							        				window.location.href = '/' + article.leagueType.toLowerCase() + '/players/' + player.slug;
					    								window.scrollTo(0,0);
							        			}} key={player._id}>
						        					<div className={'health-dot health-dot-' + getNewHealth(player.health, player.sicScore, player.status, statuses)}></div> <span>{player.name}</span>
						        				</span>
							        		})
							        	}
					        		</>
					        		: null
					        	}
					        	{
					        		article.teamDetails
					        		? <>
						        		{
						        			article.teamDetails.map(team => {
							        			return <span onClick={e => {
							        				e.preventDefault();
							        				window.location.href = '/' + article.leagueType.toLowerCase() + '/teams/' + team.slug;
					    								window.scrollTo(0,0);
							        			}} key={team.id}>{team.name}</span>
							        		})		
						        		}
					        		</>
					        		: null
					        	}
				          </div>
				          : null
			          }

			          {
			          	article.breakingNews === 'yes'
			          	? <div className="post-tag red">Breaking News</div>
			          	: null
			          }
				      	<div className="post-tag">{article.leagueType}</div>
				      	<div className="post-date">
				      		{
				      			article.accessibility === 'private'
				      			?	<LockIcon />
				      			: null
				      		}
				      		{
				      			article.accessibility === 'paid'
				      			?	<SicPickIcon />
				      			: null
				      		}
				      		{getTimeAgo(article.datetime)}
			      		</div>
				      </Link>
			      })
			    }
			  </div>

			  {
			  	hasScroller
			  	? <div className="widget-scroller next" onClick={() => slideTo('+1')}>
						<ArrowRightIcon />
					</div>
					: null
			  }
			</section>
    </>
	}
}

LatestNews.contextType = GlobalContext
LatestNews.defaultProps = { 
	layout: 'inline',
	showEditWidgetButton: false,
	postsCount: 4,
	selfLoad: true,
	outderLoad: false,
	widgetTitle: 'SIC News',
	updates: [],
	hasScroller: false,
	globalLeague: false,
	showLeagueSwitcher: false,
	hideSignupForm: false
}

export default withRouter(LatestNews)
