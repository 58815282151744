import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import EditWidget from '../EditWidget';
import CustomLink from '../CustomLink';
import SicImage from '../SicImage';
import { getTimeAgo, getNewHealth } from '../../helpers/global';
import { isAdmin } from '../../helpers/session';
import GlobalContext from '../../contexts/GlobalContext';
import { LockIcon, SicPickIcon, SicPickLogoIcon } from '../../helpers/icons';
import { API_URL, SUPPORTED_LEAGUES } from '../../config';

export default function SidePostsWidget() {
  const context = useContext(GlobalContext);
  const { widgets, widgetsContent, statuses } = context;
  const [subsequentArticles, setSubsequentArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMore, setIsMore] = useState(true);
  const widgetKey = 'post-feed-vertical';
  const pathLeague = window.location.pathname;
  const pathLeagueStripped = pathLeague.replace('/', '');

  useEffect(() => {
    if (currentPage > 1) {
      axios
        .get(`${API_URL}/widget/loadMore?page=${pathLeagueStripped || 'home'}&type=post&pageNumber=${currentPage}`)
        .then(({ data }) => {
          const newArticles = data.data;

          setSubsequentArticles(subsequentArticles.concat(newArticles));

          if (!newArticles.length) {
            setIsMore(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pathLeagueStripped]);

  if (!widgets[widgetKey]) {
    return null;
  }

  const selectedLeague =
    SUPPORTED_LEAGUES.indexOf(window.location.pathname) === -1 ? 'all' : window.location.pathname.replace('/', '');

  const articles = widgetsContent[widgetKey].filter(({ leagueType }) => {
    return (
      !selectedLeague ||
      selectedLeague === 'all' ||
      (selectedLeague === 'cfb' && ['ncaaf', 'cfb'].indexOf(leagueType.toLowerCase()) !== -1) ||
      leagueType.toLowerCase() === selectedLeague.toLowerCase()
    );
  });

  if (!articles.length && !isAdmin()) {
    return null;
  }

  return (
    <div className="news-banner-side">
      <div className="section-header justify-end">
        <EditWidget type={widgetKey} />
      </div>
      <div className="section-content">
        {articles.concat(subsequentArticles).map((article) => {
          return (
            <CustomLink key={article._id} to={context.getArticleLink(article)} className="article">
              <figure>
                <SicImage src={article.postImage.thumb} data-src={article.postImage.medium} alt="Cover" />

                {article.accessibility === 'paid' ? (
                  <div className="sic-pick-overlay">
                    <SicPickLogoIcon />
                    <div>
                      <LockIcon />
                      Exclusive Content
                    </div>
                  </div>
                ) : null}
              </figure>
              {/*<div className="pre-title">{article.leagueType} News</div>*/}
              <h2 className="update-title">{article.title}</h2>
              <div className="post-attachments">
                {article.playerDetails ? (
                  <>
                    {article.playerDetails.map((player) => {
                      return (
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = '/' + player.leagueType.toLowerCase() + '/players/' + player.slug;
                            window.scrollTo(0, 0);
                          }}
                          key={player._id}
                        >
                          <div
                            className={
                              'health-dot health-dot-' +
                              getNewHealth(player.health, player.sicScore, player.status, statuses)
                            }
                          ></div>{' '}
                          <span>{player.name}</span>
                        </span>
                      );
                    })}
                  </>
                ) : null}

                {article.teamDetails ? (
                  <>
                    {article.teamDetails.map((team) => {
                      return (
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = '/' + team.leagueType.toLowerCase() + '/teams/' + team.slug;
                            window.scrollTo(0, 0);
                          }}
                          key={team.id}
                        >
                          {team.name}
                        </span>
                      );
                    })}
                  </>
                ) : null}
              </div>
              {article.breakingNews === 'yes' ? <div className="post-tag red">Breaking News</div> : null}
              <div className="post-tag">{article.leagueType}</div>
              <div className="post-date">
                {article.accessibility === 'private' ? <LockIcon /> : null}
                {article.accessibility === 'paid' ? <SicPickIcon /> : null}
                {getTimeAgo(article.datetime)}
              </div>
            </CustomLink>
          );
        })}

        {isMore ? (
          <button className="primary-button bordered load-more" onClick={() => setCurrentPage(currentPage + 1)}>
            + View More
          </button>
        ) : null}
      </div>
    </div>
  );
}
