import TwitterLogin from 'react-twitter-auth';
import { API_URL } from '../config';
import { setToken, setRefreshToken, setAdmin } from '../helpers/session';
import { useContext, useState } from 'react';
import GlobalContext from '../contexts/GlobalContext';

export default function TwitterSocialLogin() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const context = useContext(GlobalContext);
  const twitterOnFailure = (error) => {
    console.log('error', error);
    setErrors({
      email: error.response ? error.response.data.message : 'Login failed. Please reset your password.',
      password: false,
    });
    setLoading(false);
  };

  const twitterOnSuccess = async (response) => {
    const { errors } = this.state;

    if (response.status !== 200) {
      errors.email = response.statusText;
      setErrors(errors);
    } else {
      response.json().then(async (user) => {
        if (user.token) {
          const isAdmin = user.data.user.roleName === 'Admin';
          setToken(user.token);
          setRefreshToken(user.refreshToken);
          setAdmin(isAdmin);

          context.loadAppData();
          setTimeout(() => (window.location.href = '/'), 2000);
        } else {
          alert('An error occurred while authenticating');
        }
      });
    }
  };
  return (
    <TwitterLogin
      loginUrl={API_URL + '/auth/authenticate/twitter'}
      className="twitter-button"
      onFailure={twitterOnFailure}
      onSuccess={twitterOnSuccess}
      showIcon={true}
      text="Continue with Twitter"
      requestTokenUrl={API_URL + '/auth/twitter/reverse'}
    />
  );
}
