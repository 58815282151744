import React from 'react';
import GlobalContext from '../contexts/GlobalContext';
import { getMainLeagues } from '../helpers/global';

const LeagueSwitcher = ({ leagues, currentLeague, onChangeLeague, hiddenLeagues, onlyMainLeagues }) => {
  if (['#/nfl', '#/nba', '#/mlb'].indexOf(window.location.hash) !== -1) {
    return null;
  }

  return (
    <div className="league-switcher">
      <GlobalContext.Consumer>
        {(context) => {
          const { allLeagues, selectedLeague } = context;
          const availableLeagues = onlyMainLeagues
            ? allLeagues.filter(({ _id, name }) => getMainLeagues().indexOf(name.toLowerCase()) !== -1 || _id === 'all')
            : leagues
            ? leagues
            : allLeagues;
          const currentLeagueId = currentLeague ? currentLeague._id : selectedLeague;

          return (
            <>
              {availableLeagues
                .filter((league) => !!league)
                .map((league) => {
                  if (hiddenLeagues.indexOf(league.name) !== -1) {
                    return null;
                  }

                  return (
                    <span
                      key={league._id}
                      className={league._id === currentLeagueId ? 'active' : ''}
                      onClick={() => {
                        if (onChangeLeague) {
                          onChangeLeague(league._id, league);
                        } else {
                          context.setLeague(league._id);
                        }
                      }}
                    >
                      {league.name}
                    </span>
                  );
                })}
            </>
          );
        }}
      </GlobalContext.Consumer>
    </div>
  );
};

export default LeagueSwitcher;

LeagueSwitcher.defaultProps = {
  onChangeLeague: null,
  hiddenLeagues: [],
  onlyMainLeagues: false,
};
