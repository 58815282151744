import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GameBox from './GameBox';
import CourtView from './CourtView';
import DiamondView from './DiamondView';
import { API_URL } from '../config.js';
import { NbaGame } from '../helpers/nba';
import { MlbGame } from '../helpers/mlb';

export default function GlobalEmbedGame({ league, id, search }) {
  const [indexes, setIndexes] = useState([]);
  const games =
    typeof indexes[0] !== 'undefined' && typeof indexes[0].games !== 'undefined'
      ? indexes[0].games
      : indexes.length
      ? indexes
      : [];
  const isNfl = league === 'nfl';
  const isNba = league === 'nba';
  const isMlb = league === 'mlb';

  useEffect(() => {
    axios
      .get(API_URL + '/injuryIndex/getEmbedded/?season=2021&gameScheduleId=' + id + '&domainName=' + search, {
        headers: {
          league_type: league ? league : 'nfl',
        },
      })
      .then(({ data }) => setIndexes(data.data));
  }, [id, league, search]);

  return (
    <div className="main-games-holder global-embed-game">
      {games.map((game) => {
        const nbaGame = new NbaGame();
        const mlbGame = new MlbGame();

        if (isNba) {
          nbaGame.initGame(game);
        }

        if (isMlb) {
          mlbGame.initGame(game);
        }

        return (
          <div key={game._id}>
            {isNfl ? <GameBox game={game} /> : null}
            {isNba ? <CourtView game={nbaGame} hideRedirectLink={true} /> : null}
            {isMlb ? <DiamondView gameData={mlbGame} isEmbed={true} /> : null}
          </div>
        );
      })}
    </div>
  );
}
