import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken } from '../../helpers/session';
import RegisterForm from '../RegisterForm';
import TwitterSocialLogin from '../TwitterSocialLogin';
import GoogleSocialLogin from '../GoogleSocialLogin';
import GlobalContext from '../../contexts/GlobalContext';
import featureData from '../ProductScreen/featureData';
import { Link } from 'react-router-dom';

const restrictedPaths = {
  none: ['/fantasy-teams', '/matchups', '/games', '/picks', '/injury-edge', '/nfl/teams', '/nba/teams', '/mlb/teams'],
  registered: ['/matchups', '/games', '/picks', '/injury-edge', '/nfl/teams', '/nba/teams', '/mlb/teams'],
  sicData: ['/picks'],
  sicPicks: [],
};
const restrictedModals = {
  none: {
    '/fantasy-teams': 'register',
    '/nfl/teams': 'sicData',
    '/nba/teams': 'sicData',
    '/mlb/teams': 'sicData',
    '/matchups': 'sicData',
    '/games': 'sicData',
    '/injury-edge': 'sicData',
    '/picks': 'sicPicks',
  },
  registered: {
    '/matchups': 'sicData',
    '/games': 'sicData',
    '/injury-edge': 'sicData',
    '/nfl/teams': 'sicData',
    '/nba/teams': 'sicData',
    '/mlb/teams': 'sicData',
    '/picks': 'sicPicks',
  },
  sicData: {
    '/picks': 'sicPicks',
  },
  sicPicks: {},
};
export default function ContentRestrictionModal() {
  const context = useContext(GlobalContext);
  const accessLevel = context?.currentUser?.accessLevel
    ? context.currentUser.accessLevel
    : context.currentUser
    ? 'registered'
    : 'none';
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [modalType, setModalType] = useState('');
  const [isLoggedin] = useState(getToken() ? true : false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const currentPath = location.pathname;
    let isRestrictedPath = false;
    restrictedPaths[accessLevel].forEach((path) => {
      if (currentPath.includes(path)) {
        isRestrictedPath = true;
      }
    });
    if (isRestrictedPath) {
      Object.keys(restrictedModals[accessLevel]).forEach((pathkey) => {
        if (currentPath.includes(pathkey)) {
          setModalType(restrictedModals[accessLevel][pathkey]);
        }
      });
      setOpen(true);

      document.body.style['overflow-y'] = 'hidden';
    } else {
      document.body.style['overflow-y'] = 'scroll';
      setOpen(false);
    }
  }, [accessLevel, location]);

  return (
    <>
      {open && (
        <div className="content-restriction-modal">
          <div className="content-container">
            <div className="backdrop"></div>
            <div className="paper-wrapper">
              <div className="paper">
                {modalType === 'register' && <SICMember />}
                {modalType === 'sicData' && <SICDataRestriction isLoggedin={isLoggedin} />}
                {modalType === 'sicPicks' && <SICPicksRestriction isLoggedin={isLoggedin} />}

                {!isLoggedin && (
                  <div>
                    <div className="separator">or</div>
                    <div className="subscriber-wrapper">
                      <Link className="email-btn" onClick={() => history.push('/login')}>
                        Log in with Email
                      </Link>
                      <TwitterSocialLogin />
                      <GoogleSocialLogin />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SICPicksRestriction({ isLoggedin }) {
  return (
    <>
      <div className="modal-title">Sign up for SIC Picks {!isLoggedin && ', or log in.'} </div>
      <div className="modal-subtitle">
        With SIC Picks you get access to the best injury based bets on all sports! Spread bets, totals, props, live
        in-game, futures and more from just $9.62 a week!
      </div>

      <div className="included">
        <div className="included-title">SIC Picks includes</div>
        {featureData.map((feature, idx) => {
          return (
            feature.checks['picks'] && (
              <div className="whats-included-item" key={idx}>
                <span className="feature-icon">{feature.icon}</span>
                <div className="title">{feature.title}</div>
              </div>
            )
          );
        })}
      </div>

      <Link to={'/checkout?plan=picks'} className="secondary-button bordered load-more">
        Continue...
      </Link>
      <div className="disclaimer">
        By continuing, you agree to the updated <Link to="/terms-of-service">Terms of Service</Link> , and{' '}
        <Link to="/privacy">Privacy Policy</Link>.
      </div>
    </>
  );
}

function SICDataRestriction({ isLoggedin }) {
  return (
    <>
      <div className="modal-title">Sign up for SIC Data{!isLoggedin && ', or log in.'} </div>
      <div className="modal-subtitle">
        Sign up for SIC Data to unlock this page and get access to player and team SIC Matchups, Field Views and more
        for every NFL game from just $0.96 a week!
      </div>

      <div className="included">
        <div className="included-title">SIC Data includes</div>
        {featureData.map((feature, idx) => {
          return (
            feature.checks['data'] && (
              <div className="whats-included-item" key={idx}>
                <span className="feature-icon">{feature.icon}</span>
                <div className="title">{feature.title}</div>
              </div>
            )
          );
        })}
      </div>

      <Link to={'/checkout?plan=data'} className="secondary-button bordered load-more">
        Continue...
      </Link>
      <div className="disclaimer">
        By continuing, you agree to the updated <Link to="/terms-of-service">Terms of Service</Link> , and{' '}
        <Link to="/privacy">Privacy Policy</Link>.
      </div>
    </>
  );
}

function SICMember() {
  return (
    <>
      <div className="modal-title">Sign up for SIC Free, or log in. </div>
      <div className="modal-subtitle">
        Sign up for SIC FREE to unlock this page and also get access to Subscriber Only Insights, Articles and Videos,
        our Weekly Newsletter and Podcast, as well as SMS and Email Player Alerts.
      </div>
      <div className="register-wrapper">
        <RegisterForm />
      </div>
    </>
  );
}
