import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingSplash() {
  return (
    <div className="loading-splash">
      <CircularProgress size={160} />
    </div>
  );
}
