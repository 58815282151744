import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import GameBox from '../components/GameBox';
import CourtView from '../components/CourtView';
import DiamondView from '../components/DiamondView';
import ScoresScreen from '../screens/ScoresScreen';
import GlobalContext from '../contexts/GlobalContext';
import { SicLogoIcon } from '../helpers/icons';
import { getNewHealth } from '../helpers/global';
import { NbaGame } from '../helpers/nba';
import { MlbGame } from '../helpers/mlb';
import { API_URL, APP_URL } from '../config.js';

export default function EmbedWidgetScreen(props) {
  const [layout, setLayout] = useState('post');
  const [data, setData] = useState([]);
  const { match, location } = props;

  useEffect(() => {
    if (match.params.widgetId.indexOf('preview') !== -1) {
      const contentType = match.params.widgetId.replace('preview-', '');
      const params = new URLSearchParams(location.search.replace('?', ''));
      const display = params.get('display');
      setLayout(contentType);
      axios
        .get(
          API_URL +
            '/widgetData/preview?display=' +
            display +
            '&leagueType=' +
            match.params.league.toUpperCase() +
            '&contentType=' +
            contentType
        )
        .then(({ data }) => {
          setData(data.data);
        });
    } else {
      axios.get(API_URL + '/widgetData/' + match.params.widgetId).then(({ data }) => {
        setData(data.data);
        setLayout(data.widget.contentType);
      });
    }
  }, [match.params.widgetId, match.params.league, location ? location.search : '']);

  return (
    <>
      <style type="text/css">{`#ezmob-wrapper { display: none; }`}</style>

      {['posts', 'articles'].indexOf(layout) !== -1 ? <EmbedWidgetPosts data={data} /> : null}

      {['sic-score', 'game-view', 'sic-score-game-view'].indexOf(layout) !== -1 ? (
        <EmbedWidgetSicScoreGameView data={data} layout={layout} />
      ) : null}

      {layout === 'trending-players' && false ? <EmbedWidgetPlayers data={data} /> : null}

      {layout === 'trending-players' ? <EmbedWidgetPlayerScores data={data} /> : null}

      {layout === 'videos' ? <EmbedWidgetVideos data={data} /> : null}

      {layout === 'team-sic-scores' ? <ScoresScreen hideCopy={true} hideSelectors={true} hidePlayers={true} /> : null}

      {layout === 'player-sic-scores' ? (
        <ScoresScreen hideSelectors={true} hideSidebar={true} hideTeams={true} hideCopy={true} nolink={true} />
      ) : null}
    </>
  );
}

function EmbedWidgetPosts({ data }) {
  return (
    <div className="embed-widget-posts">
      {data.map(({ _id, title, slug, postImage, leagueType, playerDetails, teamDetails, gameDetails }) => (
        <article key={_id}>
          <figure>
            <a
              href={APP_URL + '/' + leagueType.toLowerCase() + '/updates/' + slug}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={postImage.medium} alt="Post media" />
            </a>
          </figure>
          <div className="post-text">
            <a
              href={APP_URL + '/' + leagueType.toLowerCase() + '/updates/' + slug}
              className="post-title"
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
            <EmbedWidgetPostTags playerDetails={playerDetails} teamDetails={teamDetails} gameDetails={gameDetails} />
          </div>
        </article>
      ))}
    </div>
  );
}

function EmbedWidgetPostTags({ playerDetails, teamDetails, gameDetails }) {
  const MODE_COLLAPSED = 'collapsed';
  const [mode, setMode] = useState(MODE_COLLAPSED);

  const allTags = playerDetails
    .map((tag) => {
      return {
        tagType: 'player',
        ...tag,
      };
    })
    .concat(
      teamDetails.map((tag) => {
        return {
          tagType: 'team',
          ...tag,
        };
      })
    )
    .concat(
      gameDetails.map((tag) => {
        return {
          tagType: 'game',
          ...tag,
        };
      })
    );

  if (!allTags.length) return null;

  const tags = mode === MODE_COLLAPSED ? [allTags[0]] : allTags;

  return (
    <div className="tags-line">
      <div className="tags-players">
        {tags.map((row) => {
          return (
            <div key={row._id}>
              {row.tagType === 'player' ? (
                <a
                  href={APP_URL + '/' + row.leagueType.toLowerCase() + '/players/' + row.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span
                    className={
                      'health-icon health-icon-' +
                      (row.playerData && row.playerData.length > 0 ? row.playerData[0].health : row.health)
                    }
                  ></span>{' '}
                  {row.name.replace(',', '')}
                </a>
              ) : null}

              {row.tagType === 'team' ? (
                <a
                  href={APP_URL + '/' + row.leagueType.toLowerCase() + '/teams/' + row.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.name}
                </a>
              ) : null}

              {row.tagType === 'game' ? (
                <a
                  href={APP_URL + '/' + row.leagueType.toLowerCase() + '/games/' + row.scheduleYear + '/' + row.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {row.vistingTeamId.teamId.name} vs. {row.homeTeamId.teamId.name}
                </a>
              ) : null}

              {mode === MODE_COLLAPSED && allTags.length > 1 ? (
                <span className="tags-more" onClick={() => setMode('expanded')}>
                  + {allTags.length - 1} others
                </span>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function EmbedWidgetVideos({ data }) {
  return (
    <div className="embed-widget-videos">
      {data.map(({ _id, title, thumbnails, leagueType }) => (
        <article key={_id}>
          <figure>
            <a
              href={APP_URL + '/' + leagueType.toLowerCase() + '/videos?id=' + _id}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={thumbnails.medium.url} alt="Post media" />
            </a>
          </figure>
          <div className="post-text">
            <a
              href={APP_URL + '/' + leagueType.toLowerCase() + '/videos?id=' + _id}
              className="post-title"
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </a>
          </div>
        </article>
      ))}
    </div>
  );
}

function EmbedWidgetSicScoreGameView({ data, layout }) {
  const entries = data;

  return (
    <div className={'embed-widget-games ' + layout}>
      {entries.map((game, index) => {
        const nbaGame = new NbaGame();
        const mlbGame = new MlbGame();
        const isNba = game.leagueType.toLowerCase() === 'nba';
        const isMlb = game.leagueType.toLowerCase() === 'mlb';

        if (isNba) {
          if (!game.visitingTeam) {
            return null;
          }

          nbaGame.initGame(game);
        }

        if (isMlb) {
          mlbGame.initGame(game);
        }

        return (
          <div key={index}>
            {isNba ? <CourtView infoBoxPosition="bottom" game={nbaGame} /> : null}

            {isMlb ? <DiamondView gameData={mlbGame} /> : null}

            {!isNba && !isMlb ? <GameBox game={game} hideFlipButtonText={true} fieldBoxPosition="top" /> : null}
          </div>
        );
      })}
    </div>
  );
}

function EmbedWidgetPlayers({ data }) {
  return (
    <div className="embed-widget-players">
      {data.map(({ _id, name, status, photoUrl, leagueType, teamId, trend, slug }) => (
        <article key={_id}>
          <figure>
            <a
              href={APP_URL + '/' + leagueType.toLowerCase() + '/players/' + slug}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={photoUrl} alt="Post media" />
            </a>
          </figure>
          <div className="post-text">
            <div className="player-text">
              <div>
                <a
                  className="player-base"
                  href={APP_URL + '/' + leagueType.toLowerCase() + '/players/' + slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="player-name">{name}</span>
                  <span>
                    <strong>Status:</strong> {status}
                  </span>
                </a>
                <strong>Team:</strong>{' '}
                <a
                  href={APP_URL + '/' + leagueType.toLowerCase() + '/teams/' + teamId.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {teamId.name}
                </a>
              </div>
              <div>
                <div className="player-progress-direction">
                  <span className={trend}></span>
                </div>
              </div>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
}

function EmbedWidgetPlayerScores({ data }) {
  const context = useContext(GlobalContext);
  const { statuses } = context;

  return (
    <div className="trending-players sic-insights">
      <div className="section-header justify-between">
        <div className="section-header-start">
          <h2 className="section-title">
            <SicLogoIcon />
            Insights
          </h2>
        </div>
      </div>
      <div className="section-content">
        <div className="head-row">
          <div>Team &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Player &amp; Notes</div>
          <div>SIC Score&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        </div>
        <div className="widget-list">
          {data.map((player) => (
            <span
              to={'/' + player.leagueType.toLowerCase() + '/players/' + player.slug}
              className="player-progress-row"
              key={player._id}
            >
              <div className="player-progress-start second-layout">
                {player.teamId ? (
                  <figure>
                    <img src={player.teamId.logoUrl} alt="Player pic" />
                  </figure>
                ) : null}
                <div className="player-progress-text">
                  <div className="player-progress-title">
                    <span className="player-progress-cta">{player.name}</span>
                    {player.tag ? <span className="player-progress-tag">{player.tag}</span> : null}
                  </div>
                  <div className="player-progress-info" dangerouslySetInnerHTML={{ __html: player.playerNotes }}></div>
                </div>
              </div>
              <div className="player-progress-end">
                <span
                  className={
                    'health-sign health-' + getNewHealth(player.health, player.sicScore, player.status, statuses)
                  }
                >
                  {player.sicScore === null ? '' : player.sicScore}
                </span>
                <div className="player-progress-direction">
                  <span className={player.trend}></span>
                </div>
              </div>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
