import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoadingWheel from './LoadingWheel';
import GlobalContext from '../contexts/GlobalContext';
import { setToken } from '../helpers/session';
import { getEmailValidationRegex } from '../helpers/global';
import { API_URL, MIN_PASSWORD_LENGTH } from '../config.js';

function RegisterForm({
  successCallback = null,
  onRefreshSession = null,
  recaptcha = null,
  recaptchaToken = '',
  customCheckout = false,
  outerHandler = null,
  reloadReCaptcha = null,
  isSidedSso = false,
  customSubmit = null,
}) {
  const context = useContext(GlobalContext);
  const [form, setForm] = useState({
    email: '',
    password: '',
    cpassword: '',
    terms: false,
  });
  const [loading, setLoading] = useState(false);
  const trial = false;
  const free = false;

  useEffect(() => {
    if (outerHandler) {
      outerHandler(form);
    }
  }, [form]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (customCheckout) {
          return;
        }

        if (!getEmailValidationRegex().test(form.email)) {
          alert('Please enter a valid email address');
          return;
        }
        if (form.password.length < MIN_PASSWORD_LENGTH) {
          alert(`The password minimum length is ${MIN_PASSWORD_LENGTH} characters`);
          return;
        }
        if (form.password !== form.cpassword) {
          alert('The passwords does not match');
          return;
        }
        if (!form.terms) {
          alert('You must accept our terms of service');
          return;
        }

        setLoading(true);

        const extraFields = {};

        if (isSidedSso) {
          extraFields.name = form.email.split('@')[0];
          extraFields.username = form.email.split('@')[0];
        }

        axios
          .post(API_URL + '/user/' + (trial ? (free ? 'register-founder' : 'register-fanduel') : 'register'), {
            ...form,
            ...extraFields,
            recaptchaToken,
          })
          .then(async ({ data }) => {
            if (data.message && data.message.toLowerCase().indexOf('captcha') !== -1) {
              alert(data.message);
              setLoading(false);
              // recaptcha.execute()
              if (reloadReCaptcha) {
                reloadReCaptcha();
              }
            } else if (data.status.toLowerCase() === 'error') {
              alert(data.message);
              setLoading(false);
              // recaptcha.execute()
              if (reloadReCaptcha) {
                reloadReCaptcha();
              }
            } else {
              window.fbq('track', 'CompleteRegistration');
              window.gtag('event', 'sign_up');
              setToken(data.token);
              onRefreshSession();
              context.loadAppData();
              setTimeout(() => successCallback(), 2000);
            }
          })
          .catch((error) => {
            setLoading(false);
            alert(error.response.data.message);
            // recaptcha.execute()
            if (reloadReCaptcha) {
              reloadReCaptcha();
            }
          });
      }}
      className="auth-form"
    >
      <div className="auth-form-field">
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={form.email}
          onChange={(e) => setForm(Object.assign({}, form, { email: e.target.value }))}
        />
      </div>
      <div className="auth-form-field">
        <label>Password</label>
        <input
          type="password"
          name="password"
          value={form.password}
          onChange={(e) => setForm(Object.assign({}, form, { password: e.target.value }))}
        />
      </div>
      <div className="auth-form-field">
        <label>Confirm Password</label>
        <input
          type="password"
          name="cpassword"
          value={form.cpassword}
          onChange={(e) => setForm(Object.assign({}, form, { cpassword: e.target.value }))}
        />
      </div>
      <label className="auth-form-checkbox">
        <input
          type="checkbox"
          checked={form.terms}
          name="terms"
          onChange={() => setForm(Object.assign({}, form, { terms: !form.terms }))}
        />
        <span>
          I have read and agree to Sports Injury Central's &nbsp;
          <Link to="/terms-of-service" target="_blank">
            Terms of Service
          </Link>
          &nbsp; and &nbsp;
          <Link to="/privacy" target="_blank">
            Privacy Policy
          </Link>
        </span>
      </label>

      {customCheckout || customSubmit ? null : (
        <div className="buttons-bar">
          <button className="primary-button" disabled={loading} type="submit">
            Sign Up Now
          </button>
        </div>
      )}
      {customSubmit ?? null}

      {loading ? <LoadingWheel /> : null}
    </form>
  );
}

export default RegisterForm;
