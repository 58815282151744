import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import EditWidget from '../EditWidget';
import SicImage from '../SicImage';
import { getTimeAgo } from '../../helpers/global';
import { isAdmin } from '../../helpers/session';
import { LockIcon, SicPickIcon, SicPickLogoIcon } from '../../helpers/icons';
import GlobalContext from '../../contexts/GlobalContext';
import { SUPPORTED_LEAGUES } from '../../config';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

SwiperCore.use([Pagination, Navigation]);

export default function HeroCarouselWidget({ externalData = null, noEditButton = false }) {
  const context = useContext(GlobalContext);
  const { widgets, widgetsContent } = context;
  const widgetKey = 'hero-carousel';

  if (!widgets[widgetKey] && !externalData) {
    return null;
  }

  const selectedLeague =
    SUPPORTED_LEAGUES.indexOf(window.location.pathname) === -1 ? 'all' : window.location.pathname.replace('/', '');

  const articles =
    externalData ||
    widgetsContent[widgetKey].filter(({ leagueType }) => {
      return (
        !selectedLeague ||
        selectedLeague === 'all' ||
        (selectedLeague === 'cfb' && ['ncaaf', 'cfb'].indexOf(leagueType.toLowerCase()) !== -1) ||
        leagueType.toLowerCase() === selectedLeague.toLowerCase()
      );
    });

  if (!articles.length && !isAdmin()) {
    return null;
  }

  return (
    <div className={'news-banner-carousel' + (window.location.pathname === '/cfb' ? ' full-width' : '')}>
      {noEditButton ? null : (
        <div className="section-header justify-end">
          <EditWidget type={widgetKey} />
        </div>
      )}
      <div className="news-banner-slider" data-slide="1">
        <Swiper slidesPerView={1} pagination={{ clickable: true }} navigation={true}>
          {articles.map((article) => (
            <SwiperSlide key={article._id}>
              <Link to={context.getArticleLink(article)} className="article">
                <figure>
                  {/*
			      		<div className="top-badge">
			      			<ClockIcon />
			      			<strong>Breaking News:</strong> Happened @ 4:13 PM EST
			    			</div>
			    			*/}
                  {article.video ? (
                    <iframe src={article.video} title={article.title + ' video'}></iframe>
                  ) : (
                    <SicImage src={article.postImage.thumb} data-src={article.postImage.medium} alt="Cover" />
                  )}
                  {article.accessibility === 'paid' ? (
                    <div className="sic-pick-overlay">
                      <SicPickLogoIcon />
                      <div>
                        <LockIcon />
                        Exclusive Content
                      </div>
                    </div>
                  ) : null}
                  <figcaption>
                    <div className="post-category">{article.leagueType} News</div>
                    <h1 className="post-title">{article.title}</h1>
                    {article.breakingNews === 'yes' ? <div className="post-tag red">Breaking News</div> : null}
                    <div className="post-tag">{article.leagueType}</div>
                    {article.accessibility === 'paid' ? <SicPickIcon /> : null}
                    <div className="post-date">{getTimeAgo(article.datetime)}</div>
                  </figcaption>
                </figure>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
