import React, { useState, useContext } from 'react';
import CheckoutForm from './CheckoutForm';
import GlobalContext from '../contexts/GlobalContext';
import { SicPicksIcon } from '../helpers/icons';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { STRIPE_API_KEY, CONTACT_EMAIL } from '../config.js';

const checkedIcon =
  '<svg width="61" height="53" viewBox="0 0 61 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M54.1246 1.7644L18.3246 36.3144L8.82461 26.1644C7.07461 24.5144 4.32461 24.4144 2.32461 25.8144C0.374607 27.2644 -0.175394 29.8144 1.02461 31.8644L12.2746 50.1644C13.3746 51.8644 15.2746 52.9144 17.4246 52.9144C19.4746 52.9144 21.4246 51.8644 22.5246 50.1644C24.3246 47.8144 58.6746 6.8644 58.6746 6.8644C63.1746 2.26439 57.7246 -1.7856 54.1246 1.7144V1.7644Z" fill="#12B76A"/></svg>';

function SubscriptionFlowOption({ subscriptionType, isActive = true, isReadOnly = false }) {
  const classes = ['sicsub-option'];

  if (isActive) {
    classes.push('is-active');
  }

  if (isReadOnly) {
    classes.push('is-read-only');
  }

  return (
    <div className={classes.join(' ')}>
      <input type="radio" checked={isActive} onChange={() => {}} />
      <div>
        <div className="sicsub-option-price">
          ${subscriptionType.price}
          {subscriptionType.numberOfDays === 365 ? <span>Over $2,500 of Value</span> : null}
        </div>
        <div className="sicsub-option-title">{subscriptionType.title}</div>
        <div className="sicsub-option-description">{subscriptionType.description}</div>
      </div>
      <SicPicksIcon />
    </div>
  );
}

export default function SubscriptionFlow(props) {
  const [step, setStep] = useState(1);
  const context = useContext(GlobalContext);
  const { subscriptionType, subscriptionTypes, showSubscriptionFlow } = context;

  if (!showSubscriptionFlow) {
    return null;
  }

  return (
    <div
      className="sicsub"
      onClick={({ target }) => {
        if (target.className === 'sicsub') {
          setStep(1);
          context.setState({
            subscriptionType: null,
            showSubscriptionFlow: false,
          });
        }
      }}
    >
      <div className={'sicsub-steps current-step-' + step}>
        <div className="sicsub-steps-step step-1">
          <div className="status-bullet">
            <span className="status-checked" dangerouslySetInnerHTML={{ __html: checkedIcon }}></span>
          </div>
          <div className="sicsub-steps-text">Select</div>
        </div>
        <div className="sicsub-steps-step step-2">
          <div className="status-bullet">
            <span className="status-checked" dangerouslySetInnerHTML={{ __html: checkedIcon }}></span>
          </div>
          <div className="sicsub-steps-text">Payment</div>
        </div>
        <div className="sicsub-steps-step step-3">
          <div className="status-bullet">
            <span className="status-checked" dangerouslySetInnerHTML={{ __html: checkedIcon }}></span>
          </div>
          <div className="sicsub-steps-text">Confirmation</div>
        </div>
      </div>

      <div className="sicsub-box">
        {step === 1 ? (
          <div>
            <div className="sicsub-box-title">SELECT SUBSCRIPTION</div>

            {subscriptionTypes.map((type) => {
              const isWeekly = type.numberOfDays === 7;

              return (
                <div
                  key={type._id}
                  className="sicsub-option-holder"
                  onClick={() => context.setState({ subscriptionType: type })}
                >
                  {isWeekly && false ? (
                    <div className="temp-note">Weekly pass available Tuesday September 6th</div>
                  ) : null}
                  <SubscriptionFlowOption
                    subscriptionType={type}
                    isActive={subscriptionType && subscriptionType._id === type._id ? true : false}
                  />
                </div>
              );
            })}

            {subscriptionType ? (
              <div className="sicsub-buttons-bar">
                <button
                  onClick={() => {
                    setStep(2);
                    window.trackEvents('InitiateCheckout', { content_category: subscriptionType.title });
                  }}
                >
                  Next &nbsp; &rarr;
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        {step === 2 ? (
          <div>
            {subscriptionType ? <SubscriptionFlowOption subscriptionType={subscriptionType} isReadOnly={true} /> : null}
            <StripeProvider apiKey={STRIPE_API_KEY}>
              <Elements>
                <CheckoutForm
                  subscriptionType={subscriptionType}
                  onBack={() => setStep(1)}
                  onNext={() => {
                    setStep(3);
                    setTimeout(() => (window.location.href = '/picks'), 2000);
                  }}
                  onRefreshSession={props.onRefreshSession}
                />
              </Elements>
            </StripeProvider>
          </div>
        ) : null}

        {step === 3 ? (
          <div className="sicsub-confirmation">
            <div className="sicsub-confirmation-icon">
              <span dangerouslySetInnerHTML={{ __html: checkedIcon }}></span>
            </div>
            <div className="sicsub-confirmation-title">Success! Payment received.</div>
            <div className="sicsub-confirmation-body">
              Thank you, your payment was successful. You will receive a confirmation email / receipt shortly for your
              records.
              <br />
              <br />
              Questions? Suggestions?
              <br />
              <a href={'mailto:' + CONTACT_EMAIL}>Shoot us an email.</a>
            </div>
            <div className="sicsub-order-review">
              <div>Order Review</div>
              {subscriptionType ? (
                <div className="sicsub-order-product">
                  <div>{subscriptionType.title}</div>
                  <div>${subscriptionType.price}</div>
                </div>
              ) : null}
            </div>
            <a href="/picks" className="button full" onClick={() => context.setState({ showSubscriptionFlow: false })}>
              Close
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
