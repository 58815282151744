import axios from 'axios';
import { useContext, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { API_URL, GOOGLE_LOGIN_KEY } from '../config';
import GlobalContext from '../contexts/GlobalContext';
import { setAdmin, setRefreshToken, setToken } from '../helpers/session';
import googleIcon from '../images/google-icon.png';
export default function GoogleSocialLogin() {
  const context = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const responseGoogle = ({ tokenId }) => {
    if (tokenId) {
      axios
        .post(API_URL + '/auth/register/google', {}, { headers: { token: tokenId } })
        .then(({ data }) => {
          if (data.status === 'error') {
            alert(data.message);
            setLoading(false);
          } else {
            const isAdmin = data.data.user.roleName === 'Admin';

            setToken(data.token);
            setRefreshToken(null);
            setAdmin(isAdmin);

            context.loadAppData();
            setTimeout(() => (window.location.href = '/'), 2000);
          }
        })
        .catch((error) => {
          console.log('error ', error);
          // console.log('response ', response);
          // alert(response ? response.data.message : 'Login failed. Please reset your password.');
          setLoading(false);
        });
    } else {
      // alert('There was an error. Login failed.');
    }
  };
  return (
    <GoogleLogin
      clientId={GOOGLE_LOGIN_KEY}
      buttonText="Sign in"
      loading={loading}
      render={(renderProps) => (
        <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="google-btn">
          <img src={googleIcon} alt="Google" className="google-icon" />
          Continue with Google
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
      className="google-button"
    />
  );
}
