import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import GlobalContext from '../contexts/GlobalContext';
import { BellIcon, CloseIcon, PlusIcon } from '../helpers/icons';
import { getTimeAgo, getClassForPlayerScore } from '../helpers/global';
import SuccessSnackbar from './SuccessSnackbar';
import PlayerInsights from './PlayerInsights';
import { APP_URL } from '../config';
import { TeamAbbr } from './TeamAbbr';

function DepthChartPosition({ row, column, side, positions, game, teamLogo = '' }) {
  const context = useContext(GlobalContext);
  const [popupPlayerInfo, setPopupPlayerInfo] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const { playerNotifications, togglePlayerNotification } = context;

  const position = positions.find((pos) => pos.slot === row + ',' + column);

  if (!position) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(['depth-chart-position', 'color-' + (position.healthStatus || 1), side, 'inactive'])}
        onClick={() => {
          if (!position.featuredPlayer) {
            return;
          }

          const featuredPlayer = position.featuredPlayer;

          setPopupPlayerInfo({
            ...position,
            featuredPlayer,
            backupPlayers: position.backupPlayers,
          });
        }}
      >
        <div className="position-name">{position.position}</div>
        {position.featuredPlayer ? (
          <div className="tooltip-symbol">
            <PlusIcon />
          </div>
        ) : null}
      </div>

      {popupPlayerInfo ? (
        <div className="diamond-info transparent">
          <div className="diamond-info-inner">
            <div className="diamond-info-timestamp">Updated {getTimeAgo(game.updatedAt)}</div>
            <span className="diamond-info-close" onClick={() => setPopupPlayerInfo(null)}>
              <CloseIcon />
            </span>

            <div className="diamond-info-top">
              <div className="diamond-info-id">
                <figure>
                  {popupPlayerInfo.featuredPlayer ? (
                    <img src={popupPlayerInfo.featuredPlayer.photoUrl} alt="Player preview" />
                  ) : null}
                </figure>
                {popupPlayerInfo.featuredPlayer ? (
                  <div>
                    <div
                      className="diamond-info-player"
                      dangerouslySetInnerHTML={{ __html: popupPlayerInfo.featuredPlayer.name.replace(' ', '<br/>') }}
                    ></div>
                    <a
                      href={`${APP_URL}/${popupPlayerInfo.featuredPlayer.leagueType.toLowerCase()}/players/${
                        popupPlayerInfo.featuredPlayer.slug
                      }`}
                      className="diamond-info-link"
                    >
                      View Profile
                    </a>
                  </div>
                ) : null}
              </div>

              {popupPlayerInfo.featuredPlayer ? (
                <span
                  className={classNames({
                    'diamond-info-notify': true,
                    'is-active': playerNotifications.indexOf(popupPlayerInfo.featuredPlayer.id) !== -1,
                  })}
                  onClick={() => {
                    const id = popupPlayerInfo.featuredPlayer.id;

                    togglePlayerNotification(id);
                    setSuccessMessage(
                      playerNotifications.indexOf(id) === -1
                        ? 'Player notifications turned off'
                        : 'Player notifications turned on'
                    );
                    setTimeout(() => setSuccessMessage(''), 1000);
                  }}
                >
                  <BellIcon />
                </span>
              ) : null}
            </div>

            <div className="diamond-info-table">
              <div className="diamond-info-tr">
                <div className="diamond-info-td1">HEALTH</div>
                <div className="diamond-info-td1">TEAM</div>
                {/* <div className="diamond-info-td1">STATUS</div> */}
                <div className="diamond-info-td1">POSITION</div>
              </div>
              <div className="diamond-info-tr diamond-info-tr2">
                {popupPlayerInfo.featuredPlayer ? (
                  <div className="diamond-info-td2">
                    <figure>
                      <div className={'health-cell ' + getClassForPlayerScore(popupPlayerInfo.featuredPlayer.health)}>
                        {popupPlayerInfo.featuredPlayer.health || ''}
                      </div>
                    </figure>
                  </div>
                ) : null}
                <div className="diamond-info-td2">
                  <figure>
                    {/* <img src={teamLogo} alt="Team logo" /> */}
                    {/* <TeamAbbr team={popupPlayerInfo.teamId.abbr} /> */}
                  </figure>
                </div>
                {/* <div className="diamond-info-td2">
                  {popupPlayerInfo.featuredPlayer ? popupPlayerInfo.featuredPlayer.status : ''}
                </div> */}
                <div className="diamond-info-td2">{popupPlayerInfo.position}</div>
              </div>
            </div>

            {popupPlayerInfo.featuredPlayer &&
            popupPlayerInfo.featuredPlayer.playerNotes &&
            popupPlayerInfo.featuredPlayer.hidePlayerNotes !== 'on' ? (
              <PlayerInsights insights={popupPlayerInfo.featuredPlayer.playerNotes} />
            ) : null}

            {popupPlayerInfo.backupPlayers.length ? (
              <div>
                <div className="diamond-info-tr mbm">
                  <div className="diamond-info-td3">POS. BACK UPS</div>
                  <div className="diamond-info-td3 diamond-info-double">
                    <span>Status</span>
                    <span className="diamond-info-cross"></span>
                  </div>
                </div>
                {popupPlayerInfo.backupPlayers.map((player) => (
                  <div className="diamond-info-tr mbs" key={player._id}>
                    <div className="diamond-info-td4">
                      <a
                        href={`${APP_URL}/${player.leagueType.toLowerCase()}/players/${player.slug}`}
                        className="diamond-info-link"
                      >
                        {player.name}
                      </a>
                    </div>
                    <div className="diamond-info-td4 diamond-info-double">
                      <span>{player.status}</span>
                      <div className={'health-cell ' + getClassForPlayerScore(player.health)}></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {successMessage ? <SuccessSnackbar message={successMessage} /> : null}
    </>
  );
}

export default function GamePositions({ state, game, side, positions, teamLogo = '' }) {
  const slotsRows = [0, 1, 2];
  const slotsColumns = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div className="game-team-positions">
      {slotsRows.map((row) => {
        return (
          <div key={row + '_' + state} className="slots-row clearfix">
            {slotsColumns.map((column) => {
              return (
                <div key={column + '_' + state} className={'slots-column slots-column-' + column}>
                  <DepthChartPosition
                    row={row}
                    side={side}
                    game={game}
                    column={column}
                    positions={positions}
                    teamLogo={teamLogo}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
