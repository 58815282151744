import React, { useEffect } from 'react';
import GlobalProvider from './providers/GlobalProvider';
import GameEmbedScreen from './screens/GameEmbedScreen';
import EmbedWidgetScreen from './screens/EmbedWidgetScreen';
import EmbedHeader from './components/EmbedHeader';

export default function AppEmbed() {
  useEffect(() => {
    if (document.querySelector('.splash-screen')) {
      document.querySelector('.splash-screen').remove();
    }
  }, []);

  return (
    <GlobalProvider>
      <div className="page-embed">
        <EmbedHeader />

        {window.sicEmbedData && window.sicEmbedData.widgetId ? (
          <EmbedWidgetScreen
            match={{
              params: {
                widgetId: window.sicEmbedData.widgetId,
              },
            }}
          />
        ) : (
          <GameEmbedScreen />
        )}
      </div>
    </GlobalProvider>
  );
}
