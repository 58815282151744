import React, { Component } from 'react';
import axios from 'axios';
import GlobalContext from '../contexts/GlobalContext';
import { API_URL, SUPPORTED_LEAGUES } from '../config.js';
import { getSeason } from '../helpers/session';
import { NbaGame } from '../helpers/nba';
import { MlbGame } from '../helpers/mlb';

export default class GlobalProvider extends Component {
  state = {
    seasons: [],

    leagues: [],
    allLeagues: [],
    selectedLeague: 'all',

    allGames: [],
    nbaGames: [],
    mlbGames: [],

    latestNews: [],
    userTeams: [],
    nflPlayers: [],
    nbaPlayers: [],
    mlbPlayers: [],
    nflTeams: [],
    nbaTeams: [],
    mlbTeams: [],
    playersLoadInitiated: false,

    teamNotifications: [],
    playerNotifications: [],

    selectedArticle: null,
    loading: false,

    widgets: {
      'hero-carousel': [
        { key: 'leagueType', value: [], error: false },
        { key: 'categories', value: 'all', error: false },
        { key: 'boostedPost', value: 'yes', error: false },
        { key: 'postLimits', value: '10', error: false },
      ],
      'videos-feed': [
        { key: 'leagueType', value: [], error: false },
        { key: 'type', value: '', error: false },
        { key: 'boostedPost', value: 'yes', error: false },
        { key: 'postLimits', value: '10', error: false },
        { key: 'orientation', value: '', error: false },
      ],
      'trending-players': [
        { key: 'leagueType', value: [], error: false },
        { key: 'players', value: [], error: false },
        { key: 'postLimits', value: '10', error: false, hidden: true },
        { key: 'orientation', value: 'vertical', error: false },
      ],
      'game-feed': [
        { key: 'leagueType', value: [], error: false },
        { key: 'games', value: [], error: false },
        { key: 'categories', value: 'all', error: false },
        { key: 'postLimits', value: '10', error: false },
        { key: 'orientation', value: 'vertical', error: false },
      ],
      'updates-feed': [
        { key: 'leagueType', value: [], error: false },
        { key: 'categories', value: 'all', error: false },
        { key: 'type', value: 'both', error: false },
        { key: 'boostedPost', value: 'yes', error: false },
        { key: 'postLimits', value: '10', error: false },
        { key: 'orientation', value: 'vertical', error: false },
      ],
      'post-feed-vertical': [
        { key: 'leagueType', value: [], error: false },
        { key: 'categories', value: 'all', error: false },
        { key: 'boostedPost', value: 'yes', error: false },
        { key: 'postLimits', value: '10', error: false },
        { key: 'orientation', value: 'vertical', error: false },
      ],
      'post-feed-horizontal': [
        { key: 'leagueType', value: [], error: false },
        { key: 'categories', value: 'all', error: false },
        { key: 'boostedPost', value: 'yes', error: false },
        { key: 'postLimits', value: '10', error: false },
        { key: 'orientation', value: 'vertical', error: false },
      ],
    },

    widgetsContent: {
      'hero-carousel': [],
      'post-feed-vertical': [],
      'game-feed': [],
      'game-feed-compact': [],
      'post-feed-horizontal': [],
      'videos-feed': [],
      'trending-players': [],
    },

    postCategories: [],
    subscriptionType: null,
    subscriptionTypes: [],
    showSubscriptionFlow: false,
    statuses: [],
    currentUser: null,
  };

  componentDidMount() {
    this.loadAppData();
  }

  loadAppData = () => {
    const { pathname } = window.location;

    axios.get(API_URL + '/injuryIndex/allGames?pageNumber=1&perPage=1000&season=' + getSeason()).then(({ data }) => {
      this.setState({
        allGames: data.data.injuryIndexList,
      });
    });

    axios
      .get(API_URL + '/injuryIndex/allGames?pageNumber=1&perPage=1000&season=' + getSeason(), {
        headers: {
          league_type: 'nba',
        },
      })
      .then(({ data }) => {
        const nbaGames = data.data.injuryIndexList.map((game) => {
          const nbaGame = new NbaGame();
          nbaGame.initGame(game);

          return nbaGame;
        });

        axios
          .get(API_URL + '/injuryIndex/allGames?pageNumber=1&perPage=1000&season=2023', {
            headers: {
              league_type: 'nba',
            },
          })
          .then(({ data }) => {
            this.setState({
              nbaGames: nbaGames.concat(
                data.data.injuryIndexList.map((game) => {
                  const nbaGame = new NbaGame();
                  nbaGame.initGame(game);

                  return nbaGame;
                })
              ),
            });
          });
      });

    axios
      .get(API_URL + '/injuryIndex/allGames?pageNumber=1&perPage=1000&season=2023', {
        headers: {
          league_type: 'mlb',
        },
      })
      .then(({ data }) => {
        this.setState({
          mlbGames: data.data.injuryIndexList.map((game) => {
            const mlbGame = new MlbGame();
            mlbGame.initGame(game);

            return mlbGame;
          }),
        });
      });

    axios.get(API_URL + '/season').then(({ data }) => {
      this.setState({
        seasons: data.data.season,
      });
    });

    axios.get(API_URL + '/league').then(({ data }) => {
      this.setState({
        leagues: data.data.leagues,
        allLeagues: [
          {
            name: 'All',
            _id: 'all',
          },
        ].concat(data.data.leagues),
      });
    });

    axios.get(API_URL + '/player/status').then(({ data }) => this.setState({ statuses: data.data }));
    axios.get(API_URL + '/postCategory').then(({ data }) => this.setState({ postCategories: data.data.categories }));
    this.loadTeamNotification();
    this.loadPlayerNotification();
    this.loadTeams();
    this.loadUserTeams(pathname.indexOf('/nba/') !== -1 ? 'nba' : 'nfl');

    axios.get(API_URL + '/subscription').then(({ data }) => {
      this.setState({ subscriptionTypes: data.data.subscriptions.reverse() });
    });

    this.loadTeamNotification();
    this.loadPlayerNotification();
    this.loadTeams();
    this.loadUserTeams(pathname.indexOf('/nba/') !== -1 ? 'nba' : 'nfl');

    axios.get(API_URL + '/user/me').then(({ data }) => this.setState({ currentUser: data.data }));
  };

  loadPlayers = () => {
    this.setState({ playersLoadInitiated: true });

    axios
      .get(API_URL + '/player', { headers: { league_type: 'nfl' } })
      .then(({ data }) => this.setState({ nflPlayers: data.data.playerList }));
    axios
      .get(API_URL + '/player', { headers: { league_type: 'nba' } })
      .then(({ data }) => this.setState({ nbaPlayers: data.data.playerList }));
    axios
      .get(API_URL + '/player', { headers: { league_type: 'mlb' } })
      .then(({ data }) => this.setState({ mlbPlayers: data.data.playerList }));
  };

  loadTeamNotification = () => {
    axios.get(API_URL + '/team/getAllNotification').then(({ data }) => {
      this.setState({
        teamNotifications: data.data ? data.data.filter(({ status }) => status).map(({ teamId }) => teamId) : [],
      });
    });
  };

  loadPlayerNotification = () => {
    axios.get(API_URL + '/player/getAllNotification').then(({ data }) => {
      this.setState({
        playerNotifications: data.data ? data.data.filter(({ status }) => status).map(({ playerId }) => playerId) : [],
      });
    });
  };

  loadUserTeams = (leagueType = false, callback = false) => {
    const { selectedLeague, leagues } = this.state;

    if (!leagueType) {
      leagueType = selectedLeague === 'all' ? 'nfl' : leagues.find(({ _id }) => _id === selectedLeague).name;
    }

    this.setState({ loading: true, userTeams: [] });

    axios
      .get(API_URL + '/userTeams?season=' + getSeason(), { headers: { league_type: leagueType.toLowerCase() } })
      .then(({ data }) => {
        this.setState({
          userTeams: data.data.team,
          loading: false,
        });

        if (callback) {
          callback();
        }
      })
      .catch(() => this.setState({ loading: false }));
  };

  loadTeams() {
    axios.get(API_URL + '/team?season=' + getSeason(), { headers: { league_type: 'nfl' } }).then(({ data }) => {
      this.setState({ nflTeams: data.data.teamList });
    });

    axios.get(API_URL + '/team?season=' + getSeason(), { headers: { league_type: 'nba' } }).then(({ data }) => {
      this.setState({ nbaTeams: data.data.teamList });
    });

    axios.get(API_URL + '/team?season=' + getSeason(), { headers: { league_type: 'mlb' } }).then(({ data }) => {
      this.setState({ mlbTeams: data.data.teamList });
    });
  }

  render() {
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,

          setState: (updates) => {
            this.setState(updates);
          },

          setLeague: (selectedLeague) => {
            const { leagues } = this.state;

            if (
              !leagues.find(({ _id }) => _id === selectedLeague) &&
              leagues.find(({ name }) => name === selectedLeague)
            ) {
              this.setState({ selectedLeague: leagues.find(({ name }) => name === selectedLeague)._id });
            } else {
              this.setState({ selectedLeague });
            }
          },

          loadLatestNews: (league = 'nfl') => {
            axios
              .get(API_URL + '/post/public?type=docLiveUpdate', { headers: { league_type: league || 'nfl' } })
              .then((response) => {
                this.setState({
                  latestNews: response.data.data.postList,
                });
              });
          },

          loadWidgetsContent: () => {
            const { pathname } = window.location;

            axios
              .get(
                API_URL +
                  '/widget/homeWidgetsInfo?page=' +
                  (SUPPORTED_LEAGUES.indexOf(pathname) !== -1 ? pathname.replace('/', '') : 'home')
              )
              .then(({ data }) => {
                const { widgetsContent } = this.state;

                for (let i in data.data) {
                  widgetsContent[
                    i
                      .replace('gameFeedCompactLists', 'game-feed-compact')
                      .replace('gameFeedLists', 'game-feed')
                      .replace('heroCaroselLists', 'hero-carousel')
                      .replace('postFeedHorizontalLists', 'post-feed-horizontal')
                      .replace('postFeedVerticalLists', 'post-feed-vertical')
                      .replace('trendingPlayersLists', 'trending-players')
                      .replace('updatesFeedLists', 'updates-feed')
                      .replace('videoFeedLists', 'videos-feed')
                  ] = data.data[i];
                }

                this.setState({ widgetsContent });
              });
          },

          toggleTeamNotification: (teamId) => {
            const { teamNotifications } = this.state;
            const payload = { status: true };

            if (teamNotifications.indexOf(teamId) === -1) {
              teamNotifications.push(teamId);
            } else {
              teamNotifications.splice(teamNotifications.indexOf(teamId), 1);
              payload.status = false;
            }

            this.setState({ teamNotifications });

            axios.put(API_URL + '/team/toggleNotification/' + teamId, payload);
          },

          togglePlayerNotification: (playerId) => {
            const { playerNotifications } = this.state;
            const payload = { status: true };

            if (playerNotifications.indexOf(playerId) === -1) {
              playerNotifications.push(playerId);
            } else {
              playerNotifications.splice(playerNotifications.indexOf(playerId), 1);
              payload.status = false;
            }

            this.setState({ playerNotifications });

            axios.put(API_URL + '/player/toggleNotification/' + playerId, payload);
          },

          loadAppData: () => {
            this.loadAppData();
          },

          loadPlayers: () => {
            this.loadPlayers();
          },

          loadUserTeams: (leagueType = false, callback = false) => {
            this.loadUserTeams(leagueType, callback);
          },

          loadArticle: (slug, league = null) => {
            this.setState({
              loading: true,
              selectedArticle: null,
            });

            axios
              .get(API_URL + '/post/details/' + slug, league ? { headers: { league_type: league } } : {})
              .then(({ data }) => {
                const article = data.data;

                this.setState({
                  selectedArticle: article,
                  loading: false,
                });

                if (!article) {
                  window.location.href = '/404';
                } else {
                  document.title = article.title;
                  console.log('AAAAA => ', article.title, document.title);

                  setTimeout(function () {
                    // Twitter embeds
                    const tag = document.createElement('script');
                    tag.src = '//platform.twitter.com/widgets.js';
                    document.getElementsByTagName('head')[0].appendChild(tag);

                    // Sided embeds
                    window.InitSidedEmbeds();
                    window.addEventListener('scroll', window.InitSidedEmbeds);
                  }, 1000);
                }
              });
          },

          displaySubscriptionFlow: (subscriptionType = null) => {
            const updates = { showSubscriptionFlow: true };

            window.trackEvents('AddToCart');

            if (subscriptionType) {
              updates.subscriptionType = subscriptionType;
            }

            this.setState(updates);
          },

          getArticleLink: (article) => {
            if (article.accessibility === 'paid') {
              return '/picks';
            }

            return '/' + article.leagueType.toLowerCase() + '/updates/' + article.slug;
          },
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
