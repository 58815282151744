import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function RunConverge() {
  const location = useLocation();
  useEffect(() => {
    window.cvg({ method: 'track', eventName: '$page_load' });
  }, [location]);
  return <></>;
}
