import React, { Component } from 'react';
import axios from 'axios';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import LoadingWheel from './LoadingWheel';
import { API_URL, MIN_PASSWORD_LENGTH } from '../config';
import { getToken, setToken, setAdmin } from '../helpers/session';
import { getEmailValidationRegex } from '../helpers/global';
import GlobalContext from '../contexts/GlobalContext';

class CheckoutForm extends Component {
  constructor() {
    super();

    this.state = {
      form: {
        name: '',
        zip: '',
        couponCode: '',
        email: '',
        password: '',
        cpassword: '',
        terms: false,
      },
      errors: {
        name: false,
        zip: false,
        couponCode: false,
      },
      loading: false,
      stripeError: '',
      couponCodeText: '',
      couponStatus: '',
      couponMessage: '',
      authMode: 'register',
      authToken: getToken(),
    };
  }

  updateFormModel = (e) => {
    const { form, errors } = this.state;
    const { target } = e;
    const { name, value } = target;

    form[name] = value;
    errors[name] = false;

    this.setState({
      form: form,
      errors: errors,
    });
  };

  onSubmit = async (e) => {
    const { form, errors } = this.state;
    const { subscriptionType } = this.props;
    let hasErrors = false;

    if (form.name === '') {
      errors.name = true;
      hasErrors = true;
    }
    if (form.zip === '') {
      errors.zip = true;
      hasErrors = true;
    }

    if (hasErrors) {
      this.setState({ errors });
    } else {
      this.setState({
        loading: true,
        stripeError: '',
      });

      const { token, error } = await this.props.stripe.createToken({ name: form.name });

      if (typeof error !== 'undefined') {
        this.setState({
          stripeError: error.message,
          loading: false,
        });
      } else {
        axios
          .post(API_URL + '/subscription/user/subscribe', {
            stripeTokenId: token.id,
            subscriptionId: subscriptionType._id,
            couponCode: form.couponCode,
          })
          .then(() => {
            window.gtag('event', 'purchase', { value: subscriptionType.price });

            axios
              .get(API_URL + '/user/me')
              .then(({ data }) => {
                const { name, email, phone } = data.data;
                const firstName = name.indexOf(' ') !== -1 ? name.split(' ')[0] : name;
                const lastName = name.replace(firstName, '').trim();

                window.trackEvents('Purchase', {
                  value: subscriptionType.price,
                  currency: 'USD',
                  email: email,
                  phone: phone,
                  'last name': lastName,
                  'first name': firstName,
                  'click id': '',
                });

                this.setState({ loading: false });
                this.props.handleConfirmation();
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                  stripeError: error.response.data.message,
                });
              });
          })
          .catch((error) => {
            this.setState({
              loading: false,
              stripeError: error.response.data.message,
            });
          });
      }
    }
  };

  onSubmitGuest = async (e) => {
    const { form, errors } = this.state;
    const { subscriptionType } = this.props;
    let hasErrors = false;

    if (!getEmailValidationRegex().test(form.email)) {
      alert('Please enter a valid email address');
      return;
    }
    if (form.password.length < MIN_PASSWORD_LENGTH) {
      alert(`The password minimum length is ${MIN_PASSWORD_LENGTH} characters`);
      return;
    }
    if (form.password !== form.cpassword) {
      alert('The passwords does not match');
      return;
    }
    if (!form.terms) {
      alert('You must accept our terms of service');
      return;
    }

    if (hasErrors) {
      this.setState({ errors });
    } else {
      this.setState({
        loading: true,
        stripeError: '',
      });

      const { token, error } = await this.props.stripe.createToken({ name: form.name });

      if (typeof error !== 'undefined') {
        this.setState({
          stripeError: error.message,
          loading: false,
        });
      } else {
        axios
          .post(API_URL + '/subscription/guest/subscribe', {
            stripeTokenId: token.id,
            subscriptionId: subscriptionType._id,
            couponCode: form.couponCode,
            name: form.name,
            email: form.email,
            password: form.password,
          })
          .then(() => {
            window.gtag('event', 'purchase', { value: subscriptionType.price });
            window.trackEvents('Purchase', { value: subscriptionType.price, currency: 'USD' });

            axios
              .post(API_URL + '/auth/authenticate', {
                email: form.email,
                password: form.password,
              })
              .then((response) => {
                if (response.data.status === 'error') {
                  this.setState({
                    loading: false,
                    stripeError: response.data.message,
                  });
                } else {
                  setToken(response.data.token);
                  setAdmin(false);
                  this.setState({ loading: false });
                  this.props.handleConfirmation();
                }
              });
          })
          .catch((error) => {
            this.setState({
              loading: false,
              stripeError: error.response.data.message,
            });
          });
      }
    }
  };

  onCouponCodeApply = () => {
    const { form } = this.state;
    const { subscriptionType } = this.props;

    this.setState({
      loading: true,
      couponStatus: '',
      couponMessage: '',
    });

    axios
      .post(API_URL + '/subscription/coupon', {
        couponCode: form.couponCode,
        subscriptionId: subscriptionType._id,
      })
      .then(({ data }) => {
        // document.querySelector('.sicsub-option-price').innerHTML = '$' + data.data.amount;
        this.props.handleCoupon && this.props.handleCoupon(data.data);
        this.setState({
          loading: false,
          couponStatus: 'success',
          couponMessage: data.data.couponMessage,
        });
      })
      .catch(({ response }) => {
        this.setState({
          loading: false,
          couponStatus: 'error',
          couponMessage: response.data.message,
        });
      });
  };

  render() {
    const { state } = this;
    const { form, errors, loading, stripeError, couponStatus, couponMessage, authMode } = state;
    const { handleConfirmation } = this.props;
    console.log('state ', state);

    return (
      <div className="auth-form">
        {loading ? <LoadingWheel /> : null}

        {!state.authToken ? (
          <>
            <ul className="auth-form-tabs">
              <li
                className={authMode === 'register' ? 'active' : ''}
                onClick={() => this.setState({ authMode: 'register' })}
              >
                Sign Up
              </li>
              <li className={authMode === 'login' ? 'active' : ''} onClick={() => this.setState({ authMode: 'login' })}>
                Login
              </li>
            </ul>
            {authMode === 'login' ? (
              <LoginForm onRefreshSession={() => this.setState({ authToken: getToken() })} successCallback={() => {}} />
            ) : (
              <RegisterForm
                customCheckout={true}
                outerHandler={(payload) =>
                  this.setState({
                    form: Object.assign({}, form, payload),
                  })
                }
              />
            )}
          </>
        ) : null}

        {!state.authToken && authMode === 'login' ? null : (
          <>
            <div className="auth-form-title">Payment Information</div>
            <div className="auth-form-field">
              <label>Name</label>
              <input type="text" name="name" value={form.name} onChange={this.updateFormModel} />
              {errors.name ? <div className="error-message">Please enter your name</div> : null}
            </div>
            <div className="auth-form-field">
              <label>Card</label>
              <div className="input">
                <CardNumberElement />
              </div>
            </div>
            <div className="auth-form-field three-fields">
              <div>
                <label>Expiry</label>
                <div className="input">
                  <CardExpiryElement />
                </div>
              </div>
              <div>
                <label>CVC</label>
                <div className="input">
                  <CardCvcElement />
                </div>
              </div>
              <div>
                <label>Zip</label>
                <input type="text" name="zip" value={form.zip} onChange={this.updateFormModel} />
                {errors.zip ? <div className="error-message">Please enter the zipcode</div> : null}
              </div>
            </div>
            <div className="auth-form-field">
              <label>Coupon code</label>
              <input type="text" name="couponCode" value={form.couponCode} onChange={this.updateFormModel} />
              <span className="confirmation-button" onClick={this.onCouponCodeApply}>
                Apply
              </span>
              {couponStatus === 'error' ? <div className="error-message">{couponMessage}</div> : null}
              {couponStatus === 'success' ? <div className="success-message">{couponMessage}</div> : null}
            </div>
            <div className="global-error">{stripeError}</div>
            <div className="sicsub-bottom-bar">
              <button
                className="secondary-button bordered width-full"
                disabled={this.state.loading}
                onClick={() => {
                  if (state.authToken) {
                    this.onSubmit();
                  } else {
                    this.onSubmitGuest();
                  }
                }}
              >
                Pay ${this.props.price && this.props.price}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }
}

CheckoutForm.contextType = GlobalContext;

export default injectStripe(CheckoutForm);
