import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { API_URL } from '../config.js';

function PlayerSelector({ onChange, value, leagues }) {
  const [playersSearch, setPlayerSearch] = useState('');
  const [playersFound, setPlayersFound] = useState([]);
  const [playersSelected, setPlayersSelected] = useState(value);
  const [loading, setLoading] = useState(false);
  const debouncedChangeHandler = debounce((query) => setPlayerSearch(query), 500);

  useEffect(() => {
    if (playersSearch.trim() === '') {
      setPlayersFound([]);
    } else {
      setLoading(true);

      axios.get(API_URL + '/search?search=' + playersSearch).then(({ data }) => {
        setPlayersFound(data.data ? data.data.filter(({ type }) => type === 'player') : []);
        setLoading(false);
      });
    }
  }, [playersSearch]);

  return (
    <div className="notifications-selectors mb-s">
      <div className="notifications-col full-width">
        <div className="notifications-selector">
          <div className="notifications-search">
            <input
              type="text"
              placeholder="Search for a player"
              onChange={({ target }) => debouncedChangeHandler(target.value)}
            />
            <span className="notifications-icon"></span>
          </div>
          {loading ? (
            <div>
              <em>Searching, please wait...</em>
            </div>
          ) : null}
          <div className="notifications-scroller">
            {playersSelected.map((playersSelectedRow, playersSelectedIndex) => {
              const playersSelectedRowTag = playersSelectedRow.tag || 'more';

              return (
                <div
                  key={playersSelectedRow._id}
                  className="notifications-entry"
                  onClick={(e) => {
                    const checkbox = e.target.parentElement.querySelector('.chk-box');

                    if (!checkbox) {
                      return;
                    }

                    checkbox.classList.toggle('checked');
                    playersSelected.splice(
                      playersSelected.findIndex(({ _id }) => _id === playersSelectedRow._id),
                      1
                    );
                    setPlayersSelected(playersSelected);
                    onChange(playersSelected);
                  }}
                >
                  <div className="notifications-cell notifications-cell-40">
                    <span className="chk-box checked"></span>
                    <span>
                      {playersSelectedRow.playerId ? playersSelectedRow.playerId.name : playersSelectedRow.name || '-'}
                    </span>
                  </div>
                  <div className="notifications-cell notifications-cell-20">
                    <select
                      value={playersSelectedRowTag}
                      onChange={({ target }) => {
                        playersSelected[playersSelectedIndex].tag = target.value;
                        playersSelected[playersSelectedIndex].datetime = moment().format();
                        setPlayersSelected(playersSelected);
                        onChange(playersSelected);
                      }}
                    >
                      <option value="more">More</option>
                      <option value="article">Article</option>
                      <option value="video">Video</option>
                    </select>
                  </div>
                  <div className="notifications-cell notifications-cell-30">
                    <select
                      value={playersSelectedRow.trend}
                      onChange={({ target }) => {
                        playersSelected[playersSelectedIndex].trend = target.value;
                        playersSelected[playersSelectedIndex].datetime = moment().format();
                        setPlayersSelected(playersSelected);
                        onChange(playersSelected);
                      }}
                    >
                      <option value="blank">Blank</option>
                      <option value="dash">-</option>
                      <option value="up">Up - Green</option>
                      <option value="up-yellow">Up - Yellow</option>
                      <option value="up-red">Up - Red</option>
                      <option value="down-green">Down - Green</option>
                      <option value="down-yellow">Down - Yellow</option>
                      <option value="down">Down - Red</option>
                    </select>
                  </div>
                  <div className="notifications-cell notifications-cell-10">
                    <select
                      value={playersSelectedIndex}
                      onChange={({ target }) => {
                        const newIndex = parseInt(target.value);
                        const updatedRow = { ...playersSelected[playersSelectedIndex] };

                        playersSelected[playersSelectedIndex] = { ...playersSelected[newIndex] };
                        playersSelected[newIndex] = updatedRow;

                        setPlayersSelected(playersSelected);
                        onChange(playersSelected);
                      }}
                    >
                      {new Array(20).fill().map((_, i) => (
                        <option value={i} key={i}>
                          {i + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              );
            })}
            <hr />
            {playersFound
              .filter((player) => !playersSelected.find(({ _id }) => _id === player._id))
              .map((player) => {
                return (
                  <div
                    key={player._id}
                    className="notifications-entry"
                    onClick={(e) => {
                      const checkbox = e.target.parentElement.querySelector('.chk-box');

                      if (!checkbox) {
                        return;
                      }

                      checkbox.classList.toggle('checked');

                      playersSelected.push({
                        ...player,
                        tag: 'more',
                        trend: 'up',
                        datetime: moment().format(),
                      });

                      setPlayersSelected(playersSelected);
                      onChange(playersSelected);
                    }}
                  >
                    <div className="notifications-cell notifications-cell-60">
                      <span className="chk-box"></span>
                      <span>{player.name}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerSelector;
