import React, { Component } from 'react';

export default class SicImage extends Component {
  constructor() {
    super();

    this.img = this.myRef = React.createRef();
  }

  checkImage = () => {
    const img = this.img.current;

    if (!img) return;

    const bounds = img.getBoundingClientRect();

    if (bounds.top < window.innerHeight && !img.classList.contains('is-loaded') && img.attributes['data-src']) {
      img.src = img.attributes['data-src'].value;
      img.classList.add('is-loaded');
    }
  };

  componentDidMount() {
    this.checkImage();
    window.addEventListener('scroll', () => this.checkImage());
  }

  render = () => <img ref={this.img} {...this.props} alt={this.props.alt} />;
}
