import React, { Component } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import GamePositions from './GamePositions';
import { getTeamShortName, getClassForTeamScore, parseGameSchedule } from '../helpers/global';
import moment from 'moment-timezone';
import { APP_URL } from '../config.js';
import { TeamAbbr } from './TeamAbbr.js';

const InfoBox = ({ game, hideRedirectLink }) => (
  <div className="game-listing">
    <div className="game-listing-main">
      <h2 className="game-title">
        {game.visitingTeamDetails ? game.visitingTeamDetails.name : ''} vs.{' '}
        {game.homeTeamDetails ? game.homeTeamDetails.name : ''}
      </h2>
      <div className="game-dates">
        <span>Game Date: {parseGameSchedule(game.gameSchedule).format('M/D/YY')}</span>
        <span className="game-date-separator">&nbsp; | &nbsp; </span>
        <span>Game Time: {parseGameSchedule(game.gameSchedule).format('h:mm A z')}</span>
        <span className="game-date-separator">&nbsp; | &nbsp; </span>
        <span>
          Last updated @ {moment(game.scoreUpdated).format('h:mm A')}{' '}
          {moment(game.gameDate).tz(moment.tz.guess()).format('z')}
        </span>
      </div>
      {hideRedirectLink ? null : (
        <a
          href={APP_URL + '/' + game.leagueType.toLowerCase() + '/games/' + game.scheduleYear + '/' + game.slug}
          target="_blank"
          rel="noopener noreferrer"
          className="redirect-link"
        >
          Read Full Game Preview
        </a>
      )}
    </div>
    <div className="game-listing-logos">
      <figure className="item-logo transparent">
        {game.visitingTeamDetails ? <TeamAbbr team={game.visitingTeamDetails.abbreviation} /> : null}
      </figure>
      <span> vs. </span>
      <figure className="item-logo transparent">
        {game.homeTeamDetails ? <TeamAbbr team={game.homeTeamDetails.abbreviation} /> : null}
      </figure>
    </div>
  </div>
);

const TableBox = ({ game, expanded }) => (
  <div className="game-stats">
    <div className="update-box">
      <div className="v4-visible v4-box-date">
        {parseGameSchedule(game.gameSchedule).format('MMMM D, YYYY @ h:mmA z')}
      </div>
      <div className="game-box game-box-header">
        <div className="game-box-row">
          <Typography component="div" className="game-box-col game-box-col-name game-box-col-select">
            &nbsp;
          </Typography>
          <Typography component="div" className="game-box-col">
            Spread
          </Typography>
          <Typography component="div" className="game-box-col">
            Money
          </Typography>
          <Typography component="div" className="game-box-col">
            O/U
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            Team
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            Offense
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            Defense
          </Typography>
        </div>
      </div>
      <div className="game-box game-box-content">
        <div className="game-box-row">
          <Typography component="div" className="game-box-col game-box-col-name">
            {game.visitingTeamDetails ? <TeamAbbr team={game.visitingTeamDetails.abbreviation} /> : null}
          </Typography>
          <Typography component="div" className="game-box-col">
            <div>
              {game.visitingTeam && game.visitingTeam.points
                ? (game.visitingTeam.points > 0 ? '+' : '') + game.visitingTeam.points
                : ''}
            </div>
          </Typography>
          <Typography component="div" className="game-box-col">
            <div>{game.visitingTeam ? game.visitingTeam.money : ''}</div>
          </Typography>
          <Typography component="div" className="game-box-col">
            &nbsp;
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.teamIndex : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.teamIndex : '-'}
            </div>
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.offenseIndex : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.offenseIndex : '-'}
            </div>
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.defenseIndex : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.defenseIndex : '-'}
            </div>
          </Typography>
        </div>
        <div className="game-box-row">
          <Typography component="div" className="game-box-col game-box-col-name">
            {game.homeTeamDetails ? <TeamAbbr team={game.homeTeamDetails.abbreviation} /> : null}
          </Typography>
          <Typography component="div" className="game-box-col">
            {game.homeTeam && game.homeTeam.points ? (game.homeTeam.points > 0 ? '+' : '') + game.homeTeam.points : ''}
          </Typography>
          <Typography component="div" className="game-box-col">
            {game.homeTeam ? game.homeTeam.money : ''}
          </Typography>
          <Typography component="div" className="game-box-col">
            {game.OU}
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            <div
              className={'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.teamIndex : '-')}
            >
              {game && game.homeTeam ? game.homeTeam.teamIndex : '-'}
            </div>
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.offenseIndex : '-')
              }
            >
              {game && game.homeTeam ? game.homeTeam.offenseIndex : '-'}
            </div>
          </Typography>
          <Typography component="div" className="game-box-col game-box-col-switcher">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.defenseIndex : '-')
              }
            >
              {game && game.homeTeam ? game.homeTeam.defenseIndex : '-'}
            </div>
          </Typography>
        </div>
      </div>
    </div>
    <div className={classNames(['extended-game-box', expanded ? 'is-active' : ''])}>
      <div className="one-half">
        <div className="line-first">
          <div className="col-field col-field-lbl">
            {getTeamShortName(game.homeTeamDetails ? game.visitingTeamDetails.name : '')} <span>Defense</span>
          </div>
          <div className="col-label"></div>
          <div className="col-field col-field-lbl">
            {getTeamShortName(game.homeTeamDetails ? game.homeTeamDetails.name : '')} <span>Offense</span>
          </div>
        </div>
        <div className="line-second">
          <div className="col-field">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.defenseRush : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.defenseRush : '-'}
            </div>
          </div>
          <div className="col-label">Run</div>
          <div className="col-field">
            <div
              className={'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.offenseRush : '-')}
            >
              {game && game.homeTeam ? game.homeTeam.offenseRush : '-'}
            </div>
          </div>
        </div>
        <div className="line-second">
          <div className="col-field">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.defensePass : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.defensePass : '-'}
            </div>
          </div>
          <div className="col-label">Pass</div>
          <div className="col-field">
            <div
              className={'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.offensePass : '-')}
            >
              {game && game.homeTeam ? game.homeTeam.offensePass : '-'}
            </div>
          </div>
        </div>
      </div>
      <div className="one-half">
        <div className="line-first">
          <div className="col-field col-field-lbl">
            {getTeamShortName(game.visitingTeamDetails ? game.visitingTeamDetails.name : '')} <span>Offense</span>
          </div>
          <div className="col-label"></div>
          <div className="col-field col-field-lbl">
            {getTeamShortName(game.homeTeamDetails ? game.homeTeamDetails.name : '')} <span>Defense</span>
          </div>
        </div>
        <div className="line-second">
          <div className="col-field">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.offenseRush : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.offenseRush : '-'}
            </div>
          </div>
          <div className="col-label">Run</div>
          <div className="col-field">
            <div
              className={'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.defenseRush : '-')}
            >
              {game && game.homeTeam ? game.homeTeam.defenseRush : '-'}
            </div>
          </div>
        </div>
        <div className="line-second">
          <div className="col-field">
            <div
              className={
                'health-cell ' + getClassForTeamScore(game && game.visitingTeam ? game.visitingTeam.offensePass : '-')
              }
            >
              {game && game.visitingTeam ? game.visitingTeam.offensePass : '-'}
            </div>
          </div>
          <div className="col-label">Pass</div>
          <div className="col-field">
            <div
              className={'health-cell ' + getClassForTeamScore(game && game.homeTeam ? game.homeTeam.defensePass : '-')}
            >
              {game && game.homeTeam ? game.homeTeam.defensePass : '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const FieldBox = ({ game, depthChartReversed, expanded, hideFlipButtonText, state, flipField }) => (
  <div className={classNames(['depth-chart', expanded ? 'is-active' : ''])}>
    <div className="game-team-name">
      {depthChartReversed
        ? game.visitingTeamDetails
          ? game.visitingTeamDetails.name
          : ''
        : game.homeTeamDetails
        ? game.homeTeamDetails.name
        : ''}
      <small>Defense</small>
    </div>
    {depthChartReversed ? (
      <GamePositions
        positions={game.visitingTeam ? game.visitingTeam.defense_score : []}
        state={state}
        team="visiting"
        side="defense"
        game={game}
        teamLogo={game.visitingTeamDetails.logoUrl}
      />
    ) : (
      <GamePositions
        positions={game.homeTeam ? game.homeTeam.defense_score : []}
        state={state}
        team="home"
        side="defense"
        game={game}
        teamLogo={game.homeTeamDetails.logoUrl}
      />
    )}
    <div className="separator">
      <div className="dashed"></div>
      <IconButton onClick={flipField}>
        <span className="cross-arrows-icon">
          <span></span>
          <span></span>
        </span>
        {hideFlipButtonText ? null : (
          <>
            <span className="button-text button-text-dsk">Flip Offense / Defense</span>
            <span className="button-text button-text-mob">Flip Def/Off</span>
          </>
        )}
      </IconButton>
    </div>
    {depthChartReversed ? (
      <GamePositions
        positions={game.homeTeam ? game.homeTeam.offense_score : []}
        state={state}
        team="home"
        side="offense"
        game={game}
        teamLogo={game.homeTeamDetails.logoUrl}
      />
    ) : (
      <GamePositions
        positions={game.visitingTeam ? game.visitingTeam.offense_score : []}
        state={state}
        team="visiting"
        side="offense"
        game={game}
        teamLogo={game.visitingTeamDetails.logoUrl}
      />
    )}
    <div className="game-team-name at-bottom">
      <small>Offense</small>
      {depthChartReversed
        ? game.homeTeamDetails
          ? game.homeTeamDetails.name
          : []
        : game.visitingTeamDetails
        ? game.visitingTeamDetails.name
        : ''}
    </div>
  </div>
);

class GameBox extends Component {
  constructor() {
    super();

    this.state = {
      depthChartReversed: false,
      state: 1,
    };
  }

  componentDidMount() {
    const { game } = this.props;
    const { state } = this.state;

    this.setState({
      depthChartReversed: game.defaultSide === 'Visiting',
      state: state * 2,
    });
  }

  flipField = () =>
    this.setState({
      depthChartReversed: !this.state.depthChartReversed,
      state: this.state.state * 2,
    });

  render() {
    const { depthChartReversed, state } = this.state;
    const { infoBoxPosition, fieldBoxPosition } = this.props;

    return (
      <div className="game-wrapper">
        {fieldBoxPosition === 'top' ? (
          <FieldBox {...this.props} depthChartReversed={depthChartReversed} state={state} flipField={this.flipField} />
        ) : null}

        {infoBoxPosition === 'top' ? <InfoBox {...this.props} /> : null}

        <TableBox {...this.props} />

        {fieldBoxPosition === 'default' ? (
          <FieldBox {...this.props} depthChartReversed={depthChartReversed} state={state} flipField={this.flipField} />
        ) : null}

        {infoBoxPosition === 'bottom' ? <InfoBox {...this.props} /> : null}
      </div>
    );
  }
}

export default GameBox;

GameBox.defaultProps = {
  expanded: false,
  hideRedirectLink: false,
  hideFlipButtonText: false,
  infoBoxPosition: 'top',
  fieldBoxPosition: 'default',
};
