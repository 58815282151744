import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoadingWheel from './LoadingWheel';
import GlobalContext from '../contexts/GlobalContext';
import { getEmailValidationRegex } from '../helpers/global';
import { setToken, setRefreshToken, setAdmin } from '../helpers/session';
import { API_URL } from '../config.js';

function LoginForm({ onRefreshSession, successCallback }) {
  const context = useContext(GlobalContext);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!getEmailValidationRegex().test(form.email)) {
          alert('Please enter a valid email address');
        } else {
          setLoading(true);

          axios
            .post(API_URL + '/auth/authenticate', form)
            .then(({ data }) => {
              if (data.status === 'error') {
                alert(data.message);
                setLoading(false);
              } else {
                const isAdmin = data.data.user.roleName === 'Admin';
                setToken(data.token);
                setRefreshToken(data.refreshToken);
                setAdmin(isAdmin);

                onRefreshSession();
                context.loadAppData();
                setTimeout(() => successCallback(), 2000);
              }
            })
            .catch((error) => {
              alert(error.response ? error.response.data.message : 'Login failed. Please reset your password.');
              setLoading(false);
            });
        }
      }}
      className="auth-form"
    >
      <div className="auth-form-field">
        <label>Username / Email</label>
        <input
          type="email"
          name="email"
          value={form.email}
          onChange={(e) => setForm(Object.assign({}, form, { email: e.target.value }))}
        />
      </div>
      <div className="auth-form-field">
        <label>Password</label>
        <input
          type="password"
          name="password"
          value={form.password}
          onChange={(e) => setForm(Object.assign({}, form, { password: e.target.value }))}
        />
        <div className="forgot-bar">
          <Link
            to="/forgot"
            onClick={() => context.setState({ showSubscriptionFlow: false })}
            className="no-underline text-button"
          >
            Forgot password
          </Link>
        </div>
      </div>
      <div className="buttons-bar">
        <button className="primary-button" disabled={loading} type="submit">
          Login
        </button>
      </div>

      {loading ? <LoadingWheel /> : null}
    </form>
  );
}

export default LoginForm;
