import React from 'react';
import classNames from 'classnames';
import { PlusIcon } from '../helpers/icons';

export default function CourtViewPosition({ position, className, onClick }) {
  const { healthStatus } = position;
  const healthClass = position.position !== '' ? 'color-' + (healthStatus || 1) : '';

  return (
    <div
      className={classNames(['court-view-pos', healthClass, className])}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (onClick) onClick();
      }}
    >
      <div className="position-name">{position.position}</div>

      {position.mainPlayer || position.featuredPlayer ? (
        <>
          <div className="tooltip-symbol">
            <PlusIcon />
          </div>
        </>
      ) : null}
    </div>
  );
}

CourtViewPosition.defaultProps = {
  className: '',
};
