import moment from 'moment';

class MlbGame {
  constructor() {
    this.id = '';
    this._id = '';
    this.leagueType = 'mlb';
    this.mains = [
      new MlbGamePosition(null, 'main', 'm1'),
      new MlbGamePosition(null, 'main', 'm2'),
      new MlbGamePosition(null, 'main', 'm3'),
      new MlbGamePosition(null, 'main', 'm4'),
      new MlbGamePosition(null, 'main', 'm5'),
      new MlbGamePosition(null, 'main', 'm6'),
      new MlbGamePosition(null, 'main', 'm7'),
      new MlbGamePosition(null, 'main', 'm8'),
    ];
    this.reserves = [];
    this.starters = [];
    this.relievers = [];
    this.updatedAt = moment();
    this.team = null;
  }

  initGame(game) {
    this.id = game._id;
    this._id = game._id;
    this.team = Object.assign({}, game.homeTeamDetails[0] || game.homeTeamDetails, {
      teamIndex: game.homeTeam.teamIndex,
    });

    const positions = game.homeTeam.mlbPositions;

    for (let i in positions) {
      for (let j in this.mains) {
        if (positions[i] && this.mains[j].className === positions[i].slot) {
          this.mains[j].initPosition(positions[i]);
        }
      }
    }

    this.reserves = game.homeTeam.keyReservers ? game.homeTeam.keyReservers : [];
    this.starters = game.homeTeam.pitchingStaff ? game.homeTeam.pitchingStaff : [];
    this.relievers = game.homeTeam.keyRelievers ? game.homeTeam.keyRelievers : [];
    this.updatedAt = moment(game.updatedAt);
  }
}

class MlbGamePosition {
  constructor(payload = null, type = '', className = '') {
    this.id = Math.random();
    this.type = type;
    this.health = payload ? payload.healthStatus : '';
    this.position = payload ? payload.position : '';
    this.explanation = payload ? payload.text : '';
    this.className = className;
    this.mainPlayer = null;
    this.hasPlayerUpdate = false;
    this.backupPlayers = [{ id: Math.random() }];
  }

  initPosition(position) {
    const featuredPlayer = Array.isArray(position.featuredPlayer)
      ? position.featuredPlayer[0]
      : position.featuredPlayer;

    this.healthStatus = position.healthStatus;
    this.position = position.position;
    this.explanation = position.text;
    this.mainPlayer = featuredPlayer;
    this.hasPlayerUpdate = !!position.text;

    if (position.backupPlayers && position.backupPlayers.length) {
      this.backupPlayers = position.backupPlayers;
    }
  }
}

export { MlbGame, MlbGamePosition };
