import React from 'react';
import { SicLogoIcon } from '../helpers/icons';

const PlayerInsights = ({ insights }) => (
  <div className="player-insights">
    <div className="player-insights-header">
      <SicLogoIcon />
      <span>PLAYER INSIGHTS</span>
    </div>
    <div className="player-insights-body" dangerouslySetInnerHTML={{ __html: insights }}></div>
  </div>
);

export default PlayerInsights;
