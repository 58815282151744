import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import CustomLink from '../components/CustomLink';
import EditWidget from '../components/EditWidget';
import NativeSelect from '@material-ui/core/NativeSelect';
import GlobalContext from '../contexts/GlobalContext';
import { getClassForTeamScore, getNewHealth } from '../helpers/global';
import { FanDuelLogoIcon } from '../helpers/icons';
import { API_URL, APP_URL } from '../config.js';
import { getSeason } from '../helpers/session';
import { TeamAbbr } from '../components/TeamAbbr.js';

const CrossIcon = () => (
  <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9996 28.7496C28.4138 28.7496 28.7496 28.4138 28.7496 27.9996L28.7496 21.2496C28.7496 20.8354 28.4138 20.4996 27.9996 20.4996C27.5854 20.4996 27.2496 20.8354 27.2496 21.2496L27.2496 27.2496L21.2496 27.2496C20.8354 27.2496 20.4996 27.5854 20.4996 27.9996C20.4996 28.4138 20.8354 28.7496 21.2496 28.7496L27.9996 28.7496ZM8.56928 9.62994L27.4693 28.5299L28.5299 27.4693L9.62994 8.56928L8.56928 9.62994Z"
      fill="#828282"
    />
    <path
      d="M29.65 1.00039C29.65 0.586176 29.3142 0.25039 28.9 0.250389L22.15 0.250391C21.7358 0.25039 21.4 0.586177 21.4 1.00039C21.4 1.4146 21.7358 1.75039 22.15 1.75039L28.15 1.75039L28.15 7.75039C28.15 8.1646 28.4858 8.50039 28.9 8.50039C29.3142 8.50039 29.65 8.1646 29.65 7.75039L29.65 1.00039ZM10.5303 20.4307L29.4303 1.53072L28.3697 0.47006L9.46967 19.3701L10.5303 20.4307Z"
      fill="#C6C5C3"
    />
    <path
      d="M1.15039 28C1.15039 28.4142 1.48618 28.75 1.90039 28.75L8.65039 28.75C9.06461 28.75 9.40039 28.4142 9.4004 28C9.40039 27.5858 9.06461 27.25 8.65039 27.25L2.65039 27.25L2.65039 21.25C2.65039 20.8358 2.31461 20.5 1.90039 20.5C1.48618 20.5 1.15039 20.8358 1.15039 21.25L1.15039 28ZM28.3701 0.46967L1.37006 27.4697L2.43072 28.5303L29.4307 1.53033L28.3701 0.46967Z"
      fill="#C6C5C3"
    />
    <path
      d="M1 0.250001C0.585788 0.250001 0.250001 0.585788 0.250001 1L0.250002 7.75C0.250001 8.16421 0.585787 8.5 1 8.5C1.41422 8.5 1.75 8.16422 1.75 7.75L1.75 1.75L7.75 1.75C8.16422 1.75 8.5 1.41422 8.5 1C8.5 0.585787 8.16421 0.250001 7.75 0.250002L1 0.250001ZM28.5303 27.4697L1.53033 0.469671L0.469671 1.53033L27.4697 28.5303L28.5303 27.4697Z"
      fill="#828282"
    />
  </svg>
);

function ScoresScreen({
  hideCopy = false,
  hideSelectors = false,
  hideTeams = false,
  hidePlayers = false,
  hideSidebar = false,
  nolink = false,
}) {
  const context = useContext(GlobalContext);
  const { leagues, statuses } = context;
  const [weeks, setWeeks] = useState([]);
  const [games, setGames] = useState([]);
  const [currentLeague, setCurrentLeague] = useState('NFL');
  const [currentWeek, setCurrentWeek] = useState('');
  const [currentTab, setCurrentTab] = useState('qb');
  const [players, setPlayers] = useState([]);
  const [sortBy, setSortBy] = useState('points-desc');
  const currentSeason = getSeason();

  const sortedPlayers = [].concat(players);

  sortedPlayers.sort((a, b) => {
    if (sortBy === 'name-asc') {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    } else if (sortBy === 'name-desc') {
      return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
    } else if (sortBy === 'score-asc') {
      return parseFloat(a.health || 0) > parseFloat(b.health || 0) ? 1 : -1;
    } else if (sortBy === 'score-desc') {
      return parseFloat(a.sicScore) < parseFloat(b.sicScore) ? 1 : -1;
    } else if (sortBy === 'team-score-asc') {
      return parseFloat(a.team.score || 0) > parseFloat(b.team.score || 0) ? 1 : -1;
    } else if (sortBy === 'team-score-desc') {
      return parseFloat(a.team.score || 0) < parseFloat(b.team.score || 0) ? 1 : -1;
    } else if (sortBy === 'opp-score-asc') {
      return parseFloat(a.opposition.score || 0) > parseFloat(b.opposition.score || 0) ? 1 : -1;
    } else if (sortBy === 'opp-score-desc') {
      return parseFloat(a.opposition.score || 0) < parseFloat(b.opposition.score || 0) ? 1 : -1;
    } else if (sortBy === 'points-asc') {
      return parseFloat(a.fantasyPointsFanDuel || 0) > parseFloat(b.fantasyPointsFanDuel || 0) ? 1 : -1;
    } else if (sortBy === 'points-desc') {
      return parseFloat(a.fantasyPointsFanDuel || 0) < parseFloat(b.fantasyPointsFanDuel || 0) ? 1 : -1;
    } else if (sortBy === 'salary-asc') {
      return parseFloat(a.upcomingFanDuelSalary || 0) > parseFloat(b.upcomingFanDuelSalary || 0) ? 1 : -1;
    } else if (sortBy === 'salary-desc') {
      return parseFloat(a.upcomingFanDuelSalary || 0) < parseFloat(b.upcomingFanDuelSalary || 0) ? 1 : -1;
    }

    return 0;
  });

  useEffect(() => {
    setGames([]);

    axios.get(API_URL + '/scheduleTimeline/season/' + currentSeason).then(({ data }) => {
      const { scheduleTimelines } = data.data;
      const currentWeek = scheduleTimelines.find((week) => week.isCurrentWeek) || scheduleTimelines[0];

      setWeeks(scheduleTimelines);
      setCurrentWeek(currentWeek._id);
    });

    window.trackEvents('ViewContent', { title: 'Scores' });
  }, []);

  useEffect(() => {
    setGames([]);

    axios
      .post(API_URL + '/injuryIndex', {
        scheduleTimelineId: currentWeek,
        leagueType: currentLeague,
        currentSeason,
      })
      .then(({ data }) => {
        setGames(data.data.injuryIndexList[0].games);
      });
  }, [currentLeague, currentWeek]);

  useEffect(() => {
    setPlayers([]);

    const currentWeekObj = weeks.find((w) => w._id === currentWeek);
    if (currentWeekObj) {
      axios
        .get(API_URL + '/player/sicScores/' + currentWeekObj.name + '/' + currentTab.toUpperCase())
        .then(({ data }) => {
          setPlayers(data.data);
        });
    }
  }, [weeks, currentWeek, currentTab]);

  const currentWeekObj = weeks.find((w) => w._id === currentWeek);

  return (
    <div className="main-cols">
      <div className={hideSidebar ? 'full-col' : 'middle-col'}>
        {hideCopy ? null : (
          <>
            <div className="section-header justify-between mb-l">
              <div className="section-header-start">
                <h1 className="section-title">SIC MATCHUPS</h1>
              </div>
            </div>

            <div className="page-intro" id="scores-teams">
              <h3>Understanding the SIC Score</h3>
              <p>
                The SIC Score is our proprietary numerical injury grading system powered by algorithms that incorporates
                the health of every team and individual players to give sports bettors, DFS players and fantasy owners
                an edge each week during the season.
              </p>
              <p>
                While it purposely doesn't take into account coaching, schemes or matchups, the SIC Score gives an
                accurate assessment of team/player overall health heading into a game.
              </p>
            </div>
          </>
        )}

        {hideSelectors ? null : (
          <div className="multi-filters justify-start">
            <div className="week-selector">
              <div className="week-selector-label">League</div>
              <NativeSelect value={currentLeague} onChange={({ target }) => setCurrentLeague(target.value)}>
                {leagues
                  .filter(({ name }) => name === 'NFL')
                  .map(({ _id, name }) => (
                    <option key={_id} value={name}>
                      {name}
                    </option>
                  ))}
              </NativeSelect>
            </div>
            <div className="week-selector">
              <div className="week-selector-label">Week</div>
              <NativeSelect value={currentWeek} onChange={({ target }) => setCurrentWeek(target.value)}>
                {weeks.map((week) => (
                  <option key={week._id} value={week._id}>
                    {week.name}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
        )}

        {hideTeams ? null : (
          <div className="scores-table">
            <div className="scores-table-row scores-table-head">
              <div className="scores-table-subrow">
                <div className="row-id">
                  <div className="team-col">Teams</div>
                  <div className="scores-table-group text-cols">
                    <div>Spread</div>
                    <div>Money</div>
                    <div>O/U</div>
                  </div>
                </div>
                <div className="health-col">Team</div>
                <div className="scores-table-group health-cols">
                  <div>Offense</div>
                  <div>Defense</div>
                </div>
                <div className="scores-table-group health-cols">
                  <div>Off run</div>
                  <div>Def run</div>
                </div>
                <div className="scores-table-group health-cols">
                  <div>Off pass</div>
                  <div>Def pass</div>
                </div>
              </div>
            </div>
            {games.map((game) => (
              <CustomLink
                key={game._id}
                to={'/' + game.leagueType.toLowerCase() + '/games/' + game.scheduleYear + '/' + game.slug}
                className="scores-table-row"
              >
                <div className="scores-table-subrow">
                  <div className="row-id">
                    <div className="team-col">
                      <TeamAbbr team={game.visitingTeamDetails.abbreviation} />
                      {/* <figure>
                        
                        <img src={game.visitingTeamDetails.logoUrl} alt="Team logo" />
                      </figure>
                      <span>{game.visitingTeamDetails.abbreviation}</span> */}
                    </div>
                    <div className="scores-table-group text-cols">
                      <div>
                        {game.visitingTeam.points > 0 ? '+' : ''}
                        {game.visitingTeam.points}
                      </div>
                      <div>{game.visitingTeam.money}</div>
                      <div></div>
                    </div>
                  </div>
                  <div
                    className={
                      'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.teamIndex : '-')
                    }
                  >
                    {game.visitingTeam ? game.visitingTeam.teamIndex : '-'}
                  </div>
                  <div className="scores-table-group health-cols">
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.offenseIndex : '-')
                      }
                    >
                      {game.visitingTeam ? game.visitingTeam.offenseIndex : '-'}
                    </div>
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.defenseIndex : '-')
                      }
                    >
                      {game.visitingTeam ? game.visitingTeam.defenseIndex : '-'}
                    </div>
                  </div>
                  <div className="scores-table-group health-cols">
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.offenseRush : '-')
                      }
                    >
                      {game.visitingTeam ? game.visitingTeam.offenseRush : '-'}
                    </div>
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.defenseRush : '-')
                      }
                    >
                      {game.visitingTeam ? game.visitingTeam.defenseRush : '-'}
                    </div>
                  </div>
                  <div className="scores-table-group health-cols">
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.offensePass : '-')
                      }
                    >
                      {game.visitingTeam ? game.visitingTeam.offensePass : '-'}
                    </div>
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.visitingTeam ? game.visitingTeam.defensePass : '-')
                      }
                    >
                      {game.visitingTeam ? game.visitingTeam.defensePass : '-'}
                    </div>
                  </div>
                </div>

                <div className="scores-table-subrow">
                  <div className="row-id">
                    <div className="team-col">
                      {/* <figure>
                        <img src={game.homeTeamDetails.logoUrl} alt="Team logo" />
                      </figure>
                      <span>{game.homeTeamDetails.abbreviation}</span> */}
                      <TeamAbbr team={game.homeTeamDetails.abbreviation} />
                    </div>
                    <div className="scores-table-group text-cols">
                      <div>
                        {game.homeTeam.points > 0 ? '+' : ''}
                        {game.homeTeam.points}
                      </div>
                      <div>{game.homeTeam.money}</div>
                      <div>{game.OU}</div>
                    </div>
                  </div>
                  <div className={'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.teamIndex : '-')}>
                    {game.homeTeam ? game.homeTeam.teamIndex : '-'}
                  </div>
                  <div className="scores-table-group health-cols">
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.offenseIndex : '-')
                      }
                    >
                      {game.homeTeam ? game.homeTeam.offenseIndex : '-'}
                    </div>
                    <div
                      className={
                        'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.defenseIndex : '-')
                      }
                    >
                      {game.homeTeam ? game.homeTeam.defenseIndex : '-'}
                    </div>
                    <CrossIcon />
                  </div>
                  <div className="scores-table-group health-cols">
                    <div
                      className={'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.offenseRush : '-')}
                    >
                      {game.homeTeam ? game.homeTeam.offenseRush : '-'}
                    </div>
                    <div
                      className={'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.defenseRush : '-')}
                    >
                      {game.homeTeam ? game.homeTeam.defenseRush : '-'}
                    </div>
                    <CrossIcon />
                  </div>
                  <div className="scores-table-group health-cols">
                    <div
                      className={'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.offensePass : '-')}
                    >
                      {game.homeTeam ? game.homeTeam.offensePass : '-'}
                    </div>
                    <div
                      className={'health-cell ' + getClassForTeamScore(game.homeTeam ? game.homeTeam.defensePass : '-')}
                    >
                      {game.homeTeam ? game.homeTeam.defensePass : '-'}
                    </div>
                    <CrossIcon />
                  </div>
                </div>
              </CustomLink>
            ))}
          </div>
        )}

        {hidePlayers ? null : (
          <>
            <div className="ratings-title" id="scores-players">
              {currentLeague} {currentWeekObj ? currentWeekObj.name : ''} Players
              {players.length ? <span> ({players.length})</span> : null}
            </div>

            <div className="ratings-tabs">
              <div className={currentTab === 'qb' ? 'active' : ''} onClick={() => setCurrentTab('qb')}>
                Quarterbacks
              </div>
              <div className={currentTab === 'rb' ? 'active' : ''} onClick={() => setCurrentTab('rb')}>
                Running Backs
              </div>
              <div className={currentTab === 'wr' ? 'active' : ''} onClick={() => setCurrentTab('wr')}>
                Wide Receivers
              </div>
              <div className={currentTab === 'te' ? 'active' : ''} onClick={() => setCurrentTab('te')}>
                Tight Ends
              </div>
            </div>

            <div className="ratings-table">
              <div className="ratings-table-row ratings-table-head">
                <div
                  className={classNames({
                    'row-id': true,
                    sortable: true,
                    'sort-asc': sortBy === 'name-asc',
                    'sort-desc': sortBy === 'name-desc',
                  })}
                  onClick={() => setSortBy(sortBy === 'name-asc' ? 'name-desc' : 'name-asc')}
                >
                  Player
                </div>
                <div className="cell-logo">Team</div>
                <div
                  className={classNames({
                    'cell-score': true,
                    sortable: true,
                    'sort-asc': sortBy === 'score-asc',
                    'sort-desc': sortBy === 'score-desc',
                  })}
                  onClick={() => setSortBy(sortBy === 'score-asc' ? 'score-desc' : 'score-asc')}
                >
                  Player
                  <br />
                  SIC Score
                </div>
                <div
                  className={classNames({
                    'cell-score': true,
                    sortable: true,
                    'sort-asc': sortBy === 'team-score-asc',
                    'sort-desc': sortBy === 'team-score-desc',
                  })}
                  onClick={() => setSortBy(sortBy === 'team-score-asc' ? 'team-score-desc' : 'team-score-asc')}
                >
                  Player Pos.
                  <br />
                  Group
                </div>
                <div className="cell-logo">
                  UPCOMING
                  <br />
                  Opposition
                </div>
                <div
                  className={classNames({
                    'cell-score': true,
                    sortable: true,
                    'sort-asc': sortBy === 'opp-score-asc',
                    'sort-desc': sortBy === 'opp-score-desc',
                  })}
                  onClick={() => setSortBy(sortBy === 'opp-score-asc' ? 'opp-score-desc' : 'opp-score-asc')}
                >
                  OPP. POS.
                  <br />
                  GROUP
                </div>
                <div
                  className={classNames({
                    'cell-score': true,
                    sortable: true,
                    'sort-asc': sortBy === 'points-asc',
                    'sort-desc': sortBy === 'points-desc',
                  })}
                  onClick={() => setSortBy(sortBy === 'points-asc' ? 'points-desc' : 'points-asc')}
                >
                  <div>
                    <FanDuelLogoIcon />
                    <br />
                    Projected pts.
                  </div>
                </div>
                <div
                  className={classNames({
                    'cell-score': true,
                    sortable: true,
                    'sort-asc': sortBy === 'salary-asc',
                    'sort-desc': sortBy === 'salary-desc',
                  })}
                  onClick={() => setSortBy(sortBy === 'salary-asc' ? 'salary-desc' : 'salary-asc')}
                >
                  <div>
                    <FanDuelLogoIcon />
                    <br />
                    Salary
                  </div>
                </div>
              </div>
              {sortedPlayers.map((player) => (
                <a
                  key={player._id}
                  href={`${APP_URL}/${player.leagueType === '--' ? 'nfl' : player.leagueType.toLowerCase()}/players/${
                    player.slug
                  }`}
                  className="ratings-table-row"
                >
                  <div className="row-id">
                    <figure className="ratings-table-fig">
                      <img src={player.photoUrl} alt="Player face" width="35" />
                    </figure>
                    <span>{player.name}</span>
                  </div>
                  <div className="cell-logo">
                    {/* <img src={player.team.logo} alt="Team logo" width="28" /> */}
                    <TeamAbbr team={player.team.name} />
                  </div>
                  <div className="cell-score cell-gray">
                    <div
                      className={
                        'health-cell health-color-' +
                        getNewHealth(player.health, player.sicScore, player.status, statuses)
                      }
                    >
                      {player.sicScore === null ? '' : player.health}
                    </div>
                  </div>
                  <div className="cell-score">
                    <div>
                      {player.team.score ? (
                        <div className={'health-cell ' + getClassForTeamScore(player.team.score)}>
                          {player.team.score}
                        </div>
                      ) : (
                        '-'
                      )}
                      <div className="small-note">
                        {[
                          player.team.name,
                          player.team.side.toUpperCase().split(' ')[1],
                          player.team.side.toUpperCase().substring(0, 3),
                        ].join(' ')}
                      </div>
                    </div>
                  </div>
                  <div className="cell-logo cell-gray">
                    {/* <img src={player.opposition.logo} alt="Opposition logo" width="28" /> */}
                    <TeamAbbr team={player.opposition.name} />
                  </div>
                  <div className="cell-score">
                    <div>
                      {player.opposition.score ? (
                        <div className={'health-cell ' + getClassForTeamScore(player.opposition.score)}>
                          {player.opposition.score}
                        </div>
                      ) : (
                        '-'
                      )}
                      <div className="small-note">
                        {[
                          player.opposition.name,
                          player.opposition.side.toUpperCase().split(' ')[1],
                          player.opposition.side.toUpperCase().substring(0, 3),
                        ].join(' ')}
                      </div>
                    </div>
                  </div>
                  <div className="cell-score cell-gray">{player.fantasyPointsFanDuel}</div>
                  <div className="cell-score">{player.upcomingFanDuelSalary}</div>
                </a>
              ))}
            </div>
          </>
        )}
      </div>

      {hideSidebar ? null : (
        <div className="right-col">
          <EditWidget isSidebar={true} sidebarId="scores-sidebar" />
          <div className="sided-widget" clientId="46" placementId="71"></div>
        </div>
      )}
    </div>
  );
}

export default ScoresScreen;
