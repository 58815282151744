export function TeamAbbr({ team }) {
  return (
    <span
      style={{
        color: 'black',
        fontWeight: 'bold',
        fontSize: '1em',
        padding: '0.1em',
        border: '1px solid black',
        borderRadius: '8px',
        minWidth: '1em',
        width: '100%',
      }}
    >
      {team}
    </span>
  );
}
