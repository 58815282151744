import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (<Box my={4}><Grid container spacing={2}>
        <Grid item xs={12} sm={6}><Link to="/premium">
            <img src="/billy-walters-banner.jpg" alt="billy-walters-banner" />
        </Link></Grid>
        <Grid item xs={12} sm={6}><Link to="/checkout?plan=picks">
            <img src="/sic-picks-banner.jpg" alt="sic-picks-banner" /></Link></Grid>
    </Grid>
    </Box>)
}

export default Banner;