import moment from 'moment';
import { SUPPORTED_LEAGUES } from '../config.js';

export function getEmailValidationRegex() {
  return /\S+@\S+\.\S+/;
}

export function getTeamShortName(name) {
  if (typeof name === 'undefined') return '';

  const pieces = name.split(' ');

  return pieces[pieces.length - 1];
}

export function getEmbedCode(id) {
  return (
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/' +
    id +
    '?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  );
}

export function prepareEmbeds(body) {
  body = body.replace(/<a /g, '<a target="_blank" ');
  body = body.replaceAll('<iframe', '<iframe-disabled');
  body = body.replaceAll('</iframe>', '</iframe-disabled>');

  return body
    .replace('<oembed url', '<iframe src')
    .replace('</oembed>', '</iframe>')
    .replace('<oembed url', '<iframe src')
    .replace('</oembed>', '</iframe>')
    .replace('<oembed url', '<iframe src')
    .replace('</oembed>', '</iframe>')
    .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
    .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
    .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
    .replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
    .replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')
    .replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
}

export function getTimeAgo(date) {
  const minutes = moment().diff(moment(date), 'minutes');
  const hours = moment().diff(moment(date), 'hours');
  const days = moment().diff(moment(date), 'days');

  let str = '';

  if (minutes < 2) {
    str = '1 minute';
  } else if (minutes < 60) {
    str = minutes + ' minutes';
  } else if (hours < 2) {
    str = '1 hour';
  } else if (hours < 24) {
    str = hours + ' hours';
  } else if (days < 2) {
    str = '1 day';
  } else {
    str = days + ' days';
  }

  str = str + ' ago';

  return str;
}

export function parseGameSchedule(datetime) {
  if (datetime) {
    let d = datetime.replace('.000Z', '');

    if (d.indexOf('.') !== -1) {
      d = d.split('.')[0];
    }

    return moment(d).tz('America/New_York', true).tz(moment.tz.guess());
  }

  return moment();
}

export function getClassForTeamScore(score) {
  if (isNaN(score) || score === null) {
    return '';
  }

  const prefix = ' range2-';

  if (score > 84.9) {
    return prefix + 'green';
  } else if (score > 69.9) {
    return prefix + 'orange';
  }

  return prefix + 'red';
}

export function getClassForPlayerScore(score, obj = null, statuses = null) {
  if (isNaN(score) || score === null) {
    return '';
  }

  const prefix = ' range2-';

  if (obj && statuses && obj.sicScore < 1) {
    const matchingStatus = statuses.find(({ status }) => status.toLowerCase() === obj.status.toLowerCase());

    if (matchingStatus) {
      return prefix + matchingStatus.color;
    }
  }

  if (score > 84.9) {
    return prefix + 'green';
  } else if (score > 49.9) {
    return prefix + 'orange';
  }

  return prefix + 'red';
}

export function getNewHealth(health, sicScore = null, status = null, statuses = []) {
  if (sicScore === null && status !== null) {
    const statusObject = statuses.find((s) => s.status.toLowerCase() === status.toLowerCase());

    if (statusObject && statusObject.color) {
      return statusObject.color === 'green' ? 1 : statusObject.color === 'orange' ? 2 : 3;
    }
  }

  if (health > 84.9) {
    return 1;
  } else if (health > 49.9) {
    return 2;
  }

  return 3;
}

export function getMainLeagues() {
  return SUPPORTED_LEAGUES.map((lg) => lg.replace('/', ''));
}

export function getArticleParts(body) {
  const parts = [];
  const startWildcard = '<div class="raw-html-embed"><iframe';
  const endWildcard = '</iframe></div>';
  const individualParamsWildcard = '/embed-widget/';
  const gameParamsWildcard = '/game-embed/';
  const otherParamsWildcard = '/embed';

  let iframePosition = body.indexOf(startWildcard);

  while (iframePosition !== -1) {
    parts.push({
      type: 'raw',
      body: body.substring(0, iframePosition),
    });

    const endPosition = body.indexOf(endWildcard);
    const tag = body.substring(iframePosition, endPosition);
    let payload = {};
    let tagIdentified = false;
    let paramsString = [];

    if (tag.indexOf(individualParamsWildcard) !== -1) {
      tagIdentified = true;
      paramsString = tag.substring(tag.indexOf(individualParamsWildcard) + individualParamsWildcard.length);
      paramsString = paramsString.substring(0, paramsString.indexOf('?')).split('/');

      payload = {
        type: 'embed',
        widgetId: paramsString[0],
      };
    } else if (tag.indexOf(gameParamsWildcard) !== -1) {
      tagIdentified = true;
      paramsString = tag.substring(tag.indexOf(gameParamsWildcard) + gameParamsWildcard.length);
      paramsString = paramsString.substring(0, paramsString.indexOf('"')).split('?domain=');

      payload = {
        type: 'global-embed-game',
        gameId: paramsString[0],
        domain: paramsString[1],
      };
    } else if (tag.indexOf('youtube.com') !== -1) {
      parts.push({
        type: 'raw',
        body: tag + endWildcard,
      });
    } else if (tag.indexOf('docs.google.com') !== -1) {
      parts.push({
        type: 'raw',
        body: tag + endWildcard,
      });
    } else if (tag.indexOf(otherParamsWildcard) !== -1) {
      // the articles embed is still served as iframe for now to avoid recurrency issues
      parts.push({
        type: 'raw',
        body: tag + endWildcard,
      });
    }

    let width = tag
      .substring((tag.indexOf('width: ') !== -1 ? tag.indexOf('width: ') : tag.indexOf('width="')) + 7)
      .trim();
    width = width.substring(0, width.indexOf('px;') !== -1 ? width.indexOf('px;') : width.indexOf('"')).trim();

    let height = tag
      .substring((tag.indexOf('height: ') !== -1 ? tag.indexOf('height: ') : tag.indexOf('height="')) + 8)
      .trim();
    height = height.substring(0, height.indexOf('px;') !== -1 ? height.indexOf('px;') : height.indexOf('"')).trim();

    if (tagIdentified) {
      parts.push({
        tag,
        width,
        height,
        ...payload,
      });
    }

    body = body.substring(endPosition + endWildcard.length);

    iframePosition = body.indexOf(startWildcard);
  }

  parts.push({
    type: 'raw',
    body: body,
  });

  return parts;
}
