import React, { useState, useContext } from 'react';
import InfoPoint from '../InfoPoint';
import CustomLink from '../CustomLink';
import GlobalContext from '../../contexts/GlobalContext';
import { getClassForTeamScore, parseGameSchedule } from '../../helpers/global';
import { ArrowRightIcon, CloseIcon, LockIcon } from '../../helpers/icons';
import { SUPPORTED_LEAGUES } from '../../config';

const slideTo = (to) => {
  const slider = document.querySelector('.sport-selector-slider');
  const currentSlide = slider.getAttribute('data-slide');
  const totalSlides = slider.querySelectorAll('.sport-selector-slider .score-item').length;
  const firstArticle = slider.querySelector('.sport-selector-slider .score-item:first-child');
  const newSlide = to === '-1' ? currentSlide - 1 : to === '+1' ? currentSlide * 1 + 1 : to;

  if (newSlide > totalSlides - (window.innerWidth < 768 ? 1 : 2) || newSlide < 1) {
    return;
  }

  slider.setAttribute('data-slide', newSlide);

  firstArticle.style.marginLeft = (window.innerWidth < 700 ? -50 : -20) * (newSlide - 1) + '%';
};

export default function HealthScoreWidget() {
  const context = useContext(GlobalContext);
  const [selectorState, setSelectorState] = useState('closed');
  let [selectedLeague, setSelectedLeague] = useState('all');
  const { widgetsContent } = context;
  const widgetKey = 'game-feed-compact';

  if (SUPPORTED_LEAGUES.indexOf(window.location.pathname) !== -1) {
    selectedLeague = window.location.pathname.replace('/', '');
  }

  const articles = widgetsContent[widgetKey].filter(
    ({ leagueType }) =>
      !selectedLeague || selectedLeague === 'all' || leagueType.toLowerCase() === selectedLeague.toLowerCase()
  );

  const isAll = window.location.pathname === '/';

  if (!articles.length && !isAll) {
    return null;
  }

  return (
    <>
      <section className="health-score">
        <div className="health-score-start">
          <div className="health-score-headline">
            <div className="health-score-title">SIC MATCHUPS</div>
            <div className="health-score-subtitle">
              <a href="https://youtu.be/V9yMnEBgytM" target="_blank" rel="noopener noreferrer">
                Learn how
              </a>{' '}
              to use the SIC Score <br />
              to gain an advantage and win!
            </div>
          </div>
          <InfoPoint className="width-m">
            SIC Score is our proprietary numerical injury grading system that incorporates the health of team and
            individual players to give sports bettors, DFS players and fantasy owners an edge each week during the
            season. While it purposely doesn't take into account coaching, schemes or matchups, SIC Scores give an
            accurate assessment of team/player overall health heading into a game. -- For individual players a SIC Score
            of 100 means no current or past significant health issues or risks. A score of 0 means that he won't play.
            Numbers in between are a relative grade related to their health and performance expectations this week.
          </InfoPoint>
        </div>

        <div className="score-list">
          <div className="sport-selector">
            <div className="sport-selector-label">SELECT SPORT:</div>
            <div
              className="sport-selector-value"
              onClick={() => {
                if (isAll) {
                  setSelectorState('opened');
                }
              }}
            >
              <ArrowRightIcon />
              {selectedLeague === 'all' ? 'ALL' : selectedLeague.toUpperCase()}
            </div>

            {selectorState === 'opened' && isAll ? (
              <div className="selector-modal">
                <div className="selector-modal-headline">
                  <div className="selector-modal-label">Select sport:</div>
                  <span className="selector-modal-close" onClick={() => setSelectorState('closed')}>
                    <CloseIcon />
                  </span>
                </div>
                <ul className="selector-modal-options">
                  <li
                    className={selectedLeague === 'all' ? 'active' : ''}
                    onClick={() => {
                      setSelectorState('closed');
                      setSelectedLeague('all');
                    }}
                  >
                    ALL
                  </li>
                  <li
                    className={selectedLeague === 'nfl' ? 'active' : ''}
                    onClick={() => {
                      setSelectorState('closed');
                      setSelectedLeague('nfl');
                    }}
                  >
                    NFL
                  </li>
                  <li
                    className={selectedLeague === 'nba' ? 'active' : ''}
                    onClick={() => {
                      setSelectorState('closed');
                      setSelectedLeague('nba');
                    }}
                  >
                    NBA
                  </li>
                  <li
                    className={selectedLeague === 'mlb' ? 'active' : ''}
                    onClick={() => {
                      setSelectorState('closed');
                      setSelectedLeague('mlb');
                    }}
                  >
                    MLB
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          <div className="sport-selector-wrapper">
            <div className="widget-scroller prev" onClick={() => slideTo('-1')}>
              <ArrowRightIcon />
            </div>
            <div className="sport-selector-slider" data-slide="1">
              {articles.map((article, idx) => (
                <CustomLink
                  to={
                    article.leagueType === 'MLB'
                      ? `/${article.leagueType.toLowerCase()}/teams/${article.homeTeamDetails.slug}`
                      : `/${article.leagueType.toLowerCase()}/games/${article.scheduleYear}/${article.slug}`
                  }
                  key={article._id}
                  className="score-item"
                >
                  {article.gameSchedule ? (
                    <div className="score-time">{parseGameSchedule(article.gameSchedule).format('M/D - h:mm A z')}</div>
                  ) : null}
                  {article.visitingTeamDetails && article.leagueType.toLowerCase() !== 'nba' ? (
                    <div className="score-team">
                      {/* <figure>
                        <img src={article.visitingTeamDetails.logoUrl} alt="Team logo" />
                      </figure> */}
                      <span>{article.visitingTeamDetails.abbreviation}</span>
                      <em className={getClassForTeamScore(article.visitingTeam.teamIndex) + '-text'}>
                        {/* top score */}
                        {idx < 2 ? article.visitingTeam.teamIndex : <LockIcon />}
                      </em>
                    </div>
                  ) : null}
                  <div className="score-team">
                    {/* <figure>
                      <img
                        src={
                          (article.homeTeamDetails[0] ? article.homeTeamDetails[0] : article.homeTeamDetails).logoUrl
                        }
                        alt="Team logo"
                      />
                    </figure> */}
                    <span>
                      {(article.homeTeamDetails[0] ? article.homeTeamDetails[0] : article.homeTeamDetails).abbreviation}
                    </span>
                    <em className={getClassForTeamScore(article.homeTeam.teamIndex) + '-text'}>
                      {/* bottom score */}
                      {idx < 2 ? article.homeTeam.teamIndex : <LockIcon />}
                    </em>
                  </div>
                  {article.visitingTeamDetails && article.leagueType.toLowerCase() === 'nba' ? (
                    <div className="score-team">
                      {/* <figure>
                        <img src={article.visitingTeamDetails.logoUrl} alt="Team logo" />
                      </figure> */}
                      <span>{article.visitingTeamDetails.abbreviation}</span>
                      <em className={getClassForTeamScore(article.visitingTeam.teamIndex) + '-text'}>
                        {article.visitingTeam.teamIndex}
                      </em>
                    </div>
                  ) : null}
                </CustomLink>
              ))}
            </div>
            <div className="widget-scroller next" onClick={() => slideTo('+1')}>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
