import {
  BarChartIcon,
  DollarIcon,
  FullScreenIcon,
  LineChartIcon,
  NewspaperIcon,
  SMSIcon,
  WifiIcon,
} from '../../helpers/icons';

const featureData = [
  {
    icon: <NewspaperIcon />,
    title: 'Subscriber Only Insights, Articles and Videos',
    checks: {
      free: true,
      data: true,
      picks: true,
    },
  },
  {
    icon: <WifiIcon />,
    title: 'Weekly Newsletter and Podcast',
    checks: {
      free: true,
      data: true,
      picks: true,
    },
  },
  {
    icon: <SMSIcon />,
    title: 'SMS and Email Player Alerts',
    checks: {
      free: true,
      data: true,
      picks: true,
    },
  },
  {
    icon: <LineChartIcon />,
    title: 'Fantasy Team and Player Health Tracking',
    checks: {
      free: true,
      data: true,
      picks: true,
    },
  },
  {
    icon: <BarChartIcon />,
    title: 'The Injury Edge - Your NFL Matchup Cheat Sheet',
    checks: {
      free: false,
      data: true,
      picks: true,
    },
  },
  {
    icon: <FullScreenIcon />,
    title: 'NFL Field View previews for every NFL game and selected NBA games',
    checks: {
      free: false,
      data: true,
      picks: true,
    },
  },
  {
    icon: <DollarIcon />,
    title: 'Recommended Injury-based bets on NFL, NBA, MLB inc all bet types, spreads winning at over 58%',
    checks: {
      free: false,
      premium: false,
      picks: true,
    },
  },
];

export default featureData;
