import React, { Component } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { Avatar } from '../helpers/icons';
import GlobalContext from '../contexts/GlobalContext';
import { getNewHealth } from '../helpers/global';
import { API_URL } from '../config.js';

class GlobalSearchForm extends Component {
  constructor() {
    super();

    this.state = {
      search: '',
      isSearchActive: false,
      entries: [],
    };
  }

  onSearch(query) {
    if (query.trim().length) {
      axios.get(API_URL + '/search?search=' + query).then(({ data }) => this.setState({ entries: data.data || [] }));
    } else {
      this.setState({ entries: [] });
    }
  }

  onSearchDebounced = debounce((query) => this.onSearch(query), 500);

  render() {
    const { onFocus, onBlur, onSelect } = this.props;
    const { search, isSearchActive, entries } = this.state;
    const { statuses } = this.context;
    const teams = entries.filter(({ type }) => type === 'team');
    const games = entries.filter(({ type }) => type === 'game');
    const players = entries.filter(({ type }) => type === 'player');
    const articles = entries.filter(({ type }) => type === 'post');

    return (
      <>
        <Icon className="icon-trigger" onClick={() => this.setState({ isSearchActive: true })}>
          search
        </Icon>
        {
          <div className={'search-player-form' + (isSearchActive ? ' active' : '')}>
            <div className="search-box">
              <input
                placeholder="Search for a team or player"
                type="text"
                name="search"
                className={search.trim().length > 0 ? 'has-text' : 'is-empty'}
                autoComplete="off"
                value={search}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={({ target }) => {
                  this.setState({ search: target.value });
                  this.onSearchDebounced(target.value);
                }}
              />
              <Icon
                className="icon-close"
                onClick={() => this.setState({ isSearchActive: false, search: '', entries: [] })}
              >
                close
              </Icon>
              <Icon className="icon-decorator">search</Icon>
              {entries.length ? (
                <>
                  <div className="suggestions">
                    <div className="suggestions-piece">Players</div>
                    {players.map((entry) => {
                      const leagueType = entry.leagueType.toLowerCase();

                      return (
                        <Link
                          className="player"
                          key={entry._id}
                          to={'/' + leagueType + '/players/' + entry.slug}
                          onClick={() => {
                            this.setState({ search: '', entries: [], isSearchActive: false });
                            onSelect();

                            if (entry.type === 'player' && window.setPlayerObject) {
                              // window.setPlayerObject()
                              setTimeout(() => window.location.reload());
                            }
                          }}
                        >
                          <div>
                            {entry.image ? <img src={entry.image} alt="Entry pic" /> : <Avatar />}
                            <span className="name">{entry.name}</span>
                          </div>
                          <span
                            className={
                              'health-sign health-' + getNewHealth(entry.health, entry.sicScore, entry.status, statuses)
                            }
                          >
                            {entry.sicScore === null ? '' : entry.health}
                          </span>
                        </Link>
                      );
                    })}
                    {players.length ? null : <div className="suggestions-entry">-</div>}
                    <div className="suggestions-piece">Teams</div>
                    {teams.map((entry) => {
                      const leagueType = entry.leagueType.toLowerCase();

                      return (
                        <Link
                          className="player"
                          key={entry._id}
                          to={'/' + leagueType + '/teams/' + entry.slug}
                          onClick={() => {
                            this.setState({ search: '', entries: [], isSearchActive: false });
                            onSelect();
                            setTimeout(() => window.location.reload());
                          }}
                        >
                          <div>
                            {entry.image ? <img src={entry.image} alt="Entry pic" /> : <Avatar />}
                            <span className="name">{entry.name}</span>
                          </div>
                          {entry.health ? (
                            <span className={'health health-' + getNewHealth(entry.health)}>{entry.health}</span>
                          ) : null}
                        </Link>
                      );
                    })}
                    {teams.length ? null : <div className="suggestions-entry">-</div>}
                    <div className="suggestions-piece">Games</div>
                    {games.map((entry) => {
                      const leagueType = entry.leagueType.toLowerCase();

                      return (
                        <Link
                          className="suggestions-entry"
                          key={entry._id}
                          to={'/' + leagueType + '/games/' + entry.season + '/' + entry.slug}
                          onClick={() => {
                            this.setState({ search: '', entries: [], isSearchActive: false });
                            onSelect();
                            setTimeout(() => window.location.reload());
                          }}
                        >
                          <span className="name">
                            {entry.name} ({entry.season})
                          </span>
                        </Link>
                      );
                    })}
                    {games.length ? null : <div className="suggestions-entry">-</div>}
                    <div className="suggestions-piece">Articles</div>
                    {articles.map((entry) => {
                      const leagueType = entry.leagueType.toLowerCase();

                      return (
                        <Link
                          className="suggestions-entry"
                          key={entry._id}
                          to={'/' + leagueType + '/updates/' + entry.slug}
                          onClick={() => {
                            this.setState({ search: '', entries: [], isSearchActive: false });
                            onSelect();
                            setTimeout(() => window.location.reload());
                          }}
                        >
                          {entry.name}
                        </Link>
                      );
                    })}
                    {articles.length ? null : <div className="suggestions-entry">-</div>}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        }
      </>
    );
  }
}

GlobalSearchForm.contextType = GlobalContext;
GlobalSearchForm.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  onSelect: () => {},
};

export default GlobalSearchForm;
