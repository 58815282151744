import React, { useContext, useState } from 'react';
import GameBox from '../GameBox';
import CourtView from '../CourtView';
import DiamondView from '../DiamondView';
import EditWidget from '../EditWidget';
import LeagueSwitcher from '../LeagueSwitcher';
import CustomLink from '../CustomLink';
import { NbaGame } from '../../helpers/nba';
import { MlbGame } from '../../helpers/mlb';
import { isAdmin } from '../../helpers/session';
import { getTimeAgo, getTeamShortName, parseGameSchedule } from '../../helpers/global';
import GlobalContext from '../../contexts/GlobalContext';
import { SUPPORTED_LEAGUES } from '../../config';
import { TeamAbbr } from '../TeamAbbr';

export default function GameFeedWidget() {
  const context = useContext(GlobalContext);
  const { allLeagues, widgets, widgetsContent } = context;
  const [currentLeague, setCurrentLeague] = useState(null);
  const widgetKey = 'game-feed';

  if (!widgets[widgetKey]) {
    return null;
  }

  const chosenLeague = widgets[widgetKey].find(({ key }) => key === 'leagueType').value;
  const leagues = chosenLeague === 'all' ? allLeagues : [allLeagues.find(({ name }) => name === chosenLeague)];
  let selectedLeague = currentLeague ? currentLeague : leagues[0];
  if (leagues.length > 1 && SUPPORTED_LEAGUES.indexOf(window.location.pathname) !== -1) {
    selectedLeague = leagues.find(({ name }) => name === window.location.pathname.replace('/', '').toUpperCase());
  }

  const games = widgetsContent[widgetKey].filter(
    ({ leagueType }) => !selectedLeague || selectedLeague._id === 'all' || leagueType === selectedLeague.name
  );

  if (!games.length && !isAdmin()) {
    return null;
  }

  return (
    <div className="game-feed">
      <div className="section-header justify-between">
        <div className="section-header-start">
          <h2 className="section-title">Game View</h2>
          <LeagueSwitcher
            leagues={leagues}
            currentLeague={selectedLeague}
            onChangeLeague={(league) => setCurrentLeague(allLeagues.find(({ _id }) => _id === league))}
          />
        </div>
        <EditWidget type={widgetKey} />
      </div>
      <div className="section-content">
        <div className="team-charts">
          {games.map((game) => {
            const nbaGame = new NbaGame();
            const mlbGame = new MlbGame();
            const isNba = game.leagueType === 'NBA';
            const isMlb = game.leagueType === 'MLB';

            if (isNba) {
              if (!game.visitingTeam) {
                return null;
              }

              nbaGame.initGame(game);
            }

            if (isMlb) {
              mlbGame.initGame(game);
            }

            return (
              <div className="game-box-wrapper" key={game._id}>
                <div className="game-box-go">
                  Click <CustomLink to={'/' + game.leagueType.toLowerCase() + '/games'}>here</CustomLink> to see this
                  week's field views
                </div>
                <CustomLink
                  to={`/${game.leagueType.toLowerCase()}/games/${game.scheduleYear}/${
                    isMlb ? game.homeTeamDetails.slug : game.slug
                  }`}
                >
                  <div className="game-top">
                    <div>
                      <div className="game-opponents">
                        {isMlb ? (
                          mlbGame.team.name
                        ) : (
                          <>
                            {getTeamShortName(game.visitingTeamDetails.name)} VS{' '}
                            {getTeamShortName(game.homeTeamDetails.name)} Game Preview
                          </>
                        )}
                      </div>
                      <div className="game-post-title">{getTimeAgo(game.updatedAt)}</div>
                      {isMlb ? null : (
                        <div className="game-dates">
                          Game Date: {parseGameSchedule(game.gameSchedule).format('M/D/YY')}
                          &nbsp; | &nbsp; Game Time: {parseGameSchedule(game.gameSchedule).format('h:mm A z')}
                        </div>
                      )}
                    </div>
                    <div className="game-logos">
                      {isMlb ? null : (
                        <>
                          <figure className="item-logo transparent">
                            {/* <img src={game.visitingTeamDetails.logoUrl} alt="Team logo" /> */}
                            <TeamAbbr team={game.visitingTeamDetails.abbreviation} />
                          </figure>
                          <span>vs.</span>
                          <figure className="item-logo transparent">
                            {/* <img src={game.homeTeamDetails.logoUrl} alt="Team logo" /> */}
                            <TeamAbbr team={game.homeTeamDetails.abbreviation} />
                          </figure>
                        </>
                      )}
                    </div>
                  </div>
                </CustomLink>
                <div className="main-games-holder">
                  {isNba ? <CourtView game={nbaGame} /> : null}
                  {game.leagueType === 'NFL' ? <GameBox game={game} /> : null}
                  {isMlb ? <DiamondView gameData={mlbGame} isWidget={true} /> : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
