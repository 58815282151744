export const APP_VERSION = '15.0.1';

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.sicscore.com';
export const APP_URL = process.env.REACT_APP_APP_URL || 'https://sicscore.com';

export const MIN_PASSWORD_LENGTH = 6;
export const DATE_TIME_FORMAT = 'M/D/YY @ h:mma';

export const SESSION_KEY = 'pfd_auth_token';
export const SESSION_REFRESH_KEY = 'pfd_refresh_token';
export const SESSION_TYPE_KEY = 'pfd_auth_type_token';

export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;

export const RECAPTCHA_KEY = '6LeKNr8UAAAAALr5ZCMKZukxpEMM786yPLfgOWUq';

export const GOOGLE_LOGIN_KEY = '566074609540-a0d53tr6qlmhn4uh426nih8jfqh97v9e.apps.googleusercontent.com';

export const CONTACT_EMAIL = 'support@sicscore.com';

export const SUPPORTED_LEAGUES = ['/nfl', '/nba', '/mlb', '/cfb'];
