import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import EditWidget from '../EditWidget';
import LeagueSwitcher from '../LeagueSwitcher';
import SicImage from '../SicImage';
import { getTimeAgo } from '../../helpers/global';
import { isAdmin } from '../../helpers/session';
import GlobalContext from '../../contexts/GlobalContext';
import { API_URL, SUPPORTED_LEAGUES } from '../../config';

export default function VideosFeedWidget({ externalData = null, noEditButton = false }) {
  const context = useContext(GlobalContext);
  const { allLeagues, widgets, widgetsContent } = context;
  const [currentLeague, setCurrentLeague] = useState(null);
  const [subsequentArticles, setSubsequentArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMore, setIsMore] = useState(true);
  const widgetKey = 'videos-feed';
  const pathLeague = window.location.pathname;
  const pathLeagueStripped = pathLeague.replace('/', '');

  useEffect(() => {
    if (currentPage > 1) {
      axios
        .get(`${API_URL}/widget/loadMore?page=${pathLeagueStripped || 'home'}&type=video&pageNumber=${currentPage}`)
        .then(({ data }) => {
          const newArticles = data.data;

          setSubsequentArticles(subsequentArticles.concat(newArticles));

          if (!newArticles.length) {
            setIsMore(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pathLeagueStripped]);

  if (!widgets[widgetKey] && !externalData) {
    return null;
  }

  const chosenLeague = widgets[widgetKey].find(({ key }) => key === 'leagueType').value;
  const leagues = chosenLeague === 'all' ? allLeagues : [allLeagues.find(({ name }) => name === chosenLeague)];
  let selectedLeague = currentLeague ? currentLeague : leagues[0];
  if (leagues.length > 1 && SUPPORTED_LEAGUES.indexOf(pathLeague) !== -1) {
    selectedLeague = leagues.find(({ name }) => name === pathLeagueStripped.toUpperCase());
  }

  const articles =
    externalData ||
    widgetsContent[widgetKey].filter(
      ({ leagueType }) => !selectedLeague || selectedLeague._id === 'all' || leagueType === selectedLeague.name
    );

  if (!articles.length && !isAdmin()) {
    return null;
  }

  return (
    <div className="videos-feed">
      <div className="section-header justify-between">
        <div className="section-header-start">
          <h2 className="section-title">VIDEOS</h2>
          <LeagueSwitcher
            leagues={leagues}
            currentLeague={selectedLeague}
            onChangeLeague={(league) => setCurrentLeague(allLeagues.find(({ _id }) => _id === league))}
          />
        </div>
        {noEditButton ? null : (
          <div className="section-header justify-end">
            <EditWidget type={widgetKey} />
          </div>
        )}
      </div>
      <div className="section-content">
        {articles.concat(subsequentArticles).map((video, index) => (
          <Link
            to={'/' + video.leagueType.toLowerCase() + '/videos?id=' + video._id}
            className="latest-news-article stacked"
            key={`${index}_${video._id}`}
            onClick={() => {
              window.scrollTo(0, 0);
              context.loadLatestNews(video.leagueType.toLowerCase());
            }}
          >
            <figure>
              <SicImage src={video.thumbnails.default.url} data-src={video.thumbnails.high.url} alt="Cover" />
            </figure>
            <div className="update-title mb0">{video.title}</div>
            {/*
          <div className="post-attachments">
          	<div className="health-dot"></div>
          	<span>Zach Allen</span>, <span>Atlanta Falcons</span>
          </div>
          <div className="post-tag maroon">Breaking News</div>
          <div className="post-tag">{video.leagueType}</div>
          */}
            <div className="post-date block">{getTimeAgo(video.videoDateTime)}</div>
          </Link>
        ))}

        {isMore ? (
          <button className="primary-button bordered load-more" onClick={() => setCurrentPage(currentPage + 1)}>
            + View More
          </button>
        ) : null}
      </div>
    </div>
  );
}
