import moment from 'moment';

class NbaGame {
  constructor() {
    this.id = '';
    this._id = '';
    this.slug = '';
    this.season = 2022;
    this.leagueType = '';

    this.homeTeamId = '';
    this.homeTeamDetails = {};
    this.homeTeamPoints = '';
    this.homeTeamMoney = '';
    this.homeTeamOU = '';
    this.homeTeamTeam = '';
    this.homeTeamPositions = {
      p1: new NbaGamePosition(),
      p2: new NbaGamePosition(),
      p3: new NbaGamePosition(),
      p4: new NbaGamePosition(),
      p5: new NbaGamePosition(),
      sub1: new NbaGamePosition(),
      sub2: new NbaGamePosition(),
      sub3: new NbaGamePosition(),
      sub4: new NbaGamePosition(),
      sub5: new NbaGamePosition(),
    };

    this.awayTeamId = '';
    this.awayTeamPoints = '';
    this.visitingTeamDetails = {};
    this.awayTeamMoney = '';
    this.awayTeamOU = '';
    this.awayTeamTeam = '';
    this.awayTeamPositions = {
      p1: new NbaGamePosition(),
      p2: new NbaGamePosition(),
      p3: new NbaGamePosition(),
      p4: new NbaGamePosition(),
      p5: new NbaGamePosition(),
      sub1: new NbaGamePosition(),
      sub2: new NbaGamePosition(),
      sub3: new NbaGamePosition(),
      sub4: new NbaGamePosition(),
      sub5: new NbaGamePosition(),
    };

    this.gameDate = moment();
    this.gameSchedule = '';
    this.updatedAt = moment();
  }

  initGame(game) {
    this.id = game._id;
    this._id = game._id;
    this.slug = game.slug;
    this.season = game.scheduleYear;
    this.leagueType = game.leagueType;

    this.homeTeamId = game.homeTeam.teamId;
    this.homeTeamDetails = game.homeTeamDetails;
    this.homeTeamOU = game.OU;
    this.homeTeamPoints = game.homeTeam.points;
    this.homeTeamMoney = game.homeTeam.money;
    this.homeTeamTeam = game.homeTeam.teamIndex;
    this.homeTeamPositions = {
      p1: new NbaGamePosition(game.homeTeam.teamActive.find(({ slot }) => slot === '1,1')),
      p2: new NbaGamePosition(game.homeTeam.teamActive.find(({ slot }) => slot === '1,2')),
      p3: new NbaGamePosition(game.homeTeam.teamActive.find(({ slot }) => slot === '1,3')),
      p4: new NbaGamePosition(game.homeTeam.teamActive.find(({ slot }) => slot === '1,4')),
      p5: new NbaGamePosition(game.homeTeam.teamActive.find(({ slot }) => slot === '1,5')),
      sub1: new NbaGamePosition(game.homeTeam.teamOnBench.find(({ slot }) => slot === '1,1')),
      sub2: new NbaGamePosition(game.homeTeam.teamOnBench.find(({ slot }) => slot === '1,2')),
      sub3: new NbaGamePosition(game.homeTeam.teamOnBench.find(({ slot }) => slot === '1,3')),
      sub4: new NbaGamePosition(game.homeTeam.teamOnBench.find(({ slot }) => slot === '1,4')),
      sub5: new NbaGamePosition(game.homeTeam.teamOnBench.find(({ slot }) => slot === '1,5')),
    };

    this.awayTeamId = game.visitingTeam.teamId;
    this.visitingTeamDetails = game.visitingTeamDetails;
    this.awayTeamPoints = game.visitingTeam.points;
    this.awayTeamMoney = game.visitingTeam.money;
    this.awayTeamTeam = game.visitingTeam.teamIndex;
    this.awayTeamPositions = {
      p1: new NbaGamePosition(game.visitingTeam.teamActive.find(({ slot }) => slot === '1,1')),
      p2: new NbaGamePosition(game.visitingTeam.teamActive.find(({ slot }) => slot === '1,2')),
      p3: new NbaGamePosition(game.visitingTeam.teamActive.find(({ slot }) => slot === '1,3')),
      p4: new NbaGamePosition(game.visitingTeam.teamActive.find(({ slot }) => slot === '1,4')),
      p5: new NbaGamePosition(game.visitingTeam.teamActive.find(({ slot }) => slot === '1,5')),
      sub1: new NbaGamePosition(game.visitingTeam.teamOnBench.find(({ slot }) => slot === '1,1')),
      sub2: new NbaGamePosition(game.visitingTeam.teamOnBench.find(({ slot }) => slot === '1,2')),
      sub3: new NbaGamePosition(game.visitingTeam.teamOnBench.find(({ slot }) => slot === '1,3')),
      sub4: new NbaGamePosition(game.visitingTeam.teamOnBench.find(({ slot }) => slot === '1,4')),
      sub5: new NbaGamePosition(game.visitingTeam.teamOnBench.find(({ slot }) => slot === '1,5')),
    };

    this.gameDate = moment(game.gameDate);
    this.gameSchedule = game.gameSchedule;
    this.updatedAt = moment(game.updatedAt);
  }
}

class NbaGamePosition {
  constructor(payload) {
    this.healthStatus = payload ? payload.healthStatus : '';
    this.position = payload ? payload.position : '';
    this.explanation = payload ? payload.text : '';
    this.featuredPlayer = payload ? payload.featuredPlayer : '';
    this.backupPlayers = payload && payload.backupPlayers ? payload.backupPlayers : [];
  }
}

export { NbaGame };
