import React, { useState } from 'react';

function MultiSelector({ onChange, value, options, className = '' }) {
  const [selection, setSelection] = useState(value);

  return (
    <div className={`notifications-selectors mb-s ${className}`}>
      <div className="notifications-col full-width">
        <div className="notifications-selector">
          <div className={`notifications-scroller ${className}`}>
            {options.map(({ name, longName }) => {
              const selectedRow = selection.indexOf(name) !== -1;

              return (
                <div
                  key={name}
                  className="notifications-entry"
                  onClick={(e) => {
                    const checkbox = e.target.parentElement.querySelector('.chk-box');

                    if (!checkbox) {
                      return;
                    }

                    checkbox.classList.toggle('checked');

                    if (selectedRow) {
                      selection.splice(selection.indexOf(name), 1);
                    } else {
                      selection.push(name);
                    }

                    setSelection(selection);
                    onChange(selection);
                  }}
                >
                  <div className="notifications-cell notifications-cell-100">
                    <span className={'chk-box ' + (selectedRow ? 'checked' : '')}></span>
                    <span>{longName || name}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiSelector;
