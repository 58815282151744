import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import CustomLink from './CustomLink';
import PlayerInsights from './PlayerInsights';
import SuccessSnackbar from './SuccessSnackbar';
import CourtViewPosition from './CourtViewPosition';
import GlobalContext from '../contexts/GlobalContext';
import { getClassForTeamScore, getClassForPlayerScore, getNewHealth, getTimeAgo } from '../helpers/global';
import { BellIcon, CloseIcon, SicScoreIcon } from '../helpers/icons';
import { MlbGamePosition } from '../helpers/mlb';

function DiamondViewPosition({ player, onClick }) {
  return (
    <li>
      <span
        onClick={() => {
          const position = new MlbGamePosition();
          position.initPosition({
            featuredPlayer: player,
          });

          onClick(position);
        }}
      >
        <div className={'health-color health-color-' + getNewHealth(player.health)}></div>
        {player.name}
      </span>
    </li>
  );
}

export default function DiamondView({ gameData, isEmbed, isWidget }) {
  const context = useContext(GlobalContext);
  const [game] = useState(gameData);
  const [popupPlayerInfo, setPopupPlayerInfo] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const backupPlayers =
    popupPlayerInfo && popupPlayerInfo.backupPlayers
      ? popupPlayerInfo.backupPlayers.filter((player) => player && isNaN(player.id))
      : [];
  const { playerNotifications, togglePlayerNotification } = context;

  return (
    <div className={'diamond-view' + (isWidget ? ' is-widget' : '')}>
      <div className="diamond-view-top">
        <div className="diamond-view-team">
          <div>
            <div className="diamond-view-name">{game.team ? game.team.name : 'Team Name Goes Here'}</div>
            <figure className="diamond-view-logo">
              {game.team ? <img src={game.team.logoUrl} alt="Team logo" /> : null}
            </figure>
          </div>
        </div>
        <div className="diamond-view-note">
          <div className="diamond-view-hdl">
            {isEmbed ? '' : 'Diamond View: '}
            <strong>Regular Starters</strong>
          </div>
          {game.team.teamIndex ? (
            <>
              <SicScoreIcon />
              <div className={'health-cell ' + getClassForTeamScore(game.team.teamIndex)}>{game.team.teamIndex}</div>
            </>
          ) : null}
        </div>
      </div>

      <div className="diamond-view-bottom">
        <div>
          <div className="diamond-view-ttl">Key Reserves</div>
          <ul className="diamond-view-list">
            {game.reserves.map((player) => (
              <DiamondViewPosition key={player._id} player={player} onClick={(player) => setPopupPlayerInfo(player)} />
            ))}
          </ul>
        </div>
        <div className="diamond-view-rside">
          <div className="diamond-view-ttl">Pitching Staff</div>
          <div>Starters</div>
          <ul className="diamond-view-list">
            {game.starters.map((player) => (
              <DiamondViewPosition key={player._id} player={player} onClick={(player) => setPopupPlayerInfo(player)} />
            ))}
          </ul>
          <div className="diamond-view-subttl">Key Relievers</div>
          <ul className="diamond-view-list">
            {game.relievers.map((player) => (
              <DiamondViewPosition key={player._id} player={player} onClick={(player) => setPopupPlayerInfo(player)} />
            ))}
          </ul>
        </div>
      </div>

      <div className="diamond-view-rhomb"></div>

      {game.mains.map((position) => (
        <CourtViewPosition
          key={position.className}
          position={position}
          className={position.className + ' size-m'}
          onClick={() => setPopupPlayerInfo(position)}
        />
      ))}

      {popupPlayerInfo ? (
        <div className="diamond-info">
          <div className="diamond-info-inner">
            <div className="diamond-info-timestamp">Updated {getTimeAgo(game.updatedAt)}</div>
            <span className="diamond-info-close" onClick={() => setPopupPlayerInfo(null)}>
              <CloseIcon />
            </span>

            {popupPlayerInfo.mainPlayer ? (
              <>
                <div className="diamond-info-top">
                  <div className="diamond-info-id">
                    <figure>
                      {popupPlayerInfo.mainPlayer && popupPlayerInfo.mainPlayer.photoUrl ? (
                        <img src={popupPlayerInfo.mainPlayer.photoUrl} alt="Player preview" />
                      ) : null}
                    </figure>
                    <div>
                      <div
                        className="diamond-info-player"
                        dangerouslySetInnerHTML={{ __html: popupPlayerInfo.mainPlayer.name.replace(' ', '<br/>') }}
                      ></div>
                      <CustomLink
                        className="diamond-info-link"
                        to={
                          '/' +
                          popupPlayerInfo.mainPlayer.leagueType.toLowerCase() +
                          '/players/' +
                          popupPlayerInfo.mainPlayer.slug
                        }
                      >
                        View Profile
                      </CustomLink>
                    </div>
                  </div>

                  <span
                    className={classNames({
                      'diamond-info-notify': true,
                      'is-active': playerNotifications.indexOf(popupPlayerInfo.mainPlayer.id) !== -1,
                    })}
                    onClick={() => {
                      const id = popupPlayerInfo.mainPlayer.id;

                      togglePlayerNotification(id);
                      setSuccessMessage(
                        playerNotifications.indexOf(id) === -1
                          ? 'Player notifications turned off'
                          : 'Player notifications turned on'
                      );
                      setTimeout(() => setSuccessMessage(''), 1000);
                    }}
                  >
                    <BellIcon />
                  </span>
                </div>

                {popupPlayerInfo.mainPlayer.playerNotes ? (
                  <PlayerInsights insights={popupPlayerInfo.mainPlayer.playerNotes} />
                ) : null}

                <div className="diamond-info-table">
                  <div className="diamond-info-tr">
                    <div className="diamond-info-td1">HEALTH</div>
                    <div className="diamond-info-td1">TEAM</div>
                    {/* <div className="diamond-info-td1">STATUS</div> */}
                    <div className="diamond-info-td1">POSITION</div>
                  </div>
                  <div className="diamond-info-tr diamond-info-tr2">
                    <div className="diamond-info-td2">
                      <figure>
                        <div
                          className={'health-cell ' + getClassForPlayerScore(popupPlayerInfo.mainPlayer.health)}
                        ></div>
                      </figure>
                    </div>
                    <div className="diamond-info-td2">
                      <figure>{game.team ? <img src={game.team.logoUrl} alt="Team logo" /> : null}</figure>
                    </div>
                    {/* <div className="diamond-info-td2">{popupPlayerInfo.mainPlayer.status}</div> */}
                    <div className="diamond-info-td2">{popupPlayerInfo.position}</div>
                  </div>
                </div>
              </>
            ) : null}

            {popupPlayerInfo.hasPlayerUpdate ? (
              <>
                <div className="diamond-info-ttl">PLAYER UPDATE</div>
                <div className="diamond-info-update">{popupPlayerInfo.explanation}</div>
              </>
            ) : null}

            {backupPlayers.length ? (
              <div>
                <div className="diamond-info-tr mbm">
                  <div className="diamond-info-td3">POS. BACK UPS</div>
                  <div className="diamond-info-td3 diamond-info-double">
                    <span>Status</span>
                    <span className="diamond-info-cross"></span>
                  </div>
                </div>
                {backupPlayers.map((player) => (
                  <div className="diamond-info-tr mbs" key={player._id}>
                    <div className="diamond-info-td4">
                      <CustomLink
                        className="diamond-info-link"
                        to={'/' + player.leagueType.toLowerCase() + '/players/' + player.slug}
                      >
                        {player.name}
                      </CustomLink>
                    </div>
                    <div className="diamond-info-td4 diamond-info-double">
                      <span>{player.status}</span>
                      <div className={'health-cell ' + getClassForPlayerScore(player.health)}></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {successMessage ? <SuccessSnackbar message={successMessage} /> : null}
    </div>
  );
}
