import React from 'react';
import { InfoIcon } from '../helpers/icons';

export default function InfoPoint({ children, className }) {
  return (
    <span className="tooltip-holder bottom relative">
      <InfoIcon />
      <span
        className={'tooltip ' + className}
        dangerouslySetInnerHTML={{ __html: children.replace('--', '<br/><br/>') }}
      ></span>
    </span>
  );
}
