import React, { useState, useContext } from 'react';
import GlobalContext from '../contexts/GlobalContext';
import { getTeamShortName } from '../helpers/global';

function GameSelector({ onChange, value }) {
  const context = useContext(GlobalContext);
  const [gamesSelected, setGamesSelected] = useState(value);
  const { allGames, nbaGames, mlbGames } = context;

  return (
    <div className="notifications-selectors mb-s">
      <div className="notifications-col full-width">
        <div className="notifications-selector">
          <div className="notifications-scroller">
            {mlbGames
              .concat(nbaGames.filter(({ season }) => season === 2023))
              .concat(allGames)
              .map((game) => {
                return (
                  <div
                    key={game._id}
                    className="notifications-entry"
                    onClick={(e) => {
                      const checkbox = e.target.parentElement.querySelector('.chk-box');
                      checkbox.classList.toggle('checked');

                      if (gamesSelected.indexOf(game._id) !== -1) {
                        gamesSelected.splice(gamesSelected.indexOf(game._id), 1);
                      } else {
                        gamesSelected.push(game._id);
                      }

                      setGamesSelected(gamesSelected);
                      onChange(gamesSelected);
                    }}
                  >
                    <div className="notifications-cell notifications-cell-100">
                      <span className={'chk-box ' + (gamesSelected.indexOf(game._id) !== -1 ? 'checked' : '')}></span>
                      <span>
                        {game.team ? game.team.name : ''}
                        {game.visitingTeamDetails ? getTeamShortName(game.visitingTeamDetails.name) + ' vs. ' : ''}
                        {game.homeTeamDetails ? getTeamShortName(game.homeTeamDetails.name) : ''}
                        &nbsp; - &nbsp;{' '}
                        {game.weekDetails
                          ? game.weekDetails.name
                          : game.leagueType.toUpperCase() +
                            (game.gameDate ? ' - ' + game.gameDate.format('M/D/Y') : '')}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameSelector;
