import React, { useState, useContext } from 'react';
import InfoPoint from '../InfoPoint';
import CustomLink from '../CustomLink';
import EditWidget from '../EditWidget';
import LeagueSwitcher from '../LeagueSwitcher';
import { SicLogoIcon } from '../../helpers/icons';
import { getNewHealth } from '../../helpers/global';
import { isAdmin } from '../../helpers/session';
import GlobalContext from '../../contexts/GlobalContext';
import { TeamAbbr } from '../TeamAbbr';

export default function SicInsightsWidget({ history }) {
  const context = useContext(GlobalContext);
  const { widgets, widgetsContent, statuses } = context;
  const widgetKey = 'trending-players';
  const showLeagueSwitcher = window.location.pathname === '/';
  const [currentLeague, setCurrentLeague] = useState(null);

  if (!widgets[widgetKey]) {
    return null;
  }

  const players = widgets[widgetKey]
    .find(({ key }) => key === 'players')
    .value.map(({ playerId }) => widgetsContent[widgetKey].find((player) => playerId && player._id === playerId._id))
    .filter((player) => player);

  if (!players.length && !isAdmin()) {
    return null;
  }

  if (window.location.pathname === '/cfb') {
    return null;
  }

  return (
    <div className="trending-players sic-insights">
      <div className="section-header justify-between">
        <div className="section-header-start">
          <h2 className="section-title">
            <SicLogoIcon />
            Insights
          </h2>

          <InfoPoint>Doc's Players To Watch!</InfoPoint>

          {showLeagueSwitcher ? (
            <LeagueSwitcher
              onlyMainLeagues={true}
              currentLeague={currentLeague}
              onChangeLeague={(id, league) => setCurrentLeague(id === 'all' ? null : league)}
              hiddenLeagues={['CFB']}
            />
          ) : null}
        </div>
        <EditWidget type={widgetKey} />
      </div>
      <div className="section-content">
        <div className="head-row">
          <div>Team &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Player &amp; Notes</div>
          <div>SIC Score&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        </div>
        <div className="widget-list">
          {players
            .filter(
              (player) =>
                !currentLeague ||
                !showLeagueSwitcher ||
                currentLeague.name.toLowerCase() === player.leagueType.toLowerCase()
            )
            .map((player) => {
              const playerNotes = (player.playerNotes || '').replace(/(<([^>]+)>)/gi, '');

              return (
                <CustomLink
                  to={'/' + player.leagueType.toLowerCase() + '/players/' + player.slug}
                  className="player-progress-row"
                  key={player._id}
                >
                  <div className="player-progress-start second-layout">
                    <figure>
                      {player.teamId && player.teamId.logoUrl ? (
                        <TeamAbbr team={player.teamId.abbreviation} />
                      ) : (
                        <img src={player.photoUrl} alt="Player pic" />
                      )}
                    </figure>
                    <div className="player-progress-text">
                      <div className="player-progress-title">
                        <span className="player-progress-cta">{player.name}</span>
                        {player.position ? (
                          <span className="player-progress-pos"> {`(${player.position.name})`}</span>
                        ) : null}
                        {player.tag ? <span className="player-progress-tag">{player.tag}</span> : null}
                      </div>
                      {playerNotes.length ? (
                        <div className="player-progress-info">
                          {playerNotes.trim().length > 136 ? playerNotes.trim().substring(0, 136) + '...' : playerNotes}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="player-progress-end">
                    <span
                      className={
                        'health-sign health-' + getNewHealth(player.health, player.sicScore, player.status, statuses)
                      }
                    >
                      {player.sicScore === null ? '' : player.sicScore}
                    </span>
                    <div className="player-progress-direction">
                      <span className={player.trend}></span>
                    </div>
                  </div>
                </CustomLink>
              );
            })}
        </div>
      </div>
    </div>
  );
}
