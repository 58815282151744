import React, { Component } from 'react';
import axios from 'axios';
import GlobalContext from '../contexts/GlobalContext';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PlayerSelector from './PlayerSelector';
import MultiSelector from './MultiSelector';
import GameSelector from './GameSelector';
import LoadingWheel from './LoadingWheel';
import ErrorSnackbar from './ErrorSnackbar';
import { isAdmin } from '../helpers/session';
import { API_URL, SUPPORTED_LEAGUES } from '../config.js';
import HeroCarouselWidget from './widgets2/HeroCarouselWidget';
import VideosFeedWidget from './widgets2/VideosFeedWidget';
import LatestNews from './LatestNews';

const labels = {
  leagueType: 'Leagues',
  categories: 'Categories',
  boostedPost: 'Allow boosted posts',
  postLimits: '# of posts',
  type: 'Type',
  players: 'Players',
  games: 'Games',
};

const widgetMapping = [
  {
    homeType: 'hero-carousel',
    pageType: 'featured-post-carousel',
  },
  {
    homeType: 'trending-players',
    pageType: 'sic-insights',
  },
  {
    homeType: 'game-feed',
    pageType: 'upcoming-games-feed',
  },
  {
    homeType: 'post-feed-horizontal',
    pageType: 'horizontal-post-feed',
  },
  {
    homeType: 'updates-feed',
    pageType: 'game-view',
  },
  {
    homeType: 'post-feed-vertical',
    pageType: 'vertical-post-feed',
  },
  {
    homeType: 'videos-feed',
    pageType: 'videos',
  },
];

class EditWidget extends Component {
  constructor() {
    super();

    this.state = {
      form: [],
      dialogOpened: false,
      widgetType: '',
      loading: false,
      errorMessage: '',
      widgets: [],
      sidebars: [],
      sidebarWidgets: [],
      sidebarConfig: null,
      sidebarData: null,
    };
  }

  componentDidMount() {
    const { type, isSidebar, sidebarId } = this.props;
    const { widgets } = this.context;
    const pageLeague = window.location.pathname === '/matchups' ? 'all' : 'NFL';

    this.setState({
      widgetType: type,
      form: widgets[type] || [],
    });

    if (isSidebar) {
      axios
        .get(`${API_URL}/sidebarWidget/${sidebarId}?pageLeague=${pageLeague}`)
        .then(({ data }) => this.setState({ sidebarData: data.data }));
      axios
        .get(`${API_URL}/sidebarWidget/pageConfig/${sidebarId}?pageLeague=${pageLeague}`)
        .then(({ data }) => this.setState({ sidebarConfig: data.data }));
    }
  }

  onUpdateModel = ({ target }) => {
    const { name, value } = target;
    const { form } = this.state;
    const index = form.findIndex(({ key }) => key === name);

    form[index].value = value;

    this.setState({ form });
  };

  onSave = () => {
    this.setState({
      dialogOpened: false,
      loading: true,
    });

    const data = {};
    const { isSidebar, sidebarId } = this.props;
    const { form, widgetType } = this.state;
    const { pathname } = window.location;
    const pageLeague = pathname === '/matchups' ? 'all' : 'NFL';

    form.map(({ key, value }) => {
      if (key === 'players') {
        data[key] = value.map(({ playerId, tag, trend, datetime, _id }) => {
          return {
            playerId: playerId || { _id, id: _id },
            tag,
            trend,
            datetime,
          };
        });
      } else if (key === 'leagueType' && value.length === 0 && SUPPORTED_LEAGUES.indexOf(pathname) !== -1) {
        data[key] = [pathname.replace('/', '')];
      } else {
        data[key] = value;
      }

      return data;
    });

    const path = isSidebar
      ? `sidebarWidget/update/${sidebarId}?pageLeague=${pageLeague}`
      : `widget/update/${widgetType}?page=${
          SUPPORTED_LEAGUES.indexOf(pathname) !== -1 ? pathname.replace('/', '') : 'home'
        }`;
    const pageWidgetType = widgetMapping.find(({ homeType }) => homeType === widgetType).pageType;

    axios
      .put(`${API_URL}/${path}`, {
        widgeType: pageWidgetType,
        leagueTypes: data.leagueType || [],
        ...data,
      })
      .then(({ data }) => {
        this.setState({
          loading: false,
        });

        if (data.message && data.message.indexOf('not found') !== -1) {
          this.setState({
            loading: false,
            errorMessage: data.message,
          });

          setTimeout(() => {
            this.setState({ errorMessage: '' });
          }, 3000);
        } else {
          window.location.reload();
        }
      })
      .catch(({ response }) => {
        this.setState({
          loading: false,
          errorMessage: response.data.message,
        });

        setTimeout(() => {
          this.setState({ errorMessage: '' });
        }, 3000);
      });
  };

  render() {
    const { isSidebar } = this.props;
    const { form, dialogOpened, loading, errorMessage, sidebarWidgets, widgetType, sidebarConfig, sidebarData } =
      this.state;
    const { leagues, postCategories } = this.context;
    const isLeaguePage = SUPPORTED_LEAGUES.indexOf(window.location.pathname) !== -1;

    return (
      <>
        {isAdmin() ? (
          <span
            className="edit-widget-button"
            onClick={() => {
              const { isSidebar } = this.props;
              const { playersLoadInitiated, loadPlayers } = this.context;

              this.setState({
                sidebars: [],
                sidebarWidgets: [],
                dialogOpened: true,
              });

              if (!playersLoadInitiated) {
                loadPlayers();
              }

              if (isSidebar) {
                axios.get(`${API_URL}/sidebarWidget/widgetsPagesName`).then(({ data }) => {
                  const { pages, widgets } = data.data;

                  this.setState({
                    sidebars: pages,
                    sidebarWidgets: widgets,
                  });
                });
              }
            }}
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.7263 7.94556C5.17996 7.94556 4.656 7.72853 4.26969 7.34222C3.88337 6.9559 3.66634 6.43194 3.66634 5.8856C3.66634 5.33927 3.88337 4.81531 4.26969 4.42899C4.656 4.04267 5.17996 3.82564 5.7263 3.82564C6.27264 3.82564 6.7966 4.04267 7.18291 4.42899C7.56923 4.81531 7.78626 5.33927 7.78626 5.8856C7.78626 6.43194 7.56923 6.9559 7.18291 7.34222C6.7966 7.72853 6.27264 7.94556 5.7263 7.94556ZM10.0993 6.45651C10.1228 6.26817 10.1405 6.07983 10.1405 5.8856C10.1405 5.69138 10.1228 5.49715 10.0993 5.29704L11.3412 4.33769C11.453 4.24941 11.4824 4.09049 11.4118 3.96101L10.2347 1.92459C10.164 1.79511 10.0051 1.74214 9.87565 1.79511L8.41014 2.38367C8.10408 2.15413 7.78626 1.95402 7.41547 1.80688L7.1977 0.247195C7.17416 0.105941 7.05056 0 6.90342 0H4.54918C4.40204 0 4.27844 0.105941 4.2549 0.247195L4.03713 1.80688C3.66634 1.95402 3.34852 2.15413 3.04246 2.38367L1.57695 1.79511C1.44747 1.74214 1.28855 1.79511 1.21793 1.92459L0.0408058 3.96101C-0.035707 4.09049 -0.000393336 4.24941 0.111433 4.33769L1.3533 5.29704C1.32975 5.49715 1.3121 5.69138 1.3121 5.8856C1.3121 6.07983 1.32975 6.26817 1.3533 6.45651L0.111433 7.43352C-0.000393336 7.5218 -0.035707 7.68071 0.0408058 7.8102L1.21793 9.84661C1.28855 9.9761 1.44747 10.0232 1.57695 9.9761L3.04246 9.38165C3.34852 9.61708 3.66634 9.81719 4.03713 9.96433L4.2549 11.524C4.27844 11.6653 4.40204 11.7712 4.54918 11.7712H6.90342C7.05056 11.7712 7.17416 11.6653 7.1977 11.524L7.41547 9.96433C7.78626 9.8113 8.10408 9.61708 8.41014 9.38165L9.87565 9.9761C10.0051 10.0232 10.164 9.9761 10.2347 9.84661L11.4118 7.8102C11.4824 7.68071 11.453 7.5218 11.3412 7.43352L10.0993 6.45651Z"
                fill="#BDBDBD"
              />
            </svg>
            <span>Edit Widget</span>
          </span>
        ) : null}

        {isSidebar && sidebarConfig && sidebarData && sidebarConfig.widgeType ? (
          <div className="sidebar-widgets">
            {sidebarConfig.widgeType === 'featured-post-carousel' ? (
              <HeroCarouselWidget externalData={sidebarData} noEditButton={true} />
            ) : null}

            {sidebarConfig.widgeType === 'videos' ? (
              <VideosFeedWidget externalData={sidebarData} noEditButton={true} />
            ) : null}

            {sidebarConfig.widgeType === 'vertical-post-feed' ? (
              <LatestNews layout="stacked" externalData={sidebarData} hideSignupForm={true} widgetTitle="" />
            ) : null}

            {sidebarConfig.widgeType === 'horizontal-post-feed' ? (
              <LatestNews externalData={sidebarData} hideSignupForm={true} widgetTitle="" />
            ) : null}
          </div>
        ) : null}

        <Dialog open={dialogOpened} onClose={() => this.setState({ dialogOpened: true })}>
          <DialogTitle className="dialog-title">Edit Widget</DialogTitle>

          <DialogContent className={`edit-widget-content size-m ${widgetType}`}>
            {isSidebar ? (
              <div className="formfield-fw">
                <label>Select widget:</label>
                <select
                  className="full-width"
                  value={
                    widgetType === ''
                      ? ''
                      : (widgetMapping.find((wm) => wm.homeType === widgetType) || { pageType: '' }).pageType
                  }
                  onChange={({ target }) => {
                    const { widgets, playersLoadInitiated, loadPlayers } = this.context;
                    const newType = widgetMapping.find(({ pageType }) => pageType === target.value).homeType;

                    this.setState({
                      widgetType: newType,
                      form: widgets[newType] || [],
                    });

                    if (!playersLoadInitiated) {
                      loadPlayers();
                    }
                  }}
                >
                  <option value="">-</option>
                  {sidebarWidgets.map(({ widgetName, widgetSlug }) => (
                    <option key={widgetSlug} value={widgetSlug}>
                      {widgetName}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            {form.map(({ key, value, error, hidden }) => (
              <Box mb={2.5} key={key}>
                {key === 'leagueType' && !isLeaguePage ? (
                  <>
                    <div>{labels[key]}</div>
                    <MultiSelector
                      name={key}
                      value={value}
                      error={error}
                      className="leagues-selector"
                      onChange={(value) => this.onUpdateModel({ target: { name: 'leagueType', value } })}
                      options={leagues}
                    />
                  </>
                ) : null}

                {key === 'categories' ? (
                  <>
                    <div>{labels[key]}</div>
                    <MultiSelector
                      name={key}
                      value={value}
                      error={error}
                      className="categories-selector"
                      onChange={(value) => this.onUpdateModel({ target: { name: 'categories', value } })}
                      options={[{ name: 'all', fullName: 'All' }].concat(postCategories).map(({ name, fullName }) => {
                        return {
                          name,
                          longName: fullName,
                        };
                      })}
                    />
                  </>
                ) : null}

                {key === 'boostedPost' ? (
                  <>
                    <div>{labels[key]}</div>
                    <NativeSelect name={key} value={value} error={error} onChange={this.onUpdateModel} fullWidth>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </NativeSelect>
                  </>
                ) : null}

                {key === 'type' ? (
                  <>
                    <div>{labels[key]}</div>
                    <NativeSelect name={key} value={value} error={error} onChange={this.onUpdateModel} fullWidth>
                      <option value="both">Both</option>
                      <option value="players">Players</option>
                      <option value="teams">Teams</option>
                    </NativeSelect>
                  </>
                ) : null}

                {key === 'postLimits' ? (
                  <TextField
                    label={labels[key]}
                    type="number"
                    name={key}
                    value={value}
                    error={error}
                    onChange={this.onUpdateModel}
                    style={hidden ? { display: 'none' } : {}}
                    fullWidth
                  />
                ) : null}

                {key === 'players' ? (
                  <>
                    <div>{labels[key]}</div>
                    <PlayerSelector
                      name={key}
                      value={value}
                      leagues={form.find(({ key }) => key === 'leagueType').value}
                      onChange={(value) => this.onUpdateModel({ target: { name: 'players', value } })}
                      multiple
                      className="multiple-select"
                    />
                  </>
                ) : null}

                {key === 'games' ? (
                  <>
                    <div>{labels[key]}</div>
                    <GameSelector
                      name={key}
                      value={value}
                      onChange={(value) => this.onUpdateModel({ target: { name: 'games', value } })}
                      multiple
                      className="multiple-select"
                    />
                  </>
                ) : null}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogOpened: false })} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {loading ? <LoadingWheel /> : null}

        {errorMessage ? <ErrorSnackbar message={errorMessage} /> : null}
      </>
    );
  }
}

EditWidget.contextType = GlobalContext;
EditWidget.defaultProps = {
  widgetType: '',
  isSidebar: false,
  sidebarId: '',
};

export default EditWidget;
