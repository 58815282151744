import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import GlobalContext from '../contexts/GlobalContext';
import { LockIcon } from '../helpers/icons';
import { forgetRefreshToken, forgetToken, getToken, setAdmin } from '../helpers/session';
import logoMobile from '../images/logo-sic-mobile.png';
import logo from '../images/logo-sic-wide.png';
import GlobalSearchForm from './GlobalSearchForm';

const DownArrow = () => (
  <svg className="down-arrow" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5046 -5.44387e-08L5.87541 4.62917L1.24623 -4.59134e-07L0.000822013 1.24541L5.87541 7.12L11.75 1.24541L10.5046 -5.44387e-08Z"
      fill="#BAC0CB"
    />
  </svg>
);

class Header extends Component {
  constructor() {
    super();

    this.state = {
      isNavActive: false,
      isSearchActive: false,
    };
  }

  onLogout = () => {
    forgetToken();
    forgetRefreshToken();
    setAdmin(false);
    this.props.onRefreshSession();
    window.location.reload();
  };

  render() {
    const { context } = this;
    const { isNavActive, isSearchActive } = this.state;
    const { showStreamingButton, profile, onClickEvent } = this.props;
    const pathname = this.props.history.location.pathname.replace('/', '');
    const isLoggedIn = getToken() !== null;
    const customFlow =
      pathname.indexOf('-fanduel') !== -1 ? '-fanduel' : pathname.indexOf('-founder') !== -1 ? '-founder' : '';

    return (
      <div className="header" onClick={onClickEvent}>
        <div className="upper-bar">
          <Container fixed className="upper-bar-inner">
            <div className="breaking-info">
              {showStreamingButton ? (
                <>
                  <span className="dot"></span>
                  <strong>LIVE NOW:</strong> The Doc is live <Link to="/videos">watch now.</Link>
                </>
              ) : null}
            </div>
            <div className="user-info">
              <Link to="/about">About</Link>
              <span>|</span>
              <Link to="/podcasts">Podcasts</Link>
              <span>|</span>
              <div className="has-dropdown">
                <Link
                  to="/more"
                  className="a dropdown-trigger"
                  onClick={() => {
                    context.loadLatestNews();
                    this.setState({ isNavActive: false });
                    if (window.articlesScreenLoadArticles) {
                      window.articlesScreenLoadArticles('more', 'more');
                    }
                  }}
                >
                  More
                  <DownArrow />
                </Link>
                <ul>
                  {['Golf', 'Tennis', 'Soccer', 'NCAAB', 'NHL', 'Wrestling', 'Boxing', 'MMA', 'USFL', 'Motorsport'].map(
                    (league) => (
                      <li key={league}>
                        <Link
                          to={`/${league.toLowerCase()}/articles`}
                          onClick={() => {
                            context.setLeague(league);
                            context.loadLatestNews(league.toLowerCase());
                            this.setState({ isNavActive: false });
                            if (window.articlesScreenLoadArticles) {
                              window.articlesScreenLoadArticles(league.toLowerCase(), 'more');
                            }
                          }}
                        >
                          {league}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <span>|</span>
              {context?.currentUser ? (
                <Link to="/account">
                  Welcome,{' '}
                  {context?.currentUser?.name ? context?.currentUser?.name.split(' ')[0] : context?.currentUser?.email}
                </Link>
              ) : (
                <span>Welcome, Guest</span>
              )}
              <span>|</span>
              {isLoggedIn ? (
                <Link to="/account" onClick={this.onLogout}>
                  Logout
                </Link>
              ) : (
                <>
                  <Link to={'/login' + customFlow} className="btn-login">
                    Login
                  </Link>
                  <Link to={'/premium'} className="btn-register">
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          </Container>
        </div>
        <div className="top-bar">
          <Container fixed className={classNames(['top-bar-inner'], { 'active-nav': isNavActive })}>
            <div className="logo">
              <Link
                to="/"
                onClick={() => {
                  if (window.landingScreenLoadWidgets) {
                    window.landingScreenLoadWidgets();
                  }
                }}
              >
                <img src={logo} alt="PFD logo" className="logo-default" />
                <img src={logoMobile} alt="PFD logo" className="logo-mobile" />
              </Link>
            </div>
            <div className="nav-trigger" onClick={() => this.setState({ isNavActive: !isNavActive })}>
              <span></span>
            </div>
            <nav>
              <div className="nav-title">{isSearchActive ? 'Search' : 'Navigation'}</div>

              <ul>
                <li>
                  <Link to="/" onClick={() => this.setState({ isNavActive: false })}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/premium" onClick={() => this.setState({ isNavActive: false })}>
                    Premium
                  </Link>
                </li>
                <li className="has-dropdown">
                  <Link
                    to="/matchups"
                    className="highlighted dropdown-trigger"
                    onClick={() => this.setState({ isNavActive: false })}
                  >
                    <LockIcon />
                    SIC Matchups
                    <DownArrow />
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to="/matchups"
                        onClick={() => {
                          this.setState({ isNavActive: false });
                          setTimeout(() => {
                            const element = document.querySelector('#scores-teams');

                            if (element) {
                              const elementBounding = element.getBoundingClientRect();
                              window.scrollTo(0, elementBounding.y - 20);
                            }
                          }, 200);
                        }}
                      >
                        Teams
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/matchups"
                        onClick={() => {
                          this.setState({ isNavActive: false });
                          setTimeout(() => {
                            const element = document.querySelector('#scores-players');

                            if (element) {
                              const elementBounding = element.getBoundingClientRect();
                              window.scrollTo(0, elementBounding.y - 20);
                            }
                          }, 200);
                        }}
                      >
                        Players
                      </Link>
                    </li>
                    <li>
                      <Link to="/nfl/games" onClick={() => this.setState({ isNavActive: false })}>
                        Field Views
                      </Link>
                    </li>
                    <li>
                      <Link to="/injury-edge" onClick={() => this.setState({ isNavActive: false })}>
                        Injury Edge
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/insights" onClick={() => this.setState({ isNavActive: false })}>
                    SIC Insights
                  </Link>
                </li>
                <li>
                  <Link to="/picks" className="highlighted" onClick={() => this.setState({ isNavActive: false })}>
                    <LockIcon />
                    SIC Picks
                  </Link>
                </li>
                <li className="has-dropdown">
                  <Link
                    to="/nfl"
                    className="a dropdown-trigger"
                    onClick={() => {
                      context.setLeague('NFL');
                      this.setState({ isNavActive: false });
                      if (window.landingScreenLoadWidgets) {
                        window.landingScreenLoadWidgets();
                      }
                    }}
                  >
                    NFL
                    <DownArrow />
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to="/nfl"
                        onClick={() => {
                          context.setLeague('NFL');
                          this.setState({ isNavActive: false });
                          if (window.landingScreenLoadWidgets) {
                            window.landingScreenLoadWidgets();
                          }
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/player-updates"
                        onClick={() => {
                          context.setLeague('NFL');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('NFL', 'docLiveUpdate');
                          }
                        }}
                      >
                        Player Updates
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/articles"
                        onClick={() => {
                          context.setLeague('NFL');
                          context.loadLatestNews();
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('NFL', 'article');
                          }
                        }}
                      >
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/games"
                        onClick={() => {
                          context.setLeague('NFL');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        SIC Score / Field View
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/teams"
                        onClick={() => {
                          context.setLeague('NFL');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        Teams &amp; Players
                      </Link>
                    </li>
                    <li>
                      <Link to="/nfl/fantasy" onClick={() => this.setState({ isNavActive: false })}>
                        Fantasy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/fantasy-teams"
                        onClick={() => {
                          context.setLeague('NFL');
                          context.loadUserTeams('nfl');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        My Fantasy Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/videos"
                        onClick={() => {
                          context.setLeague('NFL');
                          context.loadLatestNews('nfl');
                          this.setState({ isNavActive: false });
                          if (window.videosScreenLoadVideos) {
                            window.videosScreenLoadVideos('NFL');
                          }
                        }}
                      >
                        Videos
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nfl/injuries"
                        onClick={() => {
                          this.setState({ isNavActive: false });
                          if (window.injuriesScreenSetLeague) {
                            window.injuriesScreenSetLeague('nfl');
                          }
                        }}
                      >
                        Injury Reports
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="has-dropdown">
                  <Link
                    to="/cfb"
                    className="a dropdown-trigger"
                    onClick={() => {
                      context.setLeague('CFB');
                      this.setState({ isNavActive: false });
                      if (window.landingScreenLoadWidgets) {
                        window.landingScreenLoadWidgets();
                      }
                    }}
                  >
                    CFB
                    <DownArrow />
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to="/cfb"
                        onClick={() => {
                          context.setLeague('CFB');
                          this.setState({ isNavActive: false });
                          if (window.landingScreenLoadWidgets) {
                            window.landingScreenLoadWidgets();
                          }
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cfb/player-updates"
                        onClick={() => {
                          context.setLeague('CFB');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('CFB', 'docLiveUpdate');
                          }
                        }}
                      >
                        Player Updates
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cfb/articles"
                        onClick={() => {
                          context.setLeague('CFB');
                          context.loadLatestNews('mlb');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('CFB', 'article');
                          }
                        }}
                      >
                        Articles
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="has-dropdown">
                  <Link
                    to="/nba"
                    className="a dropdown-trigger"
                    onClick={() => {
                      context.setLeague('NBA');
                      this.setState({ isNavActive: false });
                      if (window.landingScreenLoadWidgets) {
                        window.landingScreenLoadWidgets();
                      }
                    }}
                  >
                    NBA
                    <DownArrow />
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to="/nba"
                        onClick={() => {
                          context.setLeague('NBA');
                          this.setState({ isNavActive: false });
                          if (window.landingScreenLoadWidgets) {
                            window.landingScreenLoadWidgets();
                          }
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/player-updates"
                        onClick={() => {
                          context.setLeague('NBA');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('NBA', 'docLiveUpdate');
                          }
                        }}
                      >
                        Player Updates
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/articles"
                        onClick={() => {
                          context.setLeague('NBA');
                          context.loadLatestNews('nba');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('NBA', 'article');
                          }
                        }}
                      >
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/games"
                        onClick={() => {
                          context.setLeague('NBA');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        SIC Score / Court View
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/teams"
                        onClick={() => {
                          context.setLeague('NBA');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        Teams &amp; Players
                      </Link>
                    </li>
                    <li>
                      <Link to="/nba/fantasy" onClick={() => this.setState({ isNavActive: false })}>
                        Fantasy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/fantasy-teams"
                        onClick={() => {
                          context.setLeague('NBA');
                          context.loadUserTeams('nba');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        My Fantasy Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/videos"
                        onClick={() => {
                          context.setLeague('NBA');
                          context.loadLatestNews('nba');
                          this.setState({ isNavActive: false });
                          if (window.videosScreenLoadVideos) {
                            window.videosScreenLoadVideos('NBA');
                          }
                        }}
                      >
                        Videos
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nba/injuries"
                        onClick={() => {
                          this.setState({ isNavActive: false });
                          if (window.injuriesScreenSetLeague) {
                            window.injuriesScreenSetLeague('nba');
                          }
                        }}
                      >
                        Injury Reports
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="has-dropdown">
                  <Link
                    to="/mlb"
                    className="a dropdown-trigger"
                    onClick={() => {
                      context.setLeague('MLB');
                      this.setState({ isNavActive: false });
                      if (window.landingScreenLoadWidgets) {
                        window.landingScreenLoadWidgets();
                      }
                    }}
                  >
                    MLB
                    <DownArrow />
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to="/mlb"
                        onClick={() => {
                          context.setLeague('MLB');
                          this.setState({ isNavActive: false });
                          if (window.landingScreenLoadWidgets) {
                            window.landingScreenLoadWidgets();
                          }
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/player-updates"
                        onClick={() => {
                          context.setLeague('MLB');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('MLB', 'docLiveUpdate');
                          }
                        }}
                      >
                        Player Updates
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/articles"
                        onClick={() => {
                          context.setLeague('MLB');
                          context.loadLatestNews('mlb');
                          this.setState({ isNavActive: false });
                          if (window.articlesScreenLoadArticles) {
                            window.articlesScreenLoadArticles('MLB', 'article');
                          }
                        }}
                      >
                        Articles
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/games"
                        onClick={() => {
                          context.setLeague('MLB');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        SIC Score / Diamond View
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/teams"
                        onClick={() => {
                          context.setLeague('MLB');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        Teams &amp; Players
                      </Link>
                    </li>
                    <li>
                      <Link to="/mlb/fantasy" onClick={() => this.setState({ isNavActive: false })}>
                        Fantasy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/fantasy-teams"
                        onClick={() => {
                          context.setLeague('MLB');
                          context.loadUserTeams('mlb');
                          this.setState({ isNavActive: false });
                        }}
                      >
                        My Fantasy Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/videos"
                        onClick={() => {
                          context.setLeague('MLB');
                          context.loadLatestNews('mlb');
                          this.setState({ isNavActive: false });
                          if (window.injuriesScreenSetLeague) {
                            window.injuriesScreenSetLeague('mlb');
                          }
                        }}
                      >
                        Videos
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/mlb/injuries"
                        onClick={() => {
                          this.setState({ isNavActive: false });
                          if (window.injuriesScreenSetLeague) {
                            window.injuriesScreenSetLeague('mlb');
                          }
                        }}
                      >
                        Injury Reports
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/injury-ticker"
                    onClick={() => {
                      this.setState({ isNavActive: false });
                    }}
                  >
                    Injury News
                  </Link>
                </li>
                <li className="search-player search-player-desktop icon-item">
                  <GlobalSearchForm />
                </li>
                {profile ? (
                  <>
                    <li className="icon-item">
                      <Link to="/account" onClick={() => this.setState({ isSearchActive: true })}>
                        <Icon className="icon-trigger">notifications</Icon>
                      </Link>
                    </li>
                  </>
                ) : null}
              </ul>
            </nav>
          </Container>
        </div>
        <div className="search-player search-player-mobile">
          <GlobalSearchForm
            onFocus={() => this.setState({ isSearchActive: true })}
            onBlur={() => this.setState({ isSearchActive: false })}
            onSelect={() => this.setState({ isNavActive: false })}
          />
        </div>
      </div>
    );
  }
}

Header.contextType = GlobalContext;

export default withRouter(Header);
