import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import AppEmbed from './appEmbed';

function getPreviousScriptElement(node) {
  const sibling = node.previousSibling;

  if (sibling === null) {
    return null;
  }

  if (sibling.tagName === 'SCRIPT') {
    return sibling;
  }

  return getPreviousScriptElement(sibling);
}

if (window.sicEmbedData) {
  const divs = document.querySelectorAll('div');
  divs.forEach((div) => {
    if (div.id === 'sic-app-root' || div.classList.contains('sic-app-root')) {
      const loadScript = getPreviousScriptElement(div);

      if (loadScript) {
        const dataScript = getPreviousScriptElement(loadScript);

        if (dataScript) {
          eval(dataScript.innerHTML);
          ReactDOM.render(<AppEmbed />, div);
        }
      }
    }
  });
} else {
  ReactDOM.render(<App />, document.getElementById('sic-app-root'));
}
